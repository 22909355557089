// rebuild

.rebuild-aim {
  padding: 30px 20px 15px;
  .lead {
    margin-bottom: 51px;
  }
  &__card {
    position: relative;
    background: #fff url(#{$img-dir}sub/rebuild/ico-aim.png) no-repeat right -15px top -14px;
    background-size: 160px;
    padding: 59px 20px 6px;
    // margin-bottom: 46px;
    border-radius: 10px;
    &:first-of-type {
      margin-bottom: 46px;
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
  &__count {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: #fff;
    background-color: #f4b119;
    // line-height: 32px;
    font-size: 15px;
    // text-align: center;
    letter-spacing: .04em;
    width: 80px;
    height: 80px;
    // margin: 0 auto;
    margin-left: -40px;
    left: 50%;
    top: -31px;
    border-radius: 50%;
    span {
      // display: block;
      font-weight: bold;
      font-size: 21px;
    }
  }
  &__title {
    color: #333;
    line-height: 1.48;
    font-size: 21px;
    letter-spacing: .06em;
    margin-bottom: 15px;
    strong {
      color: $base-color;
    }
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: .04em;
  }
}

@media print, screen and (min-width: 768px) {

.rebuild-aim {
  // padding: 30px 20px 15px;
  padding-top: 90px;
  padding-bottom: 72px;
  .lead {
    margin-bottom: 86px;
  }
  > div {
    max-width: $base-width;
    margin: 0 auto 60px;
  }
  &__card {
    // box-sizing: border-box;
    background-position: right -14px top -14px;
    // padding: 79px 45px 30px;
    padding: 79px 36px 30px 45px;
    // width: calc(50% - 15px);
    width: 525px;
    &:first-of-type {
      margin: 0 30px 0 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__count {
    width: 95px;
    height: 95px;
    margin-left: 0;
    top: -46px;
    transform: translateX(-50%);
    span {
      line-height: 1;
      font-size: 36px;
    }
  }
  &__title {
    line-height: 1.5;
    font-size: 24px;
    margin-bottom: 30px;
  }
  &__text {
    line-height: 1.8;
    font-size: 15px;
  }
}


}

