// other

.quote {
  color: #000;
  line-height: 2;
  font-size: 15px;
  // text-align: center;
  letter-spacing: 0.04em;
  // padding: 16px 20px;
  // margin-bottom: 0;
  strong {
    position: relative;
    // color: $base-color;
    // color: #1ebe9f;
    font-weight: normal;
    // margin-left: 25px;
    box-shadow: inset 0 -10px #ffec60;
  }
}

.button-card {
  color: #fff;
  // text-align: center;
  padding: 20px 20px 10px;
  margin: 0 20px 30px;
  border-radius: 10px;
  &--section {
    margin-top: 30px;
  }
  &__lead {
    line-height: 1.47;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.04em;
    margin-bottom: 10px;
  }
  &__title {
    line-height: 1.48;
    font-weight: bold;
    font-size: 21px;
    text-align: center;
    letter-spacing: 0.06em;
    margin-bottom: 10px;
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 15px;
  }
  li {
    margin-bottom: 10px;
  }
  li:last-child {
    font-size: 13px;
    @media print, screen and (min-width: 360px) {
      font-size: 15px;
    }
  }
}

.recommend {
  // background-color: #e3f3ff;
  padding: 30px 0;
  &__heading {
    position: relative;
    color: #fff;
    // background-color: $base-color;
    line-height: 46px;
    font-weight: bold;
    font-size: 21px;
    text-align: center;
    letter-spacing: 0.06em;
    // width: 278px;
    width: 220px;
    margin: -11px auto 0;
    top: 11px;
    // z-index: 1;
    @media print, screen and (min-width: 360px) {
      width: 278px;
    }
    &:before,
    &:after {
      position: absolute;
      background: url(#{$img-dir}sub/bg-recommend.png) no-repeat;
      background-size: 40px;
      width: 40px;
      height: 46px;
      top: 10px;
      // z-index: -1;
      content: "";
    }
    &:before {
      left: -30px;
    }
    &:after {
      right: -30px;
      transform: scaleX(-1);
    }
    span {
      position: relative;
      display: block;
      background-color: $base-color;
      z-index: 1;
    }
  }
  > div {
    // background-color: #e3f3ff;
    padding: 30px 20px;
  }
  > div > div {
    background-color: #fff;
    padding: 20px 20px 9px;
    border-radius: 10px;
  }
  div div div:first-of-type {
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    border-radius: 10px;
  }
  &__title {
    display: inline;
    color: #000;
    line-height: 1.56;
    font-size: 18px;
    letter-spacing: 0.06em;
    box-shadow: inset 0 -10px #ffec60;
  }
  &__lead {
    color: $base-color;
    line-height: 1.6;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.06em;
    margin: 15px 0;
  }
  &__title + &__text {
    margin: 15px 0;
  }
  ul {
    color: #333;
    line-height: 1.93;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 15px;
  }
  li {
    position: relative;
    padding-left: 20px;
    &:after {
      position: absolute;
      background: url(#{$img-dir}sub/ico-recommend.png) no-repeat;
      background-size: 13px;
      width: 13px;
      height: 9px;
      left: 0;
      top: 9px;
      content: "";
    }
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
  }
}

.example-nav {
  background-color: #217dc5;
  font-weight: bold;
  text-align: center;
  padding: 33px 20px 19px;
  p {
    position: relative;
    color: #fff;
    // font-size: 21px;
    font-size: 18px;
    letter-spacing: 0.06em;
    margin-bottom: 20px;
    @media print, screen and (min-width: 360px) {
      font-size: 21px;
    }
    &:before,
    &:after {
      // position: absolute;
      position: relative;
      display: inline-block;
      background-color: #fff;
      width: 3px;
      // height: 100%;
      height: 22.5px;
      top: 3px;
      content: "";
    }
    &:before {
      margin-right: 15px;
      transform: rotate(-45deg);
    }
    &:after {
      margin-left: 15px;
      transform: rotate(45deg);
    }
    span {
      position: relative;
      &:after {
        position: absolute;
        background-color: #fff;
        width: 4px;
        height: 4px;
        left: 50%;
        top: -3px;
        border-radius: 50%;
        content: "";
        transform: translateX(-50%);
      }
    }
  }
  ul {
    line-height: 1.67;
    // font-size: 15px;
    font-size: 12px;
    @media print, screen and (min-width: 360px) {
      font-size: 15px;
    }
  }
  li {
    // position: relative;
    margin-bottom: 14px;
  }
  a {
    position: relative;
    box-sizing: border-box;
    // display: block;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    // padding-left: 40px;
    padding-left: 60px;
    width: 100%;
    height: 81px;
    border: 2px solid $base-color;
    border-radius: 100px;
    box-shadow: 0 4px #0a5d9d;
    @media print, screen and (min-width: 360px) {
      padding-left: 40px;
    }
    &:after {
      position: absolute;
      background-repeat: no-repeat;
      background-size: 51px;
      width: 51px;
      left: 40px;
      bottom: 0;
      content: "";
    }
  }
  li:first-child a:after {
    background-image: url(#{$img-dir}sub/ico-example1.png);
    // background-size: 51px;
    // width: 51px;
    height: 70px;
  }
  li:nth-child(2) a:after {
    background-image: url(#{$img-dir}sub/ico-example2.png);
    // background-size: 51px;
    // width: 51px;
    height: 65px;
  }
  li:nth-child(3) a:after {
    background-image: url(#{$img-dir}sub/ico-example3.png);
    // background-size: 51px;
    // width: 51px;
    height: 65px;
  }
  li:nth-child(4) a:after {
    background-image: url(#{$img-dir}sub/ico-example4.png);
    height: 65px;
  }
  li:nth-child(5) a:after {
    background-image: url(#{$img-dir}sub/ico-example5.png);
    height: 65px;
  }
  ul span {
    font-size: 20px;
    margin-left: 6px;
    transform: rotate(90deg);
  }
}

.example-list {
  background-color: #217dc5;
  font-weight: bold;
  // text-align: center;
  padding: 33px 20px 16px;
  &__title {
    position: relative;
    color: #fff;
    // font-size: 21px;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.06em;
    margin-bottom: 20px;
    @media print, screen and (min-width: 360px) {
      font-size: 21px;
    }
    &:before,
    &:after {
      // position: absolute;
      position: relative;
      display: inline-block;
      background-color: #fff;
      width: 3px;
      // height: 100%;
      height: 22.5px;
      top: 3px;
      content: "";
    }
    &:before {
      margin-right: 15px;
      transform: rotate(-45deg);
    }
    &:after {
      margin-left: 15px;
      transform: rotate(45deg);
    }
    span {
      position: relative;
      &:after {
        position: absolute;
        background-color: #fff;
        width: 4px;
        height: 4px;
        left: 50%;
        top: -3px;
        border-radius: 50%;
        content: "";
        transform: translateX(-50%);
      }
    }
  }
  &__text {
    color: #fff;
    // line-height: 2;
    line-height: 1.79;
    // font-size: 15px;
    font-size: 14px;
    letter-spacing: 0.04em;
  }
  ul {
    color: #333;
    // font-size: 21px;
    // font-size: 18px;
    font-size: 14px;
    text-align: center;
    // letter-spacing: .04em;
    letter-spacing: 0.06em;
    margin-bottom: 20px;
    border-radius: 10px;
    // @media print, screen and (min-width: 360px) {
    //   font-size: 21px;
    // }
  }
  li {
    background-color: #fff;
    // padding: 15px 0;
    padding: 15px 20px;
    margin-bottom: 10px;
    border-radius: 3px;
  }
  ul strong {
    color: $base-color;
  }
}

.flow {
  padding: 30px 20px 15px;
  .lead {
    margin-bottom: 15px;
  }
  &__box {
    background-color: #fff;
    padding: 44px 20px 6px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    > div {
      position: relative;
      &:not(:last-of-type) {
        padding-bottom: 30px;
      }
    }
  }
  &.bg--light .flow__box {
    // background-color: #fff;
    box-shadow: none;
  }
  &__count {
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #f4b119;
    // line-height: 1;
    font-size: 15px;
    letter-spacing: 0.04em;
    width: 60px;
    height: 60px;
    margin-left: -30px;
    left: 50%;
    top: -29px;
    border-radius: 50%;
  }
  span {
    display: block;
    line-height: 1;
    font-weight: bold;
    font-size: 21px;
  }
  &__image {
    margin-bottom: 15px;
  }
  img {
    width: 100%;
    border-radius: 10px;
  }
  &__title {
    color: $base-color;
    line-height: 1.48;
    font-size: 21px;
    letter-spacing: 0.06em;
    margin-bottom: 15px;
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: 0.04em;
  }
  &__list {
    color: #333;
    line-height: 1.79;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.04em;
    p {
      color: #666;
      font-weight: normal;
      padding-left: 1em;
      margin-bottom: 0;
    }
  }
}

.merit {
  padding: 30px 20px 15px;
  .lead {
    margin-bottom: 42px;
  }
  > div:not(:last-of-type) {
    margin-bottom: 37px;
  }
  &__card {
    position: relative;
    background: #fff url(#{$img-dir}sub/ico-merit.png) no-repeat right -8px top -7px;
    background-size: 121px;
    padding: 38px 20px 1px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    &:not(:last-of-type) {
      margin-bottom: 37px;
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
  &.bg--light .merit__card {
    // background-color: #fff;
    box-shadow: none;
  }
  &__count {
    position: absolute;
    color: #fff;
    background-color: #f4b119;
    line-height: 45px;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    width: 146px;
    margin-left: -73px;
    left: 50%;
    top: -22px;
    border-radius: 10px;
  }
  &__title {
    color: $base-color;
    // line-height: 1.48;
    font-size: 21px;
    letter-spacing: 0.06em;
    margin-bottom: 15px;
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
  }
  ul {
    color: #333;
    line-height: 1.93;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
  }
  li {
    position: relative;
    // display: inline-block;
    padding-left: 30px;
    margin-bottom: 10px;
    // box-shadow: inset 0 -10px #ffec60;
    &:after {
      position: absolute;
      background: url(#{$img-dir}sub/ico-check.png) no-repeat;
      background-size: 20px;
      width: 20px;
      height: 20px;
      left: 0;
      top: 4px;
      content: "";
    }
  }
  // span {
  //   box-shadow: inset 0 -10px #ffec60;
  // }
}

.cost {
  padding: 30px 20px;
  .lead {
    margin-bottom: 42px;
  }
  // > div {
  //   margin-bottom: 20px;
  // }
  > div:not(:last-of-type) {
    margin-bottom: 37px;
  }
  &__card {
    position: relative;
    background: #fff url(#{$img-dir}sub/ico-cost.png) no-repeat right -4px top -6px;
    background-size: 83px;
    padding: 38px 20px 1px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    &:not(:last-of-type) {
      margin-bottom: 37px;
    }
  }
  &.bg--light .cost__card {
    box-shadow: none;
  }
  &--star &__card {
    background: #fff url(#{$img-dir}sub/ico-star.png) no-repeat right -20px top -5px;
    background-size: 120px;
  }
  &__count {
    position: absolute;
    color: #fff;
    background-color: #f4b119;
    line-height: 45px;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    width: 146px;
    margin-left: -73px;
    left: 50%;
    top: -22px;
    border-radius: 10px;
  }
  &__title {
    color: $base-color;
    // line-height: 1.48;
    font-size: 21px;
    letter-spacing: 0.06em;
    margin-bottom: 15px;
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
  }
  &__list {
    display: table;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 10px;
  }
  dl {
    display: table-row;
  }
  dt,
  dd {
    display: table-cell;
    padding-bottom: 10px;
  }
  dt {
    color: $base-color;
    padding-right: 10px;
  }
  dd {
    color: #333;
    &:before {
      content: ":";
    }
  }
  .button {
    margin-bottom: 23px;
  }
  &__sub-text {
    color: #333;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.06em;
    margin: 20px 0;
    + .lead {
      margin-bottom: 20px;
    }
  }
  &__box {
    color: #333;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.04em;
    margin-bottom: 0;
    li {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    strong {
      color: $base-color;
    }
  }
  .quote {
    margin: 20px 0 0;
  }
}

.judge {
  padding: 30px 20px 15px;
  ul {
    color: #333;
    font-weight: bold;
    // font-size: 21px;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.04em;
    padding: 20px 20px 10px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  li {
    background-color: #fff;
    padding: 15px 0;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  ul strong {
    color: $base-color;
  }
}

.card-flow {
  padding: 30px 20px;
  .heading {
    margin-bottom: 42px;
  }
  > div:not(:last-of-type) {
    margin-bottom: 37px;
  }
  &__card {
    position: relative;
    background-color: #fff;
    padding: 38px 20px 6px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    &:not(:last-of-type) {
      margin-bottom: 37px;
    }
    // &:last-of-type {
    //   margin-bottom: 20px;
    // }
  }
  &.bg--light .card-flow__card {
    box-shadow: none;
  }
  &__count {
    position: absolute;
    color: #fff;
    background-color: #f4b119;
    line-height: 45px;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    width: 146px;
    margin-left: -73px;
    left: 50%;
    top: -22px;
    border-radius: 10px;
  }
  &__title {
    color: $base-color;
    line-height: 1.48;
    font-size: 21px;
    text-align: center;
    letter-spacing: 0.06em;
    margin-bottom: 15px;
  }
  img {
    display: block;
    margin: 0 auto 15px;
  }
  // div div:first-of-type img {
  //   width: 69px;
  // }
  // div div:nth-of-type(2) img {
  //   width: 51px;
  // }
  // div div:last-of-type img {
  //   width: 106px;
  // }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: 0.04em;
    // margin-bottom: 20px;
  }
  .quote {
    margin: 20px 0 0;
  }
}

.note {
  font-size: 12px;
  letter-spacing: 0.04em;
  padding: 0 20px 30px;
  margin-top: -10px;
  ul {
    color: #999;
    line-height: 1.83;
  }
  &__text {
    color: #999;
    line-height: 1.83;
    margin-bottom: 20px;
    a {
      text-decoration: underline;
      padding: 10px 0;
    }
  }
  &__author {
    color: #333;
    text-align: center;
    margin-bottom: 5px;
  }
  img {
    display: block;
    width: 139px;
    margin: 0 auto;
  }
}

.service {
  padding: 0 20px 40px;
  margin-top: 10px;
  div div {
    margin-bottom: 20px;
  }
  &__title {
    color: #333;
    line-height: 1.48;
    font-size: 21px;
    text-align: center;
    letter-spacing: 0.06em;
    margin-bottom: 10px;
  }
  p {
    font-size: 12px;
    width: 100%;
    max-width: 335px;
    margin: 0 auto;
    @media print, screen and (min-width: 360px) {
      // font-size: 15px;
      font-size: 14px;
    }
    @media print, screen and (min-width: 375px) {
      font-size: 15px;
    }
    + p {
      margin-top: 10px;
    }
  }
  li {
    line-height: 56px;
    margin-bottom: 14px;
    a {
      max-width: 335px;
    }
  }
}

@media print, screen and (min-width: 768px) {
  .quote {
    // box-sizing: border-box;
    line-height: 2.22;
    font-size: 18px;
    text-align: center;
    // padding: 35px 0;
    // padding: 35px 20px;
    // max-width: $base-width;
    // margin-right: auto;
    // margin-left: auto;
  }

  .button-card {
    box-sizing: border-box;
    // padding: 20px 20px 10px;
    padding-top: 40px;
    padding-bottom: 26px;
    max-width: $base-width;
    // margin: 0 20px 30px;
    margin-bottom: 90px;
    @media print, screen and (min-width: 1120px) {
      margin-right: auto;
      margin-left: auto;
    }
    &--section {
      margin-top: 90px;
    }
    // &__lead {
    // line-height: 1.47;
    // }
    &__title {
      // line-height: 1.48;
      font-size: 27px;
      margin-bottom: 20px;
    }
    &__text {
      // line-height: 1.79;
      line-height: inherit;
      font-size: 15px;
      text-align: center;
      margin-bottom: 30px;
    }
    ul {
      justify-content: center;
    }
    li:first-child {
      // height: 58px;
      margin: 0 30px 0 0;
    }
    li:last-child {
      font-size: 18px;
      a {
        width: 366px;
        max-width: 366px;
      }
    }
  }

  .recommend {
    padding: 90px 0;
    &__heading {
      line-height: 64px;
      font-size: 28px;
      width: 385px;
      margin: -11px auto 0;
      margin-top: -37px;
      top: 37px;
      &:before,
      &:after {
        background-size: 55px;
        width: 55px;
        height: 64px;
        top: 14px;
      }
      &:before {
        left: -40px;
      }
      &:after {
        right: -40px;
      }
    }
    > div {
      box-sizing: border-box;
      padding: 30px;
      max-width: $base-width;
      margin: 0 auto;
      border-radius: 10px;
    }
    > div > div {
      // padding: 66px 45px 35px;
      padding: 66px 45px 30px;
    }
    div div div:first-of-type {
      width: 450px;
      // margin-bottom: 20px;
      margin: 0 45px 30px 0;
    }
    img {
      margin-bottom: 40px;
    }
    div div div:first-of-type p {
      margin-bottom: 0;
    }
    div div div:first-of-type a {
      width: 330px;
    }
    div div div:last-of-type {
      width: 435px;
    }
    &__title {
      line-height: 1.5;
      font-size: 30px;
      box-shadow: inset 0 -20px #ffec60;
    }
    &__lead {
      line-height: 1.5;
      font-size: 18px;
      margin: 20px 0;
    }
    &__title + &__text {
      margin: 20px 0;
    }
    ul {
      line-height: 2;
      font-size: 15px;
      margin-bottom: 30px;
    }
    li {
      padding-left: 18px;
      &:after {
        top: 10px;
      }
    }
    &__text {
      line-height: 2;
      font-size: 15px;
      margin-bottom: 30px;
    }
  }

  .example-nav {
    // padding: 33px 20px 19px;
    padding-top: 50px;
    padding-bottom: 129px;
    p {
      font-size: 42px;
      margin-bottom: 42px;
      &:before,
      &:after {
        height: 49.5px;
        top: 10px;
      }
      &:before {
        margin-right: 27px;
      }
      &:after {
        margin-left: 27px;
      }
      span {
        &:after {
          width: 8px;
          height: 8px;
          // top: -3px;
          top: -2px;
        }
      }
    }
    ul {
      flex-wrap: wrap;
      justify-content: center;
      position: absolute;
      line-height: 1.56;
      // font-size: 18px;
      font-size: calc(18 / 1440 * 100vw);
      width: 100%;
      // max-width: $base-width;
      // left: 50%;
      right: 50%;
      // transform: translateX(-50%);
      transform: translateX(50%);
      @media print, screen and (min-width: 900px) {
        font-size: 18px;
      }
      @media print, screen and (min-width: 1080px) {
        width: $base-width;
      }
      @media print, screen and (min-width: 1500px) {
        width: 100%;
      }
    }
    li {
      // width: 180px;
      // margin: 0 30px;
      margin: 0 30px 7px;
      // margin-bottom: 0;
      // &:not(:last-child) {
      //   margin-right: 60px;
      // }
    }
    a {
      // display: inline-flex;
      display: block;
      // justify-content: center;
      // align-items: center;
      padding-left: 0;
      // width: 240px;
      width: 180px;
      // height: 240px;
      height: 180px;
      overflow: hidden;
      top: 0;
      border-radius: 50%;
      transition: all 0.4s;
      @media print, screen and (min-width: 900px) {
        width: 240px;
        height: 240px;
      }
      &:after {
        // background-size: 51px;
        // width: 51px;
        left: 50%;
        bottom: -10px;
        transform: translateX(-50%);
      }
      &:hover {
        color: #fff;
        background-color: $base-color;
        border-color: #0a5d9d;
        top: 4px;
        box-shadow: none;
      }
    }
    li:first-child a {
      padding-top: 66px;
      &:after {
        background-size: 72px;
        width: 72px;
        height: 100px;
      }
    }
    li:nth-child(2) a {
      padding-top: 53px;
      &:after {
        background-size: 74px;
        width: 74px;
        height: 95px;
      }
    }
    li:nth-child(3) a {
      padding-top: 53px;
      &:after {
        background-size: 74px;
        width: 74px;
        height: 95px;
      }
    }
    li:nth-child(4) a {
      padding-top: 66px;
      &:after {
        background-size: 74px;
        width: 74px;
        height: 95px;
      }
    }
    li:nth-child(5) a {
      padding-top: 66px;
      &:after {
        background-size: 74px;
        width: 74px;
        height: 95px;
      }
    }
    ul span {
      display: block;
      margin: 10px 0 0 0;
    }
  }

  .example-list {
    // padding: 33px 20px 16px;
    padding-top: 48px;
    padding-bottom: 33px;
    &__title {
      // font-size: 42px;
      // font-size: calc(42 / 1440 * 100vw);
      font-size: 30px;
      margin-bottom: 23px;
      @media print, screen and (min-width: 980px) {
        font-size: 42px;
      }
      &:before,
      &:after {
        height: 49.5px;
        top: 10px;
      }
      &:before {
        margin-right: 27px;
      }
      &:after {
        margin-left: 27px;
      }
      span {
        &:after {
          width: 8px;
          height: 8px;
          // top: -3px;
          top: -2px;
        }
      }
    }
    &__text {
      line-height: 1.78;
      font-size: 18px;
      text-align: center;
    }
    ul {
      // justify-content: center;
      // font-size: 24px;
      // font-size: 21px;
      font-size: 16px;
      max-width: $base-width;
      // margin: 0 auto 30px;
      margin: 30px auto;
      @media print, screen and (min-width: 980px) {
        font-size: 21px;
      }
    }
    li {
      padding: 30px 0;
      width: calc(100% / 2);
      margin-bottom: 0;
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  .flow {
    // padding: 30px 20px 15px;
    padding-top: 74px;
    // padding-bottom: 56px;
    padding-bottom: 72px;
    .lead {
      margin-bottom: 51px;
    }
    &__box {
      box-sizing: border-box;
      padding: 69px 60px 51px;
      max-width: $base-width;
      margin: 0 auto 70px;
      > div {
        padding-left: 125px;
        &:not(:last-of-type) {
          padding-bottom: 60px;
          &:before {
            position: absolute;
            background-color: #eee;
            width: 4px;
            height: 100%;
            // left: 45.5px;
            left: 45px;
            top: 0;
            // z-index: -1;
            content: "";
          }
        }
      }
    }
    &__count {
      width: 95px;
      height: 95px;
      margin-left: 0;
      left: 0;
      top: 0;
    }
    span {
      // line-height: 1;
      font-size: 36px;
    }
    &__image {
      min-width: 245px;
      width: 245px;
      // margin-bottom: 15px;
      margin: 0 30px 0 0;
    }
    &__title {
      // line-height: 1.48;
      font-size: 21px;
    }
    &__text {
      line-height: 1.8;
      font-size: 15px;
      margin-bottom: 0;
    }
    &__list {
      line-height: 1.8;
      font-size: 15px;
      margin-bottom: 0;
    }
  }

  .merit {
    // padding: 30px 20px 15px;
    padding-top: 90px;
    padding-bottom: 72px;
    .lead {
      margin-bottom: 82px;
    }
    > div {
      // flex-wrap: wrap;
      justify-content: center;
      max-width: $base-width;
      margin: 0 auto 60px;
      &:not(:last-of-type) {
        margin-bottom: 52px;
      }
    }
    &__card {
      box-sizing: border-box;
      padding: 53px 30px 25px;
      width: 340px;
      &:not(:last-of-type) {
        margin: 0 30px 0 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__count {
      font-size: 18px;
    }
    &__title {
      margin-bottom: 30px;
    }
    &__text {
      line-height: 1.8;
      font-size: 15px;
    }
    ul {
      line-height: 1.8;
      font-size: 15px;
    }
    li {
      // margin-bottom: 8px;
      margin-bottom: 20px;
    }
  }

  .cost {
    // padding: 30px 20px;
    padding-top: 90px;
    padding-bottom: 90px;
    .lead {
      margin-bottom: 82px;
    }
    > div {
      max-width: $base-width;
      margin: 0 auto;
      &:not(:last-of-type) {
        margin-bottom: 52px;
      }
    }
    &__card {
      box-sizing: border-box;
      padding: 53px 30px 25px;
      // min-width: 340px;
      // min-width: calc(100% / 3 - 60);
      // max-width: 525px;
      width: calc(100% / 2);
      &:not(:last-of-type) {
        margin: 0 30px 0 0;
      }
    }
    &__count {
      font-size: 18px;
      width: 110px;
      margin-left: -55px;
      top: -14px;
    }
    &__title {
      // letter-spacing: .04em;
      margin-bottom: 30px;
    }
    &__text {
      line-height: 1.8;
      font-size: 15px;
    }
    &__list {
      font-size: 15px;
      margin-bottom: 12px;
    }
    dt,
    dd {
      padding-bottom: 8px;
    }
    .button {
      font-size: 14px;
      margin-bottom: 19px;
      @media print, screen and (min-width: 960px) {
        font-size: 18px;
      }
      a {
        width: 100%;
        max-width: 242px;
      }
    }
    &__sub-text {
      font-size: 21px;
      margin: 40px 0 30px;
      + .lead {
        margin-bottom: 40px;
      }
    }
    &__box {
      line-height: 1.48;
      font-size: 21px;
      max-width: $base-width;
      margin: 0 auto;
      li {
        // display: inline-flex;
        // flex-direction: column;
        // flex-wrap: wrap;
        padding: 30px;
        width: calc(100% / 3);
        &:not(:last-child) {
          margin: 0 30px 0 0;
        }
        @media print, screen and (min-width: 960px) {
          &:last-child {
            padding-top: 46px;
          }
        }
      }
    }
    .quote {
      margin-top: 60px;
    }
  }

  .judge {
    // padding: 30px 20px 15px;
    padding-top: 90px;
    padding-bottom: 72px;
    .lead {
      margin-bottom: 40px;
    }
    ul {
      box-sizing: border-box;
      font-size: 24px;
      padding: 30px;
      max-width: $base-width;
      margin: 0 auto 40px;
    }
    li {
      padding: 30px 0;
      width: calc(100% / 2);
      margin-bottom: 0;
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  .card-flow {
    // padding: 30px 20px 15px;
    padding-top: 90px;
    padding-bottom: 90px;
    .heading {
      margin-bottom: 62px;
    }
    > div {
      justify-content: center;
      max-width: $base-width;
      margin: 0 auto;
      &:not(:last-of-type) {
        margin-bottom: 52px;
      }
    }
    &__card {
      padding: 53px 30px 15px;
      width: calc(100% / 3);
      &:not(:last-of-type) {
        margin: 0 30px 0 0;
      }
      // &:last-of-type {
      //   margin-bottom: 0;
      // }
    }
    &__count {
      font-size: 18px;
      width: 126px;
      margin-left: -63px;
    }
    &__title {
      // line-height: 1.48;
      margin-bottom: 30px;
    }
    img {
      margin-bottom: 20px;
    }
    // div div:first-of-type img {
    //   width: 69px;
    // }
    // div div:nth-of-type(2) img {
    //   width: 51px;
    // }
    // div div:last-of-type img {
    //   width: 106px;
    // }
    &__text {
      line-height: 1.8;
      font-size: 15px;
    }
    .quote {
      margin-top: 60px;
    }
  }

  .note {
    // padding: 0 20px 30px;
    padding-bottom: 50px;
    max-width: $base-width;
    margin-top: 0;
    @media print, screen and (min-width: 1120px) {
      margin-right: auto;
      margin-left: auto;
    }
    // ul {
    // }
    &__text {
      margin-bottom: 40px;
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
    &__author {
      margin-bottom: 10px;
    }
  }

  .service {
    // padding: 50px 20px 40px;
    //padding-top: 60px;
    margin: 0;
    //margin-top: 75px;
    > div {
      max-width: $base-width;
      margin: 0 auto;
    }
    div div {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
    }
    &__title {
      // line-height: 1.48;
      margin-bottom: 0;
    }
    p {
      // width: 100%;
      max-width: 335px;
      margin: 0;
      + p {
        // margin: 0 0 0 30px;
        // margin: 0 0 0 -63px;
        margin-top: 0;
        @media print, screen and (min-width: 1120px) {
          margin-left: -63px;
        }
      }
    }
    ul {
      flex-wrap: wrap;
      // justify-content: space-between;
      margin-right: -15px;
    }
    li {
      font-size: 15px;
      margin: 0 15px 19px 0;
      // &:nth-child(5n) {
      //   margin-right: 0;
      // }
      a {
        width: 200px;
        // max-width: 204px;
      }
    }
    .service__list--small {
      font-size: 13px;
    }
  }
}
