// talk

.judge--talk {
  .heading {
    margin-bottom: 20px;
  }
}

.flow--talk {
  .heading {
    margin-bottom: 20px;
  }
}

.card-flow--talk {
  > div:first-of-type div:first-of-type img {
    width: 71px;
  }
  > div:first-of-type  div:nth-of-type(2) img {
    width: 102px;
  }
  > div:first-of-type  div:last-of-type img {
    width: 51px;
  }
  > div:nth-of-type(2)  div:first-of-type img {
    width: 63px;
  }
  > div:nth-of-type(2)  div:nth-of-type(2) img {
    width: 54px;
  }
  > div:nth-of-type(2)  div:last-of-type img {
    width: 63px;
  }
  > div:last-of-type  div:first-of-type img {
    width: 63px;
  }
  > div:last-of-type  div:last-of-type img {
    width: 54px;
  }
}

@media print, screen and (min-width: 768px) {

.judge--talk {
  .heading {
    margin-bottom: 40px;
  }
}

.flow--talk {
  .heading {
    margin-bottom: 50px;
  }
}

.button-card--talk {
  margin-bottom: 40px;
}


}

