// service

.service-card {
  padding: 30px 20px;
  .lead {
    margin-bottom: 20px;
  }
  &__item {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  &__item div {
    &:nth-of-type(2) {
      padding: 20px 0;
      border-top: 1px dotted $base-color;
      border-bottom: 1px dotted $base-color;
      margin: 20px 0;
    }
  }
  &__merit {
    color: #fff;
    background-color: #f4b119;
    line-height: 30px;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    width: 120px;
    margin: 0 auto 20px;
    border-radius: 10px;
  }
  img {
    display: block;
    margin: 0 auto 20px;
  }
  &__item div:first-of-type img {
    width: 145px;
  }
  &__item div:nth-of-type(2) img {
    width: 100px;
  }
  &__item div:last-of-type img {
    width: 95px;
  }
  &__title {
    color: #333;
    line-height: 1.6;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: .04em;
    margin-bottom: 10px;
    strong {
      color: $base-color;
    }
  }
  &__text {
    line-height: 2;
    font-size: 12px;
    letter-spacing: .04em;
    margin-bottom: 0;
  }
  .quote {
    margin-bottom: 20px;
  }
  &__box {
    background-color: #fff;
    padding: 20px 20px 10px;
    border-radius: 10px;
  }
  &__sub-text {
    color: $base-color;
    font-weight: bold;
    font-size: 21px;
    text-align: center;
    letter-spacing: .06em;
  }
}

@media print, screen and (min-width: 768px) {

.service-card {
  // padding: 30px 20px;
  padding-top: 90px;
  padding-bottom: 90px;
  .lead {
    margin-bottom: 40px;
  }
  &__item {
    box-sizing: border-box;
    padding: 40px;
    max-width: $base-width;
    margin: 0 auto 40px;
  }
  &__item div {
    position: relative;
    width: calc(100% / 3);
    &:first-of-type {
      padding-top: 53px;
    }
    &:nth-of-type(2) {
      padding: 63px 45px 0;
      border-top: 0;
      border-right: 1px dotted $base-color;
      border-bottom: 0;
      border-left: 1px dotted $base-color;
      margin: 0 45px;
    }
    &:last-of-type {
      padding-top: 44px;
    }
  }
  &__merit {
    position: absolute;
    margin-left: -60px;
    left: 50%;
    top: 0;
  }
  // img {
    // margin: 0 auto 20px;
  // }
  &__title {
    line-height: 1.47;
    margin-bottom: 15px;
  }
  .quote {
    margin-bottom: 40px;
  }
  &__box {
    box-sizing: border-box;
    // padding: 20px 20px 10px;
    padding-top: 37px;
    padding-bottom: 29px;
    max-width: $base-width;
    margin: 0 auto;
  }
  &__sub-text {
    font-size: 27px;
    margin-bottom: 30px;
  }
}


}

