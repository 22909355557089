// sitemap

.sitemap {
  padding: 30px 20px 16px;
  border-top: 1px solid #ddd;
  &__heading {
    color: #333;
    font-size: 21px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 20px;
  }
  > div {
    margin-bottom: 30px;
  }
  &__title {
    color: #333;
    font-size: 18px;
    letter-spacing: .04em;
    margin-bottom: 10px;
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: .04em;
    margin-bottom: 10px;
  }
  &__list {
    background-color: #eee;
    // line-height: 49px;
    font-weight: bold;
    font-size: 14px;
    padding: 10px 20px 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    li {
      position: relative;
      border-bottom: 1px solid $base-color;
    }
    a {
      display: block;
      padding: 14px 40px 14px 0;
    }
    span {
      position: absolute;
      font-size: 20px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .sitemap__sub-list {
    li {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

@media print, screen and (min-width: 768px) {

.sitemap {
  // padding: 30px 20px 16px;
  padding-top: 26px;
  padding-bottom: 0;
  border-top: 0;
  &__heading {
    font-size: 36px;
    margin-bottom: 60px;
  }
  > div {
    max-width: $base-width;
    margin: 0 auto 50px;
  }
  div div {
    width: calc(100% / 2);
    &:first-of-type {
      margin-right: 30px;
    }
  }
  &__title {
    margin-bottom: 14px;
  }
  &__text {
    margin-bottom: 20px;
  }
  &__list {
    box-sizing: border-box;
    font-size: 15px;
    padding: 10px 30px 30px;
    // min-height: calc(100% - 111px);
    min-height: 652px;
    margin-bottom: 0;
    a {
      transition: color .2s;
      &:after {
        position: absolute;
        background-color: #1289e6;
        // background-color: #333;
        width: 0;
        height: 1px;
        left: 0;
        bottom: -1px;
        content: "";
        transition: width .2s;
      }
      &:hover {
        color: #1289e6;
        // color: #000;
        &:after {
          width: 100%;
        }
      }
    }
    span {
    }
  }
  .sitemap__sub-list {
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: $base-width;
    margin: 0 auto -10px;
    li {
      width: 320px;
      // margin-bottom: 10px;
    }
    // a {
    //   transition: color .2s;
    //   &:after {
    //     // background-color: #000;
    //     background-color: #1289e6;
    //   }
    //   &:hover {
    //     color: #1289e6;
    //   }
    // }
  }
}


}

