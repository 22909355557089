// module

.sp--hidden {
  display: none;
}

.flex {
  display: flex;
}

.sp--flex {
  display: flex;
}

.bg--light {
  background-color: #e3f3ff;
}

.bg--dark {
  background-color: $base-color;
}

.button {
  line-height: 50px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  a {
    display: block;
    color: #fff;
    max-width: 290px;
    margin: 0 auto;
    border-radius: 100px;
  }
  &--white {
    line-height: 46px;
    a {
      color: $base-color;
      background-color: #fff;
      border: 2px solid $base-color;
      box-shadow: 0 4px #0a5d9d;
    }
  }
  &--green a {
    background-color: #81cc1a;
    box-shadow: 0 5px #64a20f;
  }
  &--orange a {
    background-color: #f48f19;
    box-shadow: 0 5px #c16c09;
  }
  &--tel {
    line-height: 46px;
    a {
      color: $base-color;
      background-color: #fff;
      border: 2px solid $base-color;
      box-shadow: 0 4px #0a5d9d;
    }
  }
  span {
    position: relative;
    font-size: 20px;
    margin-left: 6px;
    top: 3px;
  }
}

.line-button {
  position: relative;
  display: inline-block;
  // line-height: 46px;
  line-height: 42px;
  font-weight: bold;
  font-size: 14px;
  a {
    display: block;
    padding: 0 25px 0 0;
    border-bottom: 1px solid;
  }
  &--white a {
    color: #fff;
    border-bottom-color: #fff;
  }
  &--pink a {
    color: #de3f7c;
    border-bottom-color: #de3f7c;
    span {
      background-color: #fff;
      border-radius: 50%;
    }
  }
  &--blue a {
    // color: $base-color;
    color: #217dc5;
    // border-bottom-color: $base-color;
    border-bottom-color: #217dc5;
  }
  span {
    position: absolute;
    font-size: 20px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.hero {
  // text-align: center;
  padding-bottom: 16px;
  &__box {
    position: relative;
    background-color: #fff;
    padding-top: 20px;
    margin: 0 20px -20px;
    bottom: 20px;
    border-radius: 10px;
  }
  &__lead {
    color: $base-color;
    line-height: 1.75;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.04em;
    // margin-bottom: 10px;
    margin: 0 20px 10px;
  }
  &__title {
    color: #333;
    line-height: 1.52;
    font-size: 21px;
    text-align: center;
    letter-spacing: 0.06em;
    // margin-bottom: 17px;
    margin: 0 20px 17px;
  }
  &__text {
    position: relative;
    color: #333;
    line-height: 2;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.04em;
    padding: 16px 20px;
    // margin-bottom: 0;
    overflow: hidden;
    &:before,
    &:after {
      position: absolute;
      color: #f4b119;
      line-height: 1;
      font-size: 60px;
    }
    &:before {
      left: -39px;
      top: 0;
      content: "「";
    }
    &:after {
      right: -39px;
      bottom: 0;
      content: "」";
    }
  }
  a {
    text-decoration: underline;
    padding: 5px 0;
  }
  // strong {
  //   color: $base-color;
  // }
}

.action {
  justify-content: center;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.04em;
  dl:first-of-type {
    margin-right: 24px;
  }
  dt {
    margin-bottom: 10px;
  }
  img {
    width: 30px;
    margin: 0 5px;
  }
}

.news {
  padding: 30px 20px 35px;
  &__heading {
    color: #333;
    font-size: 24px;
    letter-spacing: 0.06em;
    margin-bottom: 20px;
  }
  &__card a {
    position: relative;
    display: block;
    background-color: #fff;
    letter-spacing: 0.04em;
    padding: 25px 68px 10px 20px;
    border-radius: 10px;
    box-shadow: 0 5px #d0eafd;
    &:after {
      position: absolute;
      background-color: #e3f3ff;
      width: 1px;
      height: 100%;
      right: 51px;
      top: 0;
      content: "";
    }
  }
  time {
    display: block;
    color: #666;
    // line-height: 1;
    font-size: 12px;
    margin-bottom: 10px;
  }
  &__title {
    color: #333;
    line-height: 1.6;
    font-size: 15px;
  }
  &__card span {
    position: absolute;
    font-size: 20px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.heading {
  color: #333;
  line-height: 1.48;
  font-size: 21px;
  text-align: center;
  letter-spacing: 0.06em;
  margin-bottom: 15px;
  strong {
    box-shadow: inset 0 -10px #ffec60;
  }
}

.lead {
  line-height: 1.6;
  font-weight: bold;
  font-size: 15px;
  // text-align: center;
  letter-spacing: 0.04em;
}

.example {
  padding: 30px 20px 15px;
  // &:nth-of-type(odd) {
  // &:nth-of-type(even) {
  // &#example1,
  // &#example3,
  // &#example5 {
  //   background-color: #e3f3ff;
  // }
  .heading {
    margin-bottom: 15px;
  }
  .lead {
    margin-bottom: 20px;
  }
  &__heading {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    color: #fff;
    // background-color: $base-color;
    // font-size: 21px;
    font-size: 16px;
    // text-align: center;
    letter-spacing: 0.06em;
    // padding-left: 40px;
    padding-left: 60px;
    width: 100%;
    height: 82px;
    margin-bottom: 26px;
    border-radius: 10px;
    @media print, screen and (min-width: 360px) {
      // font-size: 21px;
      padding-left: 40px;
    }
    @media print, screen and (min-width: 375px) {
      // font-size: 21px;
      font-size: 18px;
    }
    &:before,
    &:after {
      position: absolute;
      content: "";
    }
    &:before {
      background-repeat: no-repeat;
      background-size: 51px;
      width: 51px;
      left: 25px;
      bottom: 0;
    }
    &:after {
      border: 11px solid;
      border-color: $base-color transparent transparent transparent;
      margin-left: -11px;
      left: 50%;
      bottom: -22px;
    }
  }
  // &:nth-of-type(1) .example__heading:before {
  &#example1 .example__heading:before {
    background-image: url(#{$img-dir}sub/ico-example1.png);
    // background-size: 51px;
    // width: 51px;
    height: 70px;
  }
  &#example2 .example__heading:before {
    background-image: url(#{$img-dir}sub/ico-example2.png);
    // background-size: 51px;
    // width: 51px;
    height: 65px;
  }
  &#example3 .example__heading:before {
    background-image: url(#{$img-dir}sub/ico-example3.png);
    // background-size: 51px;
    // width: 51px;
    height: 65px;
  }
  &#example4 .example__heading:before {
    background-image: url(#{$img-dir}sub/ico-example4.png);
    height: 65px;
  }
  &#example5 .example__heading:before {
    background-image: url(#{$img-dir}sub/ico-example5.png);
    height: 65px;
  }
  dl {
    justify-content: center;
    // align-items: center;
    text-align: center;
  }
  dt {
    color: #333;
    // line-height: 30px;
    // font-size: 15px;
    font-size: 12px;
    letter-spacing: 0.04em;
    margin: 6px 5px 5px 0;
    &:after {
      margin-left: 0.25em;
      content: ":";
    }
  }
  dd {
    color: $base-color;
    line-height: 26px;
    font-weight: bold;
    font-size: 10px;
  }
  dl ul {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  dl li {
    background-color: #fff;
    // width: 86px;
    width: 56px;
    border: 2px solid #d0eafd;
    // margin-right: 10px;
    margin: 0 5px 5px 0;
    border-radius: 5px;
  }
  &__lead {
    line-height: 1.6;
    font-weight: bold;
    font-size: 15px;
    // text-align: center;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
  }
  &__card {
    background-color: #fff;
    padding: 20px 20px 9px;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  }
  &.bg--light .example__card {
    // box-shadow: 0 0 10px rgba(0,0,0,.16);
    box-shadow: none;
  }
  // &__card div:first-of-type {
  //   margin-bottom: 20px;
  // }
  &__card img {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  &__sub-title {
    color: $base-color;
    line-height: 1.6;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.06em;
    margin-bottom: 15px;
  }
  &__title {
    display: inline;
    color: #000;
    line-height: 1.56;
    font-size: 18px;
    letter-spacing: 0.06em;
    box-shadow: inset 0 -10px #ffec60;
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: 0.04em;
    // margin: 15px 0 20px;
    margin-bottom: 20px;
  }
  &__title + &__text {
    margin-top: 15px;
  }
  &__bubble {
    position: relative;
    color: #fff;
    background-color: #f4b119;
    line-height: 40px;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.04em;
    margin-bottom: 21px;
    border-radius: 10px;
    &:after {
      position: absolute;
      border: 6px solid;
      border-color: #f4b119 transparent transparent transparent;
      margin-left: -6px;
      left: 50%;
      bottom: -12px;
      content: "";
    }
  }
  &__list {
    color: #333;
    line-height: 1.93;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
  }
  &__list li {
    position: relative;
    padding-left: 20px;
    &:after {
      position: absolute;
      background: url(#{$img-dir}module/ico-example.png) no-repeat;
      background-size: 13px;
      width: 13px;
      height: 9px;
      left: 0;
      top: 9px;
      content: "";
    }
  }
  &__service {
    flex-wrap: wrap;
    color: #000;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 10px;
  }
  &__icon {
    position: relative;
    box-sizing: border-box;
    padding-left: 25px;
    width: calc(100% / 2);
    margin-bottom: 10px;
    &:before {
      position: absolute;
      background-repeat: no-repeat;
      left: 0;
      top: 0;
      content: "";
    }
    &--9 {
      padding-left: 34px;
    }
    &--1:before {
      background-image: url(#{$img-dir}sub/change/ico-example1.png);
      background-size: 21px;
      width: 21px;
      height: 20px;
    }
    &--2:before {
      background-image: url(#{$img-dir}sub/change/ico-example2.png);
      background-size: 21px;
      width: 21px;
      // height: 20px;
      height: 21px;
    }
    &--3:before {
      background-image: url(#{$img-dir}sub/change/ico-example3.png);
      background-size: 20px;
      width: 20px;
      height: 20px;
    }
    &--4:before {
      background-image: url(#{$img-dir}sub/change/ico-example4.png);
      background-size: 19px;
      width: 19px;
      height: 20px;
    }
    &--5:before {
      background-image: url(#{$img-dir}sub/change/ico-example5.png);
      background-size: 22px;
      width: 22px;
      height: 20px;
    }
    &--6:before {
      background-image: url(#{$img-dir}sub/change/ico-example6.png);
      background-size: 24px;
      width: 24px;
      height: 20px;
    }
    &--7:before {
      background-image: url(#{$img-dir}sub/change/ico-example7.png);
      background-size: 17px;
      width: 17px;
      height: 20px;
    }
    &--8:before {
      background-image: url(#{$img-dir}sub/change/ico-example8.png);
      background-size: 20px;
      width: 20px;
      height: 20px;
    }
    &--9:before {
      background-image: url(#{$img-dir}sub/change/ico-example9.png);
      background-size: 29px;
      width: 29px;
      height: 20px;
    }
    &--10:before {
      background-image: url(#{$img-dir}sub/change/ico-example10.png);
      background-size: 20px;
      width: 20px;
      height: 20px;
    }
  }
  &__icon-title {
    // display: inline;
    // color: #000;
    color: #333;
    // line-height: 1.56;
    font-size: 18px;
    letter-spacing: 0.06em;
    margin-bottom: 15px;
    // box-shadow: inset 0 -10px #ffec60;
    &:before {
      position: relative;
      display: inline-block;
      background-repeat: no-repeat;
      // margin-right: 5px;
      margin-right: 15px;
      top: 8px;
      content: "";
    }
    &--1:before {
      background-image: url(#{$img-dir}sub/change/ico-example1.png);
      background-size: 37px;
      width: 37px;
      height: 36px;
    }
    &--2:before {
      background-image: url(#{$img-dir}sub/change/ico-example7.png);
      background-size: 31px;
      width: 31px;
      height: 36px;
    }
    &--3:before {
      background-image: url(#{$img-dir}sub/change/ico-example2.png);
      background-size: 37px;
      width: 37px;
      // height: 36px;
      height: 37px;
    }
    &--4:before {
      background-image: url(#{$img-dir}sub/change/ico-example5.png);
      background-size: 38px;
      width: 38px;
      height: 36px;
    }
    &--5:before {
      background-image: url(#{$img-dir}sub/change/ico-example11.png);
      background-size: 38px;
      width: 38px;
      height: 36px;
    }
    &--6:before {
      background-image: url(#{$img-dir}sub/change/ico-example12.png);
      background-size: 64px;
      width: 64px;
      height: 36px;
    }
    span {
      box-shadow: inset 0 -10px #ffec60;
    }
  }
  &__button {
    background-color: #fff;
    padding: 20px 20px 5px;
    border-radius: 10px;
    &-text {
      color: $base-color;
      line-height: 1.48;
      font-weight: bold;
      font-size: 21px;
      text-align: center;
      letter-spacing: 0.06em;
    }
    li {
      margin-bottom: 14px;
    }
  }
  &__multi {
    padding: 20px 20px 9px;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    .example__card {
      // padding: 20px 20px 9px;
      padding: 0;
      // margin-bottom: 15px;
      border-radius: 0;
      box-shadow: none;
      &:not(:last-of-type) {
        padding-bottom: 9px;
        border-bottom: 2px solid #ddd;
        margin-bottom: 20px;
      }
    }
  }
  &__note {
    position: relative;
    color: #333;
    line-height: 2;
    font-size: 15px;
    // text-align: center;
    letter-spacing: 0.04em;
    // padding-top: 56px;
    padding-top: 103px;
    margin-bottom: -15px;
    @media print, screen and (min-width: 360px) {
      padding-top: 90px;
    }
    @media print, screen and (min-width: 375px) {
      padding-top: 56px;
    }
    p {
      position: absolute;
      top: 5px;
    }
    strong {
      // color: $base-color;
      // color: #1ebe9f;
      font-weight: normal;
      box-shadow: inset 0 -10px #ffec60;
    }
  }
  &__sub-text {
    color: #333;
    font-weight: bold;
    // font-size: 18px;
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.06em;
    margin: 30px 0;
  }
  &__box {
    background-color: #fff;
    letter-spacing: 0.04em;
    padding: 10px 20px;
    border-radius: 10px;
    div {
      padding: 20px 0;
      &:nth-of-type(2) {
        border-top: 1px dotted $base-color;
        border-bottom: 1px dotted $base-color;
      }
    }
    &-title {
      color: $base-color;
      font-size: 18px;
      text-align: center;
      margin-bottom: 20px;
    }
    &-text {
      line-height: 1.79;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
  .quote {
    margin-top: 20px;
  }
}

.cta {
  color: #fff;
  text-align: center;
  padding: 20px 20px 4px;
  border-radius: 10px;
  margin: 0 20px 30px;
  &__lead {
    line-height: 1.47;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.04em;
    margin-bottom: 10px;
  }
  &__heading {
    line-height: 1.48;
    font-size: 21px;
    letter-spacing: 0.06em;
    margin-bottom: 10px;
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
  }
  &__title {
    line-height: 1.56;
    font-size: 18px;
    letter-spacing: 0.04em;
    // margin-bottom: 15px;
    &:first-of-type {
      margin-bottom: 63px;
    }
    &:last-of-type {
      margin-bottom: 15px;
    }
  }
  > div {
    margin-bottom: 20px;
  }
  &__box {
    position: relative;
    background-color: #fff;
    padding: 70px 20px 6px;
    // margin-bottom: 63px;
    border-radius: 10px;
    + div {
      margin-top: 63px;
    }
  }
  &__box div {
    position: absolute;
    box-sizing: border-box;
    color: $base-color;
    background-color: #fff;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.04em;
    padding-top: 20px;
    width: 120px;
    height: 120px;
    margin-left: -60px;
    left: 50%;
    top: -48px;
    border-radius: 50%;
  }
  &__point {
    margin-bottom: 15px;
  }
  &__box:first-of-type img {
    width: 51px;
  }
  &__box:nth-of-type(2) img {
    width: 58px;
  }
  &__box:last-of-type img {
    width: 44px;
  }
  &__sub-text {
    color: #666;
    line-height: 1.79;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.04em;
  }
  &__button {
    margin-bottom: 25px;
    li {
      margin-bottom: 15px;
    }
    li:last-child {
      font-size: 13px;
      @media print, screen and (min-width: 360px) {
        font-size: 15px;
      }
    }
  }
  &__image {
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px 20px 0;
    // margin-bottom: 20px;
    border-radius: 10px;
    li {
      // width: 114px;
      width: calc(50% - 14.7px / 2);
      // width: 50%;
      margin-bottom: 20px;
    }
    img {
      width: 100%;
      max-width: 114px;
    }
  }
}

.content {
  background-color: #217dc5;
  // padding: 53px 0 40px;
  padding: 53px 0 50px;
  &--news {
    padding-bottom: 40px;
  }
  &__heading {
    color: #fff;
    // font-size: 21px;
    font-size: 16px;
    letter-spacing: 0.06em;
    margin: 0 0 27px 20px;
    @media print, screen and (min-width: 360px) {
      // font-size: 21px;
      font-size: 20px;
    }
    @media print, screen and (min-width: 375px) {
      font-size: 21px;
    }
  }
  &__heading:not(:first-child) {
    margin-top: 44px;
  }
  &__heading:last-of-type {
    margin-bottom: 20px;
  }
  &__card {
    padding: 0 20px;
    a {
      // display: block;
      background-color: #fff;
      padding: 15px 15px 5px;
      margin-bottom: 15px;
      border-radius: 10px;
      box-shadow: 0 5px #0a5d9d;
    }
  }
  &__image {
    // min-width: 80px;
    min-width: calc(80 / 375 * 100vw);
    // max-width: 80px;
    max-width: calc(80 / 375 * 100vw);
    margin-right: 10px;
    // border-radius: 10px;
    // overflow: hidden;
    img {
      border-radius: 10px;
    }
  }
  &__title {
    color: #333;
    line-height: 1.46;
    font-size: 13px;
    letter-spacing: 0.06em;
    height: 36px;
    margin-bottom: 5px;
    overflow: hidden;
  }
  &__description {
    color: #666;
    line-height: 1.64;
    font-size: 11px;
    letter-spacing: 0.04em;
    height: 36px;
    margin-bottom: 5px;
    overflow: hidden;
  }
  &__card time {
    display: block;
    color: #666;
    line-height: 1.6;
    font-size: 10px;
  }
  &__text {
    color: #666;
    line-height: 1.6;
    font-size: 10px;
  }
}

.slider {
  // padding: 67px 0 0 20px;
  padding: 67px 0 5px 20px;
  // margin-top: -67px;
  // margin-bottom: -5px;
  margin: -67px 0 -5px;
  overflow: hidden;
  &__card {
    // box-sizing: border-box;
    position: relative;
    // display: block;
    background-color: #fff;
    min-width: 270px;
    // min-width: calc(100% - 105px);
    max-width: 270px;
    // max-width: calc(100% - 105px);
    height: 100%;
    // margin-right: 20px;
    outline: none;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px #0a5d9d;
  }
  &__image {
    position: relative;
    height: 159px;
    margin-bottom: 25px;
    overflow: hidden;
  }
  img {
    position: absolute;
    min-width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  time {
    display: block;
    color: #666;
    // line-height: 2.67;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.04em;
    margin: 0 20px 15px;
  }
  &__title {
    color: #333;
    line-height: 1.6;
    font-size: 15px;
    letter-spacing: 0.04em;
    height: 48px;
    margin: 0 20px 15px;
    overflow: hidden;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 2;
  }
  &__description {
    color: #666;
    line-height: 1.67;
    font-size: 12px;
    letter-spacing: 0.04em;
    // height: 3em;
    height: 60px;
    margin: 0 20px 20px;
    overflow: hidden;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 3;
  }
  &__category {
    flex-wrap: wrap;
    color: $base-color;
    // line-height: 2.67;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.04em;
    // margin: 0 20px 35px;
    margin: 0 20px 30px;
    li:not(:last-child):after {
      content: "、";
    }
  }
  &__type {
    position: absolute;
    box-sizing: border-box;
    color: #fff;
    background-color: #de3f7c;
    line-height: 30px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    padding: 0 15px 0 17px;
    min-width: 75px;
    top: 0;
    border-radius: 10px 0 10px 0;
    &:before {
      position: relative;
      display: inline-block;
      background-repeat: no-repeat;
      // margin-right: 7px;
      margin-right: 5px;
      content: "";
    }
    &--article:before {
      background-image: url(#{$img-dir}module/ico-article.png);
      background-size: 12px;
      width: 12px;
      height: 12px;
      top: 1px;
    }
    &--video:before {
      background-image: url(#{$img-dir}module/ico-video.png);
      background-size: 14px;
      width: 14px;
      height: 14px;
      top: 2px;
    }
    &--product:before {
      background-image: url(#{$img-dir}module/ico-product.png);
      background-size: 14px;
      width: 14px;
      height: 12px;
      top: 1px;
    }
  }
}

@media print, screen and (min-width: 768px) {
  .sp--hidden {
    display: block;
  }

  .pc--hidden {
    display: none;
  }

  .sp--flex {
    display: block;
  }

  .pc--flex {
    display: flex;
  }

  .button {
    line-height: 56px;
    font-size: 18px;
    a {
      width: 326px;
      max-width: 326px;
      border: 2px solid transparent;
      // margin: 0 auto;
      // border-radius: 100px;
      transition: all 0.4s;
      &:hover {
        background-color: #fff;
        box-shadow: none;
        transform: translateY(4px);
      }
    }
    &--white {
      a {
        width: 176px;
        border-color: $base-color;
        // box-shadow: 0 4px #0a5d9d;
        &:hover {
          color: #fff;
          background-color: $base-color;
          border-color: #0a5d9d;
        }
      }
    }
    &--green a {
      box-shadow: 0 4px #64a20f;
      &:hover {
        color: #81cc1a;
        border-color: #81cc1a;
      }
    }
    &--orange a {
      box-shadow: 0 4px #c16c09;
      &:hover {
        color: #f48f19;
        border-color: #f48f19;
      }
    }
    &--tel {
      // line-height: 60px;
      width: 366px;
      height: 58px;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      a {
        // display: block;
        color: #fff;
        background-color: transparent;
        // width: auto;
        // max-width: none;
        border: 0;
        cursor: default;
        box-shadow: none;
        &:hover {
          background-color: transparent;
          transform: none;
        }
      }
      span {
        display: none;
      }
    }
    span {
      margin-left: 10px;
      // top: 3px;
      top: 2px;
    }
  }

  .line-button {
    // line-height: 46px;
    line-height: 45px;
    font-size: 15px;
    // width: 120px;
    a {
      &:after {
        position: absolute;
        // width: 100%;
        width: 0;
        height: 1px;
        left: 0;
        bottom: 0;
        content: "";
        transition: width 0.2s;
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
    &--white a {
      &:after {
        background-color: #0a5d9d;
        bottom: -1px;
      }
    }
    &--pink a {
      &:after {
        background-color: #fff;
      }
    }
    &--blue a {
      transition: color 0.2s;
      &:after {
        // background-color: #0a5d9d;
        // background-color: #fff;
        background-color: #1289e6;
      }
      &:hover {
        // color: #333;
        color: #1289e6;
      }
    }
  }

  .hero {
    padding-bottom: 42px;
    > img {
      width: 100%;
    }
    &__box {
      padding-top: 45px;
      max-width: $base-width;
      margin: 0 20px -20px;
      margin-bottom: -45px;
      bottom: 45px;
      @media print, screen and (min-width: 1120px) {
        margin-right: auto;
        margin-left: auto;
      }
    }
    &__lead {
      // line-height: 1.75;
      line-height: inherit;
      font-size: 18px;
      // margin-bottom: 10px;
    }
    &__title {
      // line-height: 1.52;
      font-size: 39px;
      // margin: 0 20px 15px;
      margin-bottom: 15px;
    }
    .action {
      margin-bottom: 30px;
    }
    &__text {
      line-height: 2.22;
      font-size: 18px;
      text-align: center;
      // padding: 35px 0;
      padding: 35px 20px;
      &:before,
      &:after {
        font-size: 100px;
      }
      &:before {
        left: -65px;
      }
      &:after {
        right: -65px;
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .action {
    font-size: 15px;
    margin-top: 40px;
    dl {
      align-items: center;
    }
    dl:first-of-type {
      margin-right: 30px;
    }
    dt {
      margin: 0 5px 0 0;
    }
    a {
      transition: opacity 0.2s;
      &:hover {
        opacity: 0.8;
      }
    }
    img {
      width: 35px;
      // margin: 0 5px;
    }
    ul {
      margin-bottom: 0;
    }
  }

  .news {
    justify-content: center;
    // padding: 50px 20px 40px;
    padding-top: 60px;
    padding-bottom: 65px;
    &__heading {
      font-size: 36px;
      width: 240px;
      // margin-bottom: 20px;
    }
    &__card {
      width: 840px;
    }
    &__card a {
      display: flex;
      // padding: 25px 82px 10px 30px;
      padding: 24px 82px 9px 30px;
      // margin-bottom: 15px;
      transition: box-shadow 0.4s, transform 0.4s;
      &:before {
        position: absolute;
        background: url(#{$img-dir}module/ico-news.png) no-repeat;
        background-size: 20px;
        width: 20px;
        height: 20px;
        right: 15px;
        top: 50%;
        content: "";
        // visibility: hidden;
        opacity: 0;
        transform: translateY(-50%);
      }
      &:hover {
        box-shadow: none;
        transform: translateY(5px);
        &:before {
          // visibility: visible;
          opacity: 1;
        }
        span {
          display: none;
          // color: #fff;
          // background-color: $base-color;
          // transform: scale(1.3) translateY(-50%);
        }
      }
    }
    time {
      position: relative;
      // display: block;
      min-width: 111px;
      // margin-bottom: 10px;
      top: 2px;
    }
    &__title {
      line-height: 1.47;
      font-size: 15px;
    }
    // &__card span {
    // border-radius: 50%;
    // transition: color .2s, background-color .2s;
    // }
  }

  .heading {
    line-height: 1.5;
    font-size: 36px;
    // letter-spacing: .06em;
    margin-bottom: 30px;
    strong {
      box-shadow: inset 0 -20px #ffec60;
    }
  }

  .lead {
    line-height: 1.78;
    font-size: 18px;
    text-align: center;
  }

  .example {
    // padding: 30px 20px 15px;
    padding-top: 90px;
    padding-bottom: 60px;
    &#example1 {
      padding-top: 180px;
    }
    .heading {
      margin-bottom: 27px;
    }
    .lead {
      margin-bottom: 60px;
    }
    &__heading {
      display: table;
      line-height: 75px;
      // font-size: 36px;
      font-size: 26px;
      // text-align: center;
      padding: 0 38px 0 82px;
      width: auto;
      height: 75px;
      margin: 0 auto 40px;
      @media print, screen and (min-width: 980px) {
        font-size: 36px;
      }
      // &:before {
      // background-size: 51px;
      // width: 51px;
      // left: 25px;
      // bottom: 0;
      // }
      &:after {
        border-width: 10px;
        margin-left: -10px;
        bottom: -20px;
      }
    }
    &#example1 .example__heading:before {
      background-size: 58px;
      width: 58px;
      height: 80px;
    }
    &#example2 .example__heading:before {
      background-size: 59px;
      width: 59px;
      height: 75px;
    }
    &#example3 .example__heading:before {
      background-size: 59px;
      width: 59px;
      height: 75px;
    }
    &#example4 .example__heading:before {
      background-size: 59px;
      width: 59px;
      height: 75px;
    }
    &#example5 .example__heading:before {
      background-size: 59px;
      width: 59px;
      height: 75px;
    }
    dl {
      align-items: center;
      margin-bottom: 30px;
    }
    dt {
      font-size: 15px;
      margin: 0 15px 0 0;
    }
    dd {
      font-size: 12px;
    }
    dl li {
      width: 86px;
      margin: 0 10px 0 0;
    }
    &__lead {
      line-height: 1.78;
      font-size: 18px;
      text-align: center;
      margin-bottom: 60px;
    }
    &__card {
      box-sizing: border-box;
      max-width: $base-width;
      padding: 60px 60px 40px;
      // padding: 60px;
      margin: 0 auto 30px;
    }
    &__card div:first-of-type {
      order: 1;
      width: 450px;
      // margin-bottom: 20px;
      margin-left: 60px;
    }
    // &__card img {
    // margin-bottom: 40px;
    // }
    // &__card div:first-of-type p {
    &__card .button {
      // margin-bottom: 0;
      margin: 40px 0 24px;
    }
    &__card .button a {
      width: 330px;
    }
    &__card div:first-of-type &__bubble {
      margin-top: 10px;
    }
    &__card div:last-of-type {
      width: 450px;
    }
    &__sub-title {
      line-height: 1.5;
      font-size: 18px;
      // margin-bottom: 15px;
    }
    &__title {
      line-height: 1.5;
      font-size: 30px;
      box-shadow: inset 0 -20px #ffec60;
    }
    &__text {
      line-height: 1.8;
      font-size: 15px;
      // margin: 15px 0 20px;
      // margin-top: 30px;
      margin-bottom: 20px;
    }
    &__title + &__text {
      margin-top: 30px;
    }
    &__bubble {
      font-size: 18px;
      width: 263px;
      margin-bottom: 21px;
    }
    &__list {
      line-height: 2;
      font-size: 15px;
      // margin-bottom: 20px;
    }
    &__list li {
      padding-left: 18px;
      &:after {
        top: 10px;
      }
    }
    &__service {
      font-size: 15px;
      // font-size: 12px;
      margin-bottom: 0;
      // @media print, screen and (min-width: 1040px) {
      //   font-size: 15px;
      // }
    }
    &__icon {
      width: calc(100% / 3);
      margin-bottom: 20px;
      &:last-child {
        width: calc(100% / 3 * 2);
      }
    }
    &__icon-title {
      // line-height: 1.5;
      font-size: 24px;
      margin-bottom: 30px;
      // &:before {
      // margin-right: 5px;
      // top: 8px;
      // }
      span {
        box-shadow: inset 0 -20px #ffec60;
      }
    }
    &__button {
      box-sizing: border-box;
      // padding: 20px 20px 5px;
      padding-top: 40px;
      padding-bottom: 26px;
      max-width: $base-width;
      margin: 0 auto;
      &-text {
        // line-height: 1.48;
        font-size: 27px;
        margin-bottom: 30px;
      }
      ul {
        justify-content: center;
      }
      li {
        // margin-bottom: 14px;
        &:first-child {
          margin-right: 30px;
        }
      }
      a {
        width: 360px;
      }
    }
    &__multi {
      box-sizing: border-box;
      padding: 60px 60px 40px;
      max-width: $base-width;
      margin: 0 auto 30px;
      .example__card {
        // padding: 0;
        &:not(:last-of-type) {
          padding-bottom: 40px;
          margin-bottom: 60px;
        }
      }
    }
    &__note {
      line-height: 2.22;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      padding-top: 30px;
      max-width: $base-width;
      margin: 0 auto -105px;
      p {
        width: 100%;
        top: 30px;
      }
      strong {
        font-weight: bold;
      }
    }
    &__sub-text {
      font-size: 21px;
      margin: 40px 0;
    }
    &__box {
      box-sizing: border-box;
      padding: 60px;
      max-width: $base-width;
      margin: 0 auto;
      div {
        padding: 0;
        width: calc(100% / 3);
        &:nth-of-type(2) {
          padding: 0 35px;
          border-top: 0;
          border-right: 1px dotted $base-color;
          border-bottom: 0;
          border-left: 1px dotted $base-color;
          margin: 0 35px;
        }
      }
      &-title {
        font-size: 21px;
        margin-bottom: 30px;
      }
      &-text {
        line-height: 1.8;
        font-size: 15px;
      }
    }
    .quote {
      margin: 60px 0 30px;
    }
  }

  .cta {
    box-sizing: border-box;
    // padding: 40px 30px 44px;
    padding: 40px 30px 14px;
    max-width: $base-width;
    // margin: 0 20px 30px;
    margin-bottom: 90px;
    @media print, screen and (min-width: 1120px) {
      margin-right: auto;
      margin-left: auto;
    }
    // &__lead {
    // line-height: 1.47;
    // margin-bottom: 10px;
    // }
    &__heading {
      // line-height: 1.48;
      font-size: 27px;
      margin-bottom: 20px;
    }
    &__text {
      // line-height: 1.79;
      font-size: 15px;
      text-align: center;
      margin-bottom: 30px;
    }
    &__title {
      // line-height: 1.56;
      font-size: 21px;
      letter-spacing: 0.06em;
      &:first-of-type {
        margin-bottom: 71px;
      }
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
    > div {
      margin-bottom: 30px;
    }
    &__box {
      padding: 70px 30px 5px;
      width: 320px;
      + div {
        margin: 0 0 0 30px;
      }
    }
    // &__point {
    //   margin-bottom: 15px;
    // }
    &__sub-text {
      line-height: 1.8;
      font-size: 15px;
    }
    &__button {
      justify-content: center;
      margin-bottom: 30px;
      li {
        margin-bottom: 0;
      }
      li:first-child {
        // margin: 0 30px 0 0;
        margin-right: 30px;
      }
      li:last-child {
        font-size: 18px;
        a {
          width: 362px;
          max-width: 362px;
        }
      }
    }
    &__image {
      justify-content: start;
      padding: 28px 10px 0 30px;
      // margin-bottom: 30px;
      li {
        width: auto;
        margin: 0 20px 30px 0;
      }
      img {
        max-width: 120px;
      }
    }
  }

  .content {
    // padding: 90px 0 95px;
    padding: 90px 0;
    &--news {
      padding-bottom: 95px;
    }
    &__heading {
      font-size: 30px;
      text-align: center;
      // margin: 0 0 27px 20px;
      margin-left: 0;
      @media print, screen and (min-width: 1305px) {
        margin-bottom: 42px;
      }
    }
    &__heading:not(:first-child) {
      margin-top: 75px;
    }
    &__heading:last-of-type {
      margin-bottom: 40px;
    }
    &__card {
      flex-wrap: wrap;
      // box-sizing: border-box;
      // padding: 0 20px;
      max-width: $base-width;
      margin: 0 auto;
      a {
        box-sizing: border-box;
        padding: 20px 15px 10px;
        // width: 525px;
        width: calc(50% - 15px);
        // margin-bottom: 15px;
        // transition: all .4s;
        transition: box-shadow 0.2s, transform 0.2s;
        &:hover {
          // background-color: #e3f3ff;
          box-shadow: none;
          transform: translateY(5px);
          .content__image {
            opacity: 0.8;
          }
        }
        &:nth-of-type(odd) {
          margin-right: 30px;
        }
      }
    }
    &__image {
      min-width: 100px;
      max-width: 100px;
      margin-right: 15px;
      transition: opacity 0.2s;
    }
    &__title {
      line-height: 1.46;
      font-size: 14px;
      height: 20px;
    }
    &__description {
      // line-height: 1.64;
      // font-size: 11px;
      // height: 36px;
      margin-bottom: 10px;
    }
  }

  .slider {
    max-width: $base-width;
    // margin: 0 auto;
    margin-right: auto;
    margin-left: auto;
    @media print, screen and (min-width: 1305px) {
      padding-left: 0;
      overflow: visible;
    }
    &__card {
      // width: 330px;
      // min-width: auto;
      min-width: 330px;
      // max-width: none;
      max-width: 330px;
      // margin-right: 45px;
      // margin-right: 0;
      transition: box-shadow 0.4s, transform 0.4s;
      &:hover {
        box-shadow: none;
        transform: translateY(5px);
        .slider__image {
          opacity: 0.8;
        }
      }
    }
    &__image {
      height: 195px;
      margin-bottom: 24px;
      transition: opacity 0.4s;
    }
    time {
      display: block;
      font-size: 12px;
      margin: 0 30px 15px;
    }
    &__title {
      line-height: 1.5;
      font-size: 18px;
      height: 54px;
      margin: 0 30px 15px;
      // overflow: hidden;
    }
    &__description {
      // height: 60px;
      margin: 0 30px 15px;
      // overflow: hidden;
    }
    &__category {
      // margin: 0 30px 43px;
      margin: 0 30px 38px;
    }
    // &__category {
    // padding: 0 15px 0 17px;
    // }
  }
}
