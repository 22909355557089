// module

// カード　デフォルト縦型
.card {
  display: block;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  transition: 0.2s;
  &:hover {
    transform: translateY(5px);
    box-shadow: none;
  }

  &__img {
    position: relative;
    overflow: hidden;
    transition: opacity 0.4s ease 0s;
    &:before {
      content: "";
      display: block;
      padding-top: 56.25%; // 画像比率16:9
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
  &__contain {
  }
  &__title {
    font-size: 16px;
    line-height: 1.6;
    overflow: hidden;
    margin: 0 20px 15px;
    letter-spacing: 0.04em;
    color: #333;
  }
  &__date {
    font-size: 12px;
    font-weight: bold;
    display: block;
    margin: 0 20px 15px;
    letter-spacing: 0.04em;
    color: #666;
  }
  &__type {
    font-size: 12px;
    font-weight: bold;
    line-height: 30px;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    min-width: 75px;
    padding: 0 15px 0 17px;
    text-align: center;
    color: #fff;
    border-radius: 5px 0 5px 0;
    background-color: #de3f7c;
    &:before {
      position: relative;
      display: inline-block;
      margin-right: 5px;
      content: "";
      background-repeat: no-repeat;
    }
  }

  // 記事タイプ
  &.is-article {
    .card__type:before {
      top: 1px;
      width: 12px;
      height: 12px;
      background-image: url(/img/module/ico-article.png);
      background-size: 12px;
    }
  }
  &.is-video {
    &:before {
      position: absolute;
      top: 58px;
      left: 116px;
      box-sizing: border-box;
      content: "";
      vertical-align: middle;
      border: 14px solid transparent;
      border-left: 24px solid #fff;
      z-index: 100;
    }

    .card__type:before {
      top: 2px;
      width: 14px;
      height: 14px;
      background-image: url(/img/module/ico-video.png);
      background-size: 14px;
    }
    .card__img {
      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 70px;
        height: 70px;
        margin: auto;
        content: " ";
        vertical-align: middle;
        border-radius: 50%;
        background: #999;
        right: 0;
        border: 3px solid #fff;
        opacity: 0.7;
      }
    }
  }

  // カード縦型
  &.is-v {
    .card__img {
      margin-bottom: 24px;
    }
  }

  // カード横型
  &.is-h {
    display: flex;
    .card__img {
      width: 45%;
      margin: 0 10px 0 0;
    }
  }

  // カード　画像全面
  &.is-main {
    .card__contain {
      position: absolute;
      z-index: 10;
      bottom: 0;
    }
  }

  // カード　番組表紙
  &.is-hyoushi {
    .card__img {
      &:before {
        padding-top: 100%; // 画像比率16:9
      }
      img {
        max-width: none;
      }
    }
    .card__contain {
      position: absolute;
      z-index: 10;
      bottom: 0;
    }
  }

  // カード　次回予告 comingsoon
  &.is-comingsoon {
    .card__img {
      &:before {
        position: relative;
        content: " ";
        background-color: #333;
        opacity: 0.3;
        z-index: 50;
      }
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "次回予告";
        z-index: 100;
        font-size: 26px;
        color: #fff;
      }
    }
    background: #999;
    .card__title {
      color: #fff;
    }
  }
}
