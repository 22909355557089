/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    // display: block;
    display: flex;
    // overflow: hidden;

    margin: 0;
    padding: 0;
    @media print, screen and (min-width: 1150px) {
        padding-bottom: 5px;
        margin-bottom: -5px;
        overflow: hidden;
    }
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    // display: block;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    // float: left;

    min-width: 270px;
    // max-width: 270px;
    height: 100%;
    min-height: 1px;
    margin-right: 20px;
    @media print, screen and (min-width: 768px) {
        min-width: 330px;
        // max-width: 330px;
        margin-right: 45px;
    }
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    // display: block;
    display: flex;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Arrows */
.slick-prev,
.slick-next
{
    position: absolute;
    background-color: #fff;
    font-size: 0;
    width: 45px;
    height: 45px;
    border: 3px solid $base-color;
    outline: none;
    top: -67px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 2px #0a5d9d;
    transition: all .2s;
    @media print, screen and (min-width: 768px) {
        border-width: 2px;
        box-shadow: 0 3px #0a5d9d;
    }
    @media print, screen and (min-width: 1305px) {
        top: 50%;
        transform: translateY(-50%);
    }
}
.slick-prev
{
    right: 74px;
    @media print, screen and (min-width: 1305px) {
        left: -90px;
    }
}
.slick-next
{
    right: 20px;
    @media print, screen and (min-width: 1305px) {
        right: -90px;
    }
}
.slick-prev:hover,
.slick-next:hover
{
    @media print, screen and (min-width: 768px) {
        background-color: #217dc5;
        border-color: #0a5d9d;
        box-shadow: none;
        transform: translateY(3px);
    }
    @media print, screen and (min-width: 1305px) {
        margin-top: 3px;
        // top: calc(50% + 3px);
        // transform: translateY(calc(-50% + 3px));
        transform: translateY(-50%);
    }
}
.slick-prev:after,
.slick-next:after {
    position: absolute;
    background: url(#{$img-dir}module/ico-slider.png) no-repeat;
    background-size: 39px;
    width: 19.5px;
    height: 23px;
    left: 50%;
    top: 50%;
    content: "";
}
.slick-prev:after {
    transform: translate(-50%, -50%);
}
.slick-next:after {
    transform-origin: left;
    transform: scaleX(-1) translate(-50%, -50%);
}
.slick-prev:hover:after,
.slick-next:hover:after {
    @media print, screen and (min-width: 768px) {
        background-position: -19.5px;
    }
}
// .slick-prev:hover,
// .slick-prev:focus,
// .slick-next:hover,
// .slick-next:focus
// {
    // outline: none;
// }
// .slick-prev.slick-disabled:before,
// .slick-next.slick-disabled:before
// {
//     opacity: .25;
// }


/* Dots */
// .slick-dotted.slick-slider
// {
//     // margin-bottom: 30px;
//     margin-bottom: 0;
// }

// .slick-dots
// {
//     position: absolute;
//     text-align: center;
//     width: 100%;
//     // bottom: -25px;
//     bottom: 20px;
// }
// .slick-dots li
// {
//     // position: relative;
//     display: inline-block;
//     background: #fff;
//     width: 10px;
//     height: 10px;
//     margin: 0 7.5px;
//     cursor: pointer;
//     border-radius: 50%;
//     transition: background-color .2s;
// }
// .slick-dots li.slick-active {
//     background-color: $accent-color;
// }
// .slick-dots li:hover
// {
//     background-color: $accent-color;
// }
// .slick-dots button
// {
//     display: none;
// }
