// module
//ワンライナー

@media print, screen and (min-width: 768px) {
  .is-pc {
    display: block;
  }
  .is-sp {
    display: none;
  }
}
@media print, screen and (max-width: 767px) {
  .is-pc {
    display: none;
  }
  .is-sp {
    display: block;
  }
}

.is-t-r {
  text-align: right;
}
