// about

.about-hero {
  padding: 30px 20px;
  border-top: 1px solid #ddd;
  &__heading {
    color: #333;
    font-size: 21px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 20px;
  }
  &__logo {
    width: 157px;
    margin: 0 auto 10px;
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: .04em;
    margin-bottom: 30px;
  }
  &__title {
    color: #333;
    font-size: 18px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 10px;
  }
  &__sub-logo {
    max-width: 405px;
    margin: 0 auto 30px;
  }
  &__sub-title {
    color: $base-color;
    font-size: 18px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 10px;
  }
  table {
    color: #333;
    border-spacing: 0;
    font-size: 15px;
    // text-align: center;
    letter-spacing: .04em;
    width: 100%;
    margin-bottom: 20px;
  }
  th,
  td {
    display: block;
    padding: 10px 20px;
    border: 2px solid $base-color;
    border-top: 0;
    // border-bottom: 2px solid $base-color;
  }
  th {
    color: #fff;
    background-color: $base-color;
    // font-weight: bold;
  }
  tr:nth-of-type(even) td {
    background-color: #e3f3ff;
  }
  tr:first-of-type th {
    border-radius: 10px 10px 0 0;
  }
  tr:last-of-type td:last-of-type {
    border-radius: 0 0 10px 10px;
  }
  div {
    position: relative;
    padding-top: 75%;
    // width: 100%;
  }
  iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    // left: 0;
    top: 0;
  }
}

.about-service {
  padding: 30px 20px 15px;
  &__heading {
    color: #333;
    font-size: 21px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 20px;
  }
  &__logo {
    width: 169.5px;
    margin: 0 auto 20px;
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: .04em;
    margin-bottom: 30px;
  }
  &__lead {
    color: $base-color;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    letter-spacing: .04em;
    margin-bottom: 10px;
  }
  &__title {
    color: #333;
    font-size: 18px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 20px;
  }
  > img {
    display: block;
    max-width: 297.5px;
    margin: 0 auto 10px;
  }
  &__quote {
    position: relative;
    color: #333;
    line-height: 2;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: .04em;
    padding: 16px 20px;
    margin-bottom: 30px;
    overflow: hidden;
    &:before,
    &:after {
      position: absolute;
      color: #f4b119;
      line-height: 1;
      font-size: 60px;
    }
    &:before {
      left: -39px;
      top: 0;
      content: "「";
    }
    &:after {
      right: -39px;
      bottom: 0;
      content: "」";
    }
  }
  &__sub-title {
    color: #333;
    font-size: 18px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 10px;
    &:last-of-type {
      margin-top: 30px;
    }
  }
  .example__card {
    box-shadow: none;
  }
  .button a:after {
    position: relative;
    display: inline-block;
    background: url(#{$img-dir}about/ico-external.png) no-repeat;
    background-size: 40px;
    width: 20px;
    height: 20px;
    margin-left: 6px;
    // top: 3px;
    top: 4px;
    content: "";
  }
}

@media print, screen and (min-width: 768px) {

.about-hero {
  // padding: 30px 20px;
  padding-top: 35px;
  padding-bottom: 90px;
  max-width: 810px;
  border-top: 0;
  @media print, screen and (min-width: 850px) {
    margin-right: auto;
    margin-left: auto;
  }
  &__heading {
    font-size: 36px;
  }
  &__logo {
    width: 314px;
    margin-bottom: 20px;
  }
  &__text {
    line-height: inherit;
    font-size: 15px;
    text-align: center;
    margin-bottom: 60px;
  }
  &__title {
    font-size: 30px;
    margin-bottom: 40px;
  }
  &__sub-logo {
    // max-width: 810px;
    max-width: none;
    margin-bottom: 53px;
  }
  &__sub-title {
    font-size: 24px;
    margin-bottom: 28px;
  }
  table {
    // table-layout: fixed;
    line-height: 1.8;
    font-size: 15px;
    // max-width: 810px;
    margin-bottom: 60px;
  }
  th,
  td {
    display: table-cell;
    padding: 8px 20px;
    // border-top: 2px solid $base-color;
    border-left: 0;
  }
  th {
    box-sizing: border-box;
    vertical-align: top;
    width: 203px;
    border-bottom-color: #fff;
  }
  tr:first-of-type th,
  tr:first-of-type td {
    border-top: 2px solid $base-color;
  }
  tr:first-of-type th {
    border-radius: 10px 0 0 0;
  }
  tr:first-of-type td:last-of-type {
    border-radius: 0 10px 0 0;
  }
  tr:last-of-type th {
    border-bottom-color: $base-color;
    border-radius: 0 0 0 10px;
  }
  tr:last-of-type td:last-of-type {
    border-radius: 0 0 10px 0;
  }
  div {
    padding-top: 0;
    // max-width: 810px;
    height: 480px;
  }
}

.about-service {
  // padding: 30px 20px 15px;
  padding-top: 90px;
  padding-bottom: 60px;
  &__heading {
    font-size: 30px;
    margin-bottom: 30px;
  }
  &__logo {
    width: 339px;
    margin-bottom: 30px;
  }
  &__text {
    line-height: 2;
    font-size: 15px;
    text-align: center;
    margin-bottom: 60px;
  }
  &__lead {
    font-size: 18px;
    margin-bottom: 20px;
  }
  &__title {
    font-size: 27px;
    margin-bottom: 40px;
  }
  > img {
    max-width: 595px;
    margin-bottom: 20px;
  }
  &__quote {
    box-sizing: border-box;
    line-height: 2.22;
    font-size: 18px;
    text-align: center;
    // padding: 35px 0;
    padding: 35px 20px;
    max-width: $base-width;
    margin: 0 auto 69px;
    &:before,
    &:after {
      font-size: 100px;
    }
    &:before {
      left: -65px;
    }
    &:after {
      right: -65px;
    }
  }
  &__sub-title {
    font-size: 30px;
    margin-bottom: 40px;
    &:last-of-type {
      margin-top: 90px;
    }
  }
  .button a:after {
    margin-left: 10px;
    top: 3px;
  }
  .button a:hover:after {
    background-position: -20px;
  }
}


}

