// module
// デフォルト
.nav {
  position: relative;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  //font-weight: bold;

  &__wrapper {
  }
  &__dropdown {
    box-sizing: border-box;
    padding: 82px 30px 22px;
    -webkit-overflow-scrolling: touch;
    @media print, screen and (min-width: 768px) {
      padding: 114px 20px 32px;
    }
    &--loaded {
      transition: transform 0.4s;
    }
    &--opened {
      z-index: 10;
    }
    @media print, screen and (min-width: 768px) {
      padding: 82px 30px 22px;
    }
  }
  &__guest {
    margin-bottom: 30px;
    li {
      margin-bottom: 15px;
    }
  }
  &__list {
    min-height: calc(100% - 103px);
    background-color: #fff;
    font-size: 13px;
    padding: 16px 30px 33px;
    margin-bottom: 25px;
    border-radius: 10px;
    @media print, screen and (min-width: 360px) {
      font-size: 14px;
    }
    li {
      position: relative;
      border-bottom: 1px solid $base-color;
    }
    a {
      display: block;
      padding: 14px 40px 14px 0;
    }
    span {
      position: absolute;
      font-size: 20px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &--content {
      line-height: 1.58;
      font-size: 12px;
      @media print, screen and (min-width: 360px) {
        font-size: 12px;
      }
      a {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  &__member {
    margin-bottom: 30px;
    li {
      margin-bottom: 15px;
    }
    li:last-child {
      line-height: 46px;
      a {
        background-color: #1e75c2;
        // border: 2px solid #fff;
        border: 2px solid #125c9d;
        box-shadow: 0 4px #125c9d;
      }
    }
  }
  // PCのみ
  @media print, screen and (min-width: 768px) {
    div div div {
      width: 320px;
      + div {
        margin-left: 30px;
      }
    }
  }
  &__text {
    color: #333;
    font-size: 12px;
    margin-bottom: 10px;
  }
  &__button {
    line-height: 30px;
    font-size: 11px;
    text-align: center;
    width: 90px;
    margin: 0 auto;
    a {
      display: block;
      background-color: #fff;
      box-shadow: 0 2px #0a5d9d;
      border-radius: 100px;
      &:after {
        position: relative;
        display: inline-block;
        background: url(#{$img-dir}common/ico-nav.png) no-repeat;
        background-size: 16px;
        width: 16px;
        height: 16px;
        margin-left: 5px;
        top: 4px;
        content: "";
      }
    }
  }
}

// グローバルナビ
.nav__dropdown--global {
  @media print, screen and (min-width: 768px) {
    padding: 0 !important;
    &.bg--light {
      background-color: #fff;
    }
    a {
      transition: color 0.4s;
      color: #000;
      &:hover {
        color: #1777c2;
      }
    }
  }
  @media print, screen and (max-width: 767px) {
    padding: 82px 30px 22px !important;
  }
}

.gnav__list {
  @media print, screen and (min-width: 768px) {
    justify-content: space-between;
    margin: 0;
  }
  @media print, screen and (max-width: 767px) {
    background-color: #fff;
    font-size: 13px;
    padding: 10px 20px 20px;
    margin-bottom: 25px;
    border-radius: 10px;
  }
  .gnav__item {
    @media print, screen and (min-width: 768px) {
      border-left: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      &:last-child {
        border-right: 1px solid #ccc;
      }
      &.is-has-subnav:hover {
        border-bottom: 1px solid #fff;
      }
    }
    @media print, screen and (max-width: 767px) {
      position: relative;
      border-bottom: 1px solid $base-color;
    }
    a {
      @media print, screen and (min-width: 768px) {
        display: block;
        padding: 10px 26.6px;
        font-size: 14px;
      }
      @media print, screen and (max-width: 767px) {
        display: block;
        padding: 14px 40px 14px 0;
      }
    }
    span {
      display: none;
      position: absolute;
      font-size: 20px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      @media print, screen and (max-width: 767px) {
        display: block;
      }
    }
  }
}

//サブナビ
.sub-nav {
  &__inner {
    display: flex;
  }
  &__item {
  }
}
//サブナビ表示
.nav__dropdown--global .gnav__list li:not(:hover) > .sub-nav {
  display: none;
}
@media print, screen and (max-width: 767px) {
  .sub-nav {
    display: none;
  }
}
.nav__dropdown--global .gnav__list li > .sub-nav {
  @media print, screen and (min-width: 768px) {
    position: absolute;
    top: 42px;
    left: 0;
    background-color: #fff;
    border-right: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    box-sizing: border-box;
    width: calc(100% - 1px);
    z-index: 10;
  }
}

//固有
#menu {
  @media print, screen and (max-width: 767px) {
    position: absolute;
    width: 100%;
    z-index: 80;
    transition: 0.4s;
    top: -2000px;
    &.nav__dropdown--opened {
      top: -52px;
    }
  }
}
#favorite,
#history {
  position: absolute;
  width: 100%;
  z-index: 80;
  transition: 0.4s;
  @media print, screen and (min-width: 768px) {
    top: -500px;
  }
  @media print, screen and (max-width: 767px) {
    top: -2000px;
  }
  &.nav__dropdown--opened {
    top: -52px;
  }
}
