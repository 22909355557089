@charset "UTF-8";
/*
  service
  説明：サービス・各トップで使われるスタイル
 */

 .section__inner{
 &.service-guide-wrap {
 	max-width: unset;
 	padding-right: 0px;
 	padding-left: 0px;
 }
	
 }
 
 .service-guide {
 	background-color: #217dc5;
 	padding:70px 0 60px 0;

	 &__title {
	 	font-size: 36px;
	 	margin-bottom: 16px;
	 	letter-spacing: .06em;
	 	color: #ffffff;
	 	position: relative;
	 	text-align: center;
	 	&:before {
	 		margin-right:27px;
	 		position: relative;
	 		top: 10px;
	 		height: 49.5px;
	 		display: inline-block;
	 		width:3px;
	 		content: '';
	 		background-color: #ffffff;
	 		-webkit-transform: rotate(-45deg);
	 		transform: rotate(-45deg);
	 	}
	 	&:after {
	 		margin-left:27px;
	 		position: relative;
	 		top: 10px;
	 		height: 49.5px;
	 		display: inline-block;
	 		width:3px;
	 		content: '';
	 		background-color: #ffffff;
	 		-webkit-transform: rotate(-45deg);
	 		transform: rotate(45deg);
	 	}

	 	span {
	 		position: relative;
	 	&:after {
	 		position:absolute;
	 		top: -2px;
	 		width: 8px;
	 		height: 8px;
	 		left: 50%;
	 		content: '';
	 		-webkit-transform: translateX(-50%);
	 		transform: translateX(-50%);
	 		border-radius: 50%;
	 		background-color: #f4b119;
	 	}
	 	}
	 }
	.titleimage {
		text-align: center;
	}
	.titleimage img {
		width:224px;
		height: auto;
	}

	&__lead {
		font-size: 18px;
		line-height: 2;
		color: #ffffff;
		box-sizing: border-box;
		max-width: 1080px;
		margin: 0 auto 30px;
		padding:0 20px;
		&__inner {
				display: block;
				position: relative;
				overflow: hidden;
				text-align: center;
				padding: 0 28px;
		&:before {
			left:-65px;
			font-size: 100px;
			content: '「';
			line-height: 1;
			position: absolute;
			color: #f4b119;
		}
		&:after {
			right: -65px;
			font-size: 100px;
			bottom: 0;
			content: '」';
			line-height: 1;
			position: absolute;
			color: #f4b119;
		}

		}
		}
 &__contents {
	box-sizing: border-box;
	padding:40px 20px;
	margin: 0 auto 30px;

	    @media print, screen and (min-width: 768px) {
	     max-width: 1080px;
	     padding: 0 20px;
	    }
	&__title {
		background-color: #e3f3ff;
		border-radius: 10px 10px 0 0;
		padding: 30px;
		p {
			color: #666666;
			font-size: 18px;
			line-height: 1.78;
			text-align: center;
		}
	}
	&__item {
		background-color: #ffffff;
		border-radius: 0 0 10px 10px;
		padding: 36px 50px;/*左右*/
	}
	&__list {
		width:100%;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		border-bottom: 1px dashed #1777C2;
		&:last-child {
			border-bottom:none;

		}
		dt {
			width:192px;
			padding-bottom: 20px;
		}
		dd {
			width:calc(100%-192px);
			width:-webkit-calc(100%-192px);
			padding: 0 12px 20px 30px;
			h5 {
				font-size: 21px;
				font-weight: bold;
				color: #1777c2;
				line-height: 1.48;
				margin-bottom: 15px;
				letter-spacing: .06em;
			}
			p.service-guide__contents__explain {
				font-size: 15px;
				line-height: 1.8;
				margin-bottom: 20px;
				letter-spacing: .04em;
			}
			p.button a {
				display: block;
				margin: 0 0 0 auto;
			}
		}
	}

	}/*contents*/

}/*service-guide*/

/*汎用クラス*/

.font-small-80 {
	font-size: 80% !important;
}
