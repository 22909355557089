// column

.column-hero {
  text-align: center;
  padding: 30px 20px 15px;
  border-top: 1px solid #ddd;
  &__lead {
    color: $base-color;
    line-height: 1.75;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: .04em;
    margin-bottom: 10px;
  }
  &__title {
    color: #333;
    line-height: 1.52;
    font-size: 21px;
    letter-spacing: .06em;
    margin-bottom: 20px;
  }
  &__text {
    line-height: 1.6;
    font-size: 15px;
    letter-spacing: .04em;
  }
}

.column-single-hero {
  padding: 21px 20px 0;
  border-top: 1px solid #ddd;
  &__state {
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 13px;
    div {
      flex-wrap: wrap;
      // justify-content: center;
      align-items: center;
      // margin: 0 -20px;
    }
  }
  &__type {
    box-sizing: border-box;
    color: #fff;
    background-color: #de3f7c;
    line-height: 20px;
    text-align: center;
    padding: 0 13px 0 9px;
    min-width: 55px;
    // height: 20px;
    margin: 0 10px 5px 0;
    border-radius: 10px;
    &:before {
      position: relative;
      display: inline-block;
      background-repeat: no-repeat;
      margin-right: 3px;
      content: "";
    }
    &--article:before {
      background-image: url(#{$img-dir}module/ico-article.png);
      background-size: 10px;
      width: 10px;
      height: 10px;
      top: 1px;
    }
    &--video:before {
      background-image: url(#{$img-dir}module/ico-video.png);
      background-size: 12px;
      width: 12px;
      height: 12px;
      top: 2px;
    }
    &--product:before {
      background-image: url(#{$img-dir}module/ico-product.png);
      background-size: 12px;
      width: 12px;
      height: 10px;
      top: 1px;
    }
  }
  &__list {
    // flex-wrap: wrap;
    // justify-content: center;
    // line-height: 20px;
    letter-spacing: .04em;
    margin-bottom: 5px;
    // dt,
    // dd {
    //   display: table-cell;
    // }
    dt {
      // word-break: break-all;
      word-break: keep-all;
    }
    dt:after {
      content: "：";
    }
    dd {
      // flex: 1;
      width: 100%;
    }
    dd + dt {
      margin-left: 1em;
    }
    ul {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
    li:not(:last-child):after {
      content: "、";
    }
  }
  &__title {
    color: #333;
    font-size: 18px;
    // text-align: center;
    letter-spacing: .06em;
    margin-bottom: 20px;
  }
}

.entry {
  // padding: 0 20px 20px;
  // padding: 0 20px 6px;
  padding: 0 20px 10px;
  &__video {
    margin-bottom: 20px;
  }
  video {
    width: 100%;
    border-radius: 10px;
  }
  p.entry__video-text {
    color: #333;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 11px;
  }
  h2 {
    color: #333;
    background-color: #e4f3ff;
    // padding: 15px 10px;
    padding: 10px;
    border-left: 4px solid $base-color;
  }
  h3 {
    color: #333;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
  h4 {
    color: #333;
    padding-left: 10px;
    border-left: 4px solid $base-color;
  }
  h5 {
    color: #333;
    font-size: 16px;
  }
  * + h2,
  * + h3,
  * + h4,
  * + h5 {
    margin-top: 40px;
  }
  p {
    line-height: 1.79;
    font-size: 14px;
    margin-bottom: 20px;
  }
  p.entry__small {
    font-size: 12px;
  }
  strong {
    color: #333;
  }
  a {
    text-decoration: underline;
    padding: 10px 0;
  }
  a.entry__button {
    text-decoration: none;
    span {
      position: relative;
      font-size: 20px;
      margin-left: 5px;
      top: 3px;
    }
  }
  a.entry__external {
    text-decoration: none;
    &:after {
      position: relative;
      display: inline-block;
      background: url(#{$img-dir}column/ico-external.png) no-repeat;
      background-size: 20px;
      width: 20px;
      height: 20px;
      margin-left: 5px;
      top: 3px;
      content: "";
    }
  }
  &__yellow {
    box-shadow: inset 0 -10px #ffec60;
  }
  &__yellow-small {
    box-shadow: inset 0 -2px #ffec60;
  }
  &__blue {
    box-shadow: inset 0 -10px #d0e9ff;
  }
  &__blue-small {
    box-shadow: inset 0 -2px #d0e9ff;
  }
  &__green {
    box-shadow: inset 0 -10px #dbffcb;
  }
  &__green-small {
    box-shadow: inset 0 -2px #dbffcb;
  }
  &__pink {
    box-shadow: inset 0 -10px #fadfeb;
  }
  &__pink-small {
    box-shadow: inset 0 -2px #fadfeb;
  }
  ul {
    line-height: 1.79;
    font-size: 14px;
    margin-bottom: 20px;
    li {
      position: relative;
      padding-left: 1em;
      &:after {
        position: absolute;
        background-color: $base-color;
        width: 6px;
        height: 6px;
        left: 0;
        // top: 10px;
        top: 9px;
        content: "";
        border-radius: 50%;
      }
    }
    ul {
      margin-bottom: 0;
    }
  }
  ol {
    line-height: 1.79;
    font-size: 14px;
    margin-bottom: 20px;
    li {
      position: relative;
      padding-left: 1em;
      counter-increment: column-entry;
      &:after {
        position: absolute;
        color: $base-color;
        font-weight: bold;
        left: 0;
        top: 0;
        content: counter(column-entry) ".";
      }
    }
  }
  dl {
    margin-bottom: 20px;
  }
  dt {
    color: #333;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 11px;
  }
  dd {
    line-height: 1.79;
    font-size: 14px;
  }
  &__box {
    background-color: #e3f3ff;
    // line-height: 1.8;
    // font-size: 15px;
    padding: 20px;
    // margin-bottom: 20px;
    border-radius: 10px;
  }
  &__border {
    // line-height: 1.8;
    // font-size: 15px;
    padding: 20px;
    border: 1px solid #ddd;
    // margin-bottom: 20px;
    border-radius: 10px;
  }
  &__border-blue {
    // line-height: 1.8;
    // font-size: 15px;
    padding: 20px;
    border: 1px solid $base-color;
    // margin-bottom: 20px;
    border-radius: 10px;
  }
  &__card {
    background-color: #e4f3ff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    p {
      color: #333;
      line-height: 1;
      font-weight: bold;
      font-size: 18px;
      padding-left: 10px;
      border-left: 4px solid $base-color;
    }
    ol {
      margin-bottom: 0;
    }
    ol li {
      padding-left: 0;
      &:after {
        display: none;
      }
    }
    a {
      text-decoration: none;
    }
    > ol > li > a {
      display: block;
      font-weight: bold;
      font-size: 16px;
      padding-bottom: 10px;
      border-bottom: 1px solid #bfe2ff;
      margin: 20px 0 10px;
    }
    // ol ol {
    //   margin-bottom: 0;
    // }
  }
  img {
    display: block;
    margin: 0 auto 20px;
    border-radius: 10px;
  }
  // &__image-right {
    // div {
      // margin-bottom: 20px;
    // }
  // }
  // &__image-left {
  //   div {
  //     margin-bottom: 20px;
  //   }
  // }
  &__figure {
    text-align: center;
    img {
      margin-bottom: 10px;
    }
    p {
      font-size: 12px;
    }
  }
  &__image-column {
    margin-bottom: 20px;
    div {
      width: 50%;
      &:first-of-type {
        margin-right: 20px;
      }
    }
  }
  img.entry__image-square {
    border-radius: 0;
  }
  table {
    // border-collapse: separate;
    border-spacing: 0;
    font-size: 15px;
    text-align: center;
    // border: 2px solid $base-color;
    // border-bottom: 0;
    margin-bottom: 20px;
    // overflow: hidden;
    // border-radius: 10px;
  }
  td {
    display: block;
    padding: 10px 20px;
    border: 2px solid $base-color;
    border-top: 0;
    // border-bottom: 2px solid $base-color;
    &:nth-of-type(odd) {
      background-color: #e3f3ff;
    }
    &:first-of-type {
      color: #fff;
      background-color: $base-color;
      font-weight: bold;
      letter-spacing: .04em;
    }
  }
  tr:first-of-type td:first-of-type {
    border-radius: 10px 10px 0 0;
  }
  tr:last-of-type td:last-of-type {
    border-radius: 0 0 10px 10px;
  }
}

.column-button {
  padding: 30px 20px;
  margin: -10px 20px 30px;
  border-radius: 10px;
  &__text {
    color: #333;
    // font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 15px;
  }
  p:last-of-type {
    width: 100%;
    max-width: 255px;
    margin: 0 auto;
  }
}

.comment-box {
  padding: 0 20px 16px;
  margin-top: 31px;
  &__state {
    // align-items: center;
    align-items: flex-end;
    margin-bottom: 14px;
    dt {
      color: #333;
      font-weight: bold;
      font-size: 14px;
      margin-right: 15px;
    }
    dd {
      font-size: 15px;
    }
  }
  // .comment-respond {
    // padding-bottom: 20px;
    // border-bottom: 1px solid #ccc;
    // margin-bottom: 20px;
  // }
  .comment-reply-title {
    font-size: 14px;
    margin-bottom: 5px;
    small {
      font-size: 100%;
    }
    a {
      // display: inline !important;
      padding: 5px 0;
    }
  }
  form {
    position: relative;
  }
  textarea {
    box-sizing: border-box;
    // font-size: 14px;
    // font-size: 16px;
    padding: 10px 110px 10px 20px;
    width: 100%;
    height: 45px;
    border: 1px solid #ccc;
    border-radius: 10px;
    transition: background-color .2s, border-color .2s;
    &::placeholder {
      color: #999;
    }
    &:focus {
      background-color: #e3f3ff;
      border-color: $base-color;
    }
  }
  .submit {
    position: absolute;
    color: #fff;
    background-color: $base-color;
    font-weight: bold;
    font-size: 15px;
    width: 90px;
    height: 45px;
    right: 0;
    top: 0;
    border-radius: 0 10px 10px 0;
  }
  // > p {
  //   margin-top: 30px;
  // }
}

.comment {
  &.depth-1 {
    padding: 20px 0;
    border-top: 1px solid #ccc;
    // &:first-of-type {
    //   border-top: 1px solid #ccc;
    // }
    &:last-of-type {
      border-bottom: 1px solid #ccc;
      margin-bottom: 30px;
    }
  }
  .comment {
    // padding: 10px 0 0;
    // padding-top: 10px;
    padding: 10px 0 0 40px;
    // border-bottom: 0;
    + .comment-respond {
      padding-top: 20px;
    }
  }
  // .depth-2 {
  //   padding-left: 40px;
  // }
  // .depth-3 {
  //   padding-left: 40px;
  // }
  &__image {
    position: relative;
    display: table-cell;
    // min-width: 30px;
    width: 30px;
    // margin-right: 10px;
    padding-right: 10px;
    top: 23px;
  }
  img {
    width: 100%;
    height: auto;
  }
  &__box {
    display: table-cell;
    vertical-align: top;
  }
  &__author {
    color: #333;
    font-size: 12px;
    margin-bottom: 5px;
  }
  cite {
    font-style: normal;
  }
  &__text {
    color: #333;
    line-height: 1.71;
    font-size: 14px;
    padding: 15px 15px 1px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
    border-radius: 10px;
  }
  &__action {
    align-items: center;
  }
  &__reply {
    font-size: 12px;
    margin: 0 15px 0 0;
    a {
      text-decoration: underline;
      padding: 5px 0;
    }
  }
  time {
    font-size: 10px;
  }
}

.column-pickup {
  // padding: 9px 0 30px;
  // padding-bottom: 30px;
  padding-bottom: 20px;
  &__title {
    color: #333;
    // font-size: 18px;
    font-size: 14px;
    letter-spacing: .06em;
    margin: 0 0 31px 20px;
    @media print, screen and (min-width: 360px) {
      font-size: 18px;
    }
  }
  .slider {
    padding-bottom: 10px;
  }
  .slider__card {
    position: relative;
    box-shadow: 0 0 10px rgba(0,0,0,.16);
    &--member {
      &:after {
        position: absolute;
        background: url(#{$img-dir}column/ico-member.png) no-repeat;
        background-size: 30px;
        width: 30px;
        height: 30px;
        right: 20px;
        top: 178px;
        content: "";
      }
    }
  }
}

.column-content {
  padding: 30px 20px 20px;
  &__title {
    color: #333;
    font-size: 21px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 20px;
  }
}

.column-large-card {
  // margin-bottom: 35px;
  margin-bottom: 30px;
  > div  {
    position: relative;
    background-color: #fff;
    padding-bottom: 10px;
    // margin-bottom: 20px;
    margin-bottom: 15px;
    // border-radius: 10px;
    // border-radius: 100px 100px 10px 10px;
    border-radius: 10px;
    overflow: hidden;
    // box-shadow: 0 5px #d0eafd;
  }
  &__category {
    position: relative;
    display: block;
    background-color: #fff;
    padding: 17px 10px 10px;
    margin: 0 10px -14px;
    bottom: 14px;
    border-radius: 5px;
  }
  &__heading {
    color: #2f85c8;
    // font-size: 15px;
    font-size: 13px;
    letter-spacing: .06em;
    margin-bottom: 10px;
    @media print, screen and (min-width: 360px) {
      font-size: 15px;
    }
  }
  &__lead {
    color: #666;
    line-height: 1.55;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: .04em;
    margin-bottom: 0;
  }
  span {
    position: absolute;
    font-size: 20px;
    right: 10px;
    top: 20px;
  }
  &__entry {
    padding: 10px;
    margin: 0 10px;
    border-radius: 5px;
  }
  &__image {
    position: relative;
    // min-width: 120px;
    min-width: calc(120 / 375 * 100vw);
    // max-width: 120px;
    max-width: calc(120 / 375 * 100vw);
    margin-right: 10px;
    // border-radius: 10px;
    // overflow: hidden;
    img {
      border-radius: 10px;
    }
  }
  &__type {
    position: absolute;
    box-sizing: border-box;
    color: #fff;
    background-color: #de3f7c;
    line-height: 20px;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    padding: 0 13px 0 9px;
    min-width: 55px;
    top: 0;
    border-radius: 10px 0 10px 0;
    &:before {
      position: relative;
      display: inline-block;
      background-repeat: no-repeat;
      margin-right: 3px;
      content: "";
    }
    &--article:before {
      background-image: url(#{$img-dir}module/ico-article.png);
      background-size: 10px;
      width: 10px;
      height: 10px;
      top: 1px;
    }
    &--video:before {
      background-image: url(#{$img-dir}module/ico-video.png);
      background-size: 12px;
      width: 12px;
      height: 12px;
      top: 2px;
    }
    &--product:before {
      background-image: url(#{$img-dir}module/ico-product.png);
      background-size: 12px;
      width: 12px;
      height: 10px;
      top: 1px;
    }
  }
  time {
    display: block;
    color: #666;
    font-size: 10px;
    margin-bottom: 5px;
  }
  &__title {
    color: #333;
    font-size: 12px;
    letter-spacing: .06em;
    height: 36px;
    margin-bottom: 5px;
    overflow: hidden;
  }
  &__description {
    color: #666;
    line-height: 1.64;
    font-size: 11px;
    letter-spacing: .04em;
    height: 54px;
    // margin-bottom: 5px;
    margin-bottom: 0;
    overflow: hidden;
  }
  &__count {
    position: absolute;
    line-height: 1;
    font-weight: bold;
    font-size: 10px;
    left: 10px;
    top: 10px;
    a {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #e3f3ff;
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
  }
  strong {
    // line-height: 1;
    font-size: 18px;
    margin-bottom: 2px;
  }
}

.column-card {
  a {
    // display: block;
    background-color: #fff;
    padding: 20px 20px 10px;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0 5px #d0eafd;
  }
  &__image {
    position: relative;
    // min-width: 120px;
    min-width: calc(120 / 375 * 100vw);
    // max-width: 120px;
    max-width: calc(120 / 375 * 100vw);
    margin-right: 10px;
    // border-radius: 10px;
    // overflow: hidden;
    img {
      border-radius: 10px;
    }
  }
  &__type {
    position: absolute;
    box-sizing: border-box;
    color: #fff;
    background-color: #de3f7c;
    line-height: 20px;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    padding: 0 13px 0 9px;
    min-width: 55px;
    top: 0;
    border-radius: 10px 0 10px 0;
    &:before {
      position: relative;
      display: inline-block;
      background-repeat: no-repeat;
      margin-right: 3px;
      content: "";
    }
    &--article:before {
      background-image: url(#{$img-dir}module/ico-article.png);
      background-size: 10px;
      width: 10px;
      height: 10px;
      top: 1px;
    }
    &--video:before {
      background-image: url(#{$img-dir}module/ico-video.png);
      background-size: 12px;
      width: 12px;
      height: 12px;
      top: 2px;
    }
    &--product:before {
      background-image: url(#{$img-dir}module/ico-product.png);
      background-size: 12px;
      width: 12px;
      height: 10px;
      top: 1px;
    }
  }
  time {
    display: block;
    color: #666;
    font-size: 10px;
    margin-bottom: 5px;
  }
  &__title {
    color: #333;
    font-size: 12px;
    letter-spacing: .06em;
    height: 36px;
    margin-bottom: 5px;
    overflow: hidden;
  }
  &__description {
    color: #666;
    line-height: 1.64;
    font-size: 11px;
    letter-spacing: .04em;
    height: 54px;
    margin-bottom: 5px;
    overflow: hidden;
  }
  &__category {
    flex-wrap: wrap;
    color: $base-color;
    font-weight: bold;
    font-size: 10px;
    li:not(:last-child):after {
      content: "、";
    }
  }
}

.card-content {
  padding: 52px 20px 33px;
  margin: 10px 0 -8px;
}

.column-small-card {
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: bold;
  text-align: center;
  a {
    position: relative;
    background-color: #fff;
    width: calc(50% - 10px);
    margin-bottom: 41px;
    // overflow: hidden;
    // border-radius: 10px;
    // border-radius: 100px 100px 10px 10px;
    border-radius: 10px;
    box-shadow: 0 3px #d0eafd;
    // &:nth-last-of-type(2),
    // &:last-of-type {
    //   margin-bottom: 0;
    // }
  }
  img {
    width: 100%;
    border-radius: 10px 10px 0 0;
  }
  &__title {
    display: inline-flex;
    align-items: center;
    color: #333;
    // line-height: 38px;
    font-size: 12px;
    height: 38px;
    margin-bottom: 0;
  }
  &__count {
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    // line-height: 12px;
    line-height: 16px;
    font-size: 10px;
    width: 45px;
    height: 45px;
    left: 50%;
    top: -22px;
    border-radius: 50%;
    transform: translateX(-50%);
  }
  strong {
    display: block;
    font-size: 18px;
  }
}

.column-single-content {
  padding: 30px 20px 33px;
  &__title {
    color: #333;
    font-size: 21px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 20px;
  }
  .column-card {
    margin-bottom: 56px;
  }
}

.pager {
  position: relative;
  color: #333;
  line-height: 45px;
  font-size: 15px;
  letter-spacing: .06em;
  // padding: 30px 20px 33px;
  padding: 30px 20px 18px;
  &__prev,
  &__next {
    position: absolute;
    // font-size: 0;
    width: 45px;
    // top: 50%;
    top: 30px;
    // transform: translateY(-50%);
    a {
      display: block;
      color: transparent;
      background-color: #e3f3ff;
      border-radius: 10px;
      box-shadow: 0 3px #d0eafd;
      &:after {
        position: absolute;
        background: url(#{$img-dir}column/ico-pager.png) no-repeat;
        background-size: 28px;
        width: 14px;
        height: 15px;
        left: 50%;
        top: 50%;
        content: "";
      }
    }
  }
  &__prev {
    // left: 0;
    a:after {
      transform: translate(-50%, -50%);
    }
  }
  &__next {
    right: 20px;
    a:after {
      transform-origin: left;
      transform: scaleX(-1) translate(-50%, -50%);
    }
  }
  ol {
    justify-content: center;
    a {
      display: none;
    }
  }
  &__current {
    &:after {
      content: "/";
    }
  }
}

@media print, screen and (min-width: 768px) {

.column-hero {
  // padding: 30px 20px 15px;
  padding-top: 35px;
  padding-bottom: 55px;
  border-top: 0;
  &__lead {
    line-height: 1.47;
    font-size: 15px;
    margin-bottom: 15px;
  }
  &__title {
    // line-height: 1.52;
    font-size: 36px;
    // margin-bottom: 20px;
  }
  &__text {
    line-height: 2;
    max-width: 660px;
    margin-right: auto;
    margin-left: auto;
  }
}

.column-single-hero {
  // padding: 21px 20px 0;
  padding-top: 31px;
  padding-bottom: 30px;
  max-width: 810px;
  border-top: 0;
  margin: 0 auto;
  &__state {
    flex-wrap: wrap;
    // justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-bottom: 18px;
    // div {
    //   margin: 0;
    // }
  }
  &__type {
    line-height: 30px;
    padding: 0 15px 0 17px;
    min-width: 75px;
    // margin: 0 10px 5px 0;
    margin-right: 20px;
    &:before {
      // margin-right: 7px;
      margin-right: 5px;
    }
    &--article:before {
      background-size: 12px;
      width: 12px;
      height: 12px;
    }
    &--video:before {
      background-size: 14px;
      width: 14px;
      height: 14px;
    }
    &--product:before {
      background-size: 14px;
      width: 14px;
      height: 12px;
    }
  }
  &__list {
    // margin-bottom: 5px;
    margin-right: 20px;
    dd {
      max-width: 727px;
      // max-width: 810px;
    }
  }
  &__title {
    font-size: 30px;
    margin-bottom: 30px;
  }
}

.entry {
  // padding: 0 20px 6px;
  padding-bottom: 60px;
  max-width: 810px;
  margin: 0 auto;
  &__video {
    margin-bottom: 46px;
  }
  // video {
    // width: 100%;
  // }
  p.entry__video-text {
    font-size: 18px;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 24px;
    padding: 15px 10px;
  }
  h3 {
    font-size: 21px;
  }
  h4 {
    font-size: 18px;
  }
  // h5 {
  //   font-size: 16px;
  // }
  * + h2,
  * + h3,
  * + h4,
  * + h5 {
    margin-top: 60px;
  }
  p {
    line-height: 1.875;
    font-size: 16px;
    margin-bottom: 30px;
  }
  p.entry__small {
    line-height: 1.83;
    font-size: 12px;
  }
  a {
    // padding: 10px 0;
    &:hover {
      text-decoration: none;
    }
  }
  a.entry__button {
    transition: opacity .2s;
    &:hover {
      opacity: .8;
    }
    span {
      margin-left: 8px;
      // top: 2px;
    }
  }
  a.entry__external {
    transition: opacity .2s;
    &:after {
      margin-left: 8px;
      // top: 2px;
    }
    &:hover {
      opacity: .8;
    }
  }
  ul {
    line-height: 1.8;
    font-size: 15px;
    margin-bottom: 30px;
    li {
      &:after {
        top: 10px;
      }
    }
  }
  ol {
    line-height: 1.8;
    font-size: 15px;
    margin-bottom: 30px;
  }
  dl {
    margin-bottom: 30px;
  }
  dt {
    // font-size: 18px;
    font-size: 16px;
    margin-bottom: 15px;
  }
  dd {
    line-height: 1.875;
    font-size: 16px;
    // letter-spacing: .04em;
    // letter-spacing: 0;
  }
  &__box {
    padding: 30px;
    // margin-bottom: 30px;
  }
  &__border {
    padding: 30px;
    // margin-bottom: 30px;
  }
  &__border-blue {
    padding: 30px;
    // margin-bottom: 30px;
  }
  &__card {
    // padding: 20px;
    margin-bottom: 30px;
    p {
      line-height: 1;
      font-size: 21px;
    }
    ol {
      margin-bottom: 0;
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
    > ol > li > a {
      font-size: 18px;
      margin: 20px 0;
    }
  }
  img {
    margin-bottom: 30px;
  }
  &__image-right {
    margin-bottom: 30px;
    div {
      order: 1;
      min-width: 385px;
      margin-left: 30px;
    }
    img {
      margin-bottom: 0;
    }
  }
  &__image-left {
    margin-bottom: 30px;
    div {
      min-width: 385px;
      margin-right: 30px;
    }
    img {
      margin-bottom: 0;
    }
  }
  &__figure {
    img {
      margin-bottom: 20px;
    }
    p {
      font-size: 12px;
    }
  }
  &__image-column {
    margin-bottom: 30px;
    div {
      // width: 50%;
      &:first-of-type {
        margin-right: 40px;
      }
    }
    img {
      margin-bottom: 0;
    }
  }
  table {
    table-layout: fixed;
    text-align: left;
    width: 100%;
    margin-bottom: 30px;
  }
  td {
    display: table-cell;
    padding: 12px 20px;
    // border-top: 2px solid $base-color;
    border-left: 0;
    &:first-of-type {
      border-bottom-color: #fff;
    }
  }
  tr:first-of-type td {
    border-top: 2px solid $base-color;
  }
  tr:first-of-type td:first-of-type {
    border-radius: 10px 0 0 0;
  }
  tr:first-of-type td:last-of-type {
    border-radius: 0 10px 0 0;
  }
  tr:last-of-type td:first-of-type {
    border-bottom-color: $base-color;
    border-radius: 0 0 0 10px;
  }
  tr:last-of-type td:last-of-type {
    border-radius: 0 0 10px 0;
  }
}

.column-button {
  box-sizing: border-box;
  // padding: 30px 20px;
  padding: 40px 0;
  max-width: 810px;
  // margin: 0 20px 30px;
  margin-top: -30px;
  margin-bottom: 60px;
  @media print, screen and (min-width: 850px) {
    margin-right: auto;
    margin-left: auto;
  }
  ul {
    justify-content: center;
    margin-bottom: 12px;
  }
  li {
    margin-bottom: 0;
    &:first-child {
      margin-right: 30px;
    }
  }
  p:last-of-type {
    max-width: 241px;
  }
}

.comment-box {
  box-sizing: border-box;
  // padding: 0 20px 16px;
  padding-bottom: 94px;
  max-width: 810px;
  margin: 75px auto 0;
  // @media print, screen and (min-width: 850px) {
  //   margin-right: auto;
  //   margin-left: auto;
  // }
  &__state {
    // align-items: flex-end;
    margin-bottom: 20px;
    dt {
      font-size: 24px;
    }
    dd {
      margin-bottom: 2px;
    }
  }
  .comment-respond {
    // padding-bottom: 30px;
    padding-bottom: 10px;
  }
  > p a {
    width: 330px;
  }
}

.comment {
  &.depth-1 {
    padding: 30px 0;
    &:last-of-type {
      margin-bottom: 60px;
    }
  }
  .comment {
    padding: 20px 0 0 60px;
    + .comment-respond {
      padding-top: 30px;
    }
  }
  &__image {
    width: 45px;
    // top: 23px;
  }
  &__reply {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.column-pickup {
  // padding-bottom: 70px;
  padding-bottom: 60px;
  &__title {
    font-size: 30px;
    text-align: center;
    margin: 0 0 40px 0;
  }
  // .slider {
    // padding-left: 10px;
    // max-width: 1100px;
  // }
  .slick-list {
    overflow: visible;
  }
  .slider__card {
    &:hover {
      box-shadow: 0 0 6px rgba(0,0,0,.16);
    }
    &--member {
      &:after {
        right: 30px;
        top: 212px;
      }
    }
  }
}

.column-content {
  // padding: 30px 20px 20px;
  padding-top: 70px;
  padding-bottom: 50px;
  &__title {
    font-size: 30px;
    margin-bottom: 40px;
  }
}

.column-large-card {
  flex-wrap: wrap;
  align-items: baseline;
  max-width: $base-width;
  // margin: 0 auto 30px;
  margin: 0 auto 25px;
  > div {
    // padding-bottom: 10px;
    // width: 525px;
    width: calc(50% - 15px);
    // margin-bottom: 35px;
    margin-bottom: 30px;
    // top: 0;
    // transition: top .4s, box-shadow .4s;
    // &:hover {
      // top: 5px;
      // box-shadow: none;
      // img {
      //   opacity: .8;
      // }
    // }
    &:nth-of-type(odd) {
      margin-right: 30px;
    }
  }
  // img {
  //   transition: opacity .4s;
  // }
  &__category {
    padding: 17px 15px 18px;
    margin: 0 15px -38px;
    bottom: 38px;
    transition: background-color .4s;
    &:after {
      position: absolute;
      background: url(#{$img-dir}column/ico-card.png) no-repeat;
      background-size: 20px;
      width: 20px;
      height: 20px;
      right: 15px;
      top: 28px;
      content: "";
      opacity: 0;
      transition: opacity .4s;
    }
    &:hover {
      background-color: #e3f3ff;
      &:after {
        opacity: 1;
      }
      span {
        display: none;
      }
    }
  }
  &__heading {
    font-size: 18px;
    margin-bottom: 5px;
  }
  // &__lead {
    // line-height: 1.55;
  // }
  span {
    right: 15px;
    top: 28px;
  }
  &__entry {
    padding: 11px 15px;
    margin: 0 15px;
    transition: background-color .4s;
    &:hover {
      background-color: #e3f3ff;
      img {
        opacity: .8;
      }
    }
  }
  &__image {
    min-width: 165px;
    max-width: 165px;
    margin-right: 19px;
    img {
      transition: opacity .4s;
    }
  }
  &__type {
    line-height: 30px;
    font-size: 12px;
    padding: 0 15px 0 17px;
    min-width: 75px;
    &:before {
      // margin-right: 7px;
      margin-right: 5px;
    }
    &--article:before {
      background-size: 12px;
      width: 12px;
      height: 12px;
    }
    &--video:before {
      background-size: 14px;
      width: 14px;
      height: 14px;
    }
    &--product:before {
      background-size: 14px;
      width: 14px;
      height: 12px;
    }
  }
  time {
    font-size: 11px;
    margin-bottom: 7px;
  }
  &__title {
    line-height: 1.47;
    font-size: 15px;
    // letter-spacing: 0;
    height: 44px;
  }
  &__description {
    line-height: 1.73;
    // letter-spacing: 0;
    height: 57px;
  }
  &__count {
    left: 15px;
    a {
      transition: color .2s, background-color .2s;
      &:hover {
        color: #fff;
        background-color: $base-color;
      }
    }
  }
}

.column-card {
  flex-wrap: wrap;
  max-width: $base-width;
  margin: 0 auto;
  a {
    position: relative;
    box-sizing: border-box;
    padding: 34px 30px 19px;
    // width: 525px;
    width: calc(50% - 15px);
    margin-bottom: 25px;
    box-shadow: 0 5px #d0eafd;
    top: 0;
    transition: top .4s, box-shadow .4s;
    &:hover {
      top: 5px;
      box-shadow: none;
      img {
        opacity: .8;
      }
    }
    &:nth-of-type(odd) {
      margin-right: 30px;
    }
  }
  &__image {
    min-width: 165px;
    max-width: 165px;
    margin-right: 19px;
  }
  img {
    transition: opacity .4s;
  }
  div:last-of-type {
    flex: 1;
    min-width: 0;
    max-width: 281px;
  }
  &__type {
    line-height: 30px;
    font-size: 12px;
    padding: 0 15px 0 17px;
    min-width: 75px;
    &:before {
      // margin-right: 7px;
      margin-right: 5px;
    }
    &--article:before {
      background-size: 12px;
      width: 12px;
      height: 12px;
    }
    &--video:before {
      background-size: 14px;
      width: 14px;
      height: 14px;
    }
    &--product:before {
      background-size: 14px;
      width: 14px;
      height: 12px;
    }
  }
  time {
    font-size: 11px;
    margin-bottom: 7px;
  }
  &__title {
    line-height: 1.47;
    font-size: 15px;
    // letter-spacing: 0;
    height: 44px;
  }
  &__description {
    line-height: 1.73;
    // letter-spacing: 0;
    height: 57px;
  }
  &__category {
    font-size: 11px;
  }
  // &--category {
  //   a {
  //     padding-bottom: 25px;
  //   }
  //   .column-card__category {
  //     position: absolute;
  //     right: 30px;
  //     top: 34px;
  //   }
  // }
}

.card-content {
  // padding: 52px 20px 33px;
  padding-top: 82px;
  padding-bottom: 19px;
  margin: 75px 0 0;
}

.column-small-card {
  // flex-wrap: nowrap;
  justify-content: start;
  justify-content: flex-start;
  max-width: $base-width;
  margin: 0 auto;
  a {
    width: 160px;
    // margin-bottom: 41px;
    // margin-bottom: 45px;
    margin: 0 24px 45px 0;
    transition: box-shadow .2s, transform .2s;
    &:hover {
      box-shadow: none;
      transform: translateY(3px);
    }
    // &:not(:last-of-type) {
    // &:not(:nth-of-type(6n)) {
    //   margin-right: 24px;
    // }
    @media print, screen and (min-width: 1120px) {
      &:nth-of-type(6n) {
        margin-right: 0;
      }
    }
  }
  &__title {
    // line-height: 47px;
    font-size: 11px;
    height: 47px;
  }
}

.column-single-content {
  // padding: 30px 20px 33px;
  padding-top: 69px;
  padding-bottom: 33px;
  &__title {
    font-size: 30px;
    margin-bottom: 40px;
  }
  .column-card {
    margin-bottom: 42px;
  }
}

.pager {
  justify-content: center;
  color: #fff;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0;
  // padding: 30px 20px 33px;
  // padding: 70px 0 95px;
  padding: 70px 0 80px;
  &__prev,
  &__next {
    position: static;
    // top: 30px;
    a {
      position: relative;
      transition: all .2s;
      &:hover {
        // color: #fff;
        background-color: $base-color;
        box-shadow: none;
        transform: translateY(3px);
        &:after {
          background-position: -14px;
        }
      }
    }
  }
  &__prev {
    margin-right: 15px;
  }
  &__next {
    right: 20px;
  }
  ol {
    a {
      position: relative;
      display: block;
      color: $base-color;
      background-color: #e3f3ff;
      top: 0;
      border-radius: 10px;
      box-shadow: 0 3px #d0eafd;
      transition: all .2s;
      &:hover {
        color: #fff;
        background-color: $base-color;
        top: 3px;
        box-shadow: none;
      }
    }
  }
  li {
    width: 45px;
    margin-right: 15px;
  }
  &__current {
    background-color: $base-color;
    border-radius: 10px;
    box-shadow: 0 3px #0a5d9d;
    &:after {
      display: none;
    }
  }
  &__last {
    display: none;
  }
}


}

