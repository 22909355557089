// tax

.example--tax {
  .heading {
    margin-bottom: 20px;
  }
}

@media print, screen and (min-width: 768px) {

.example--tax {
  .heading {
    margin-bottom: 40px;
  }
}


}

