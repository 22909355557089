// reset

html {
  color: #666;
  line-height: 1.5;
  font-family: $sans-serif-font;
  // font-size: 62.5%;
  // letter-spacing: .04em;
  // height: 100%;
  // overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // -ms-content-zooming: none;
}
body {
  position: relative;
  // height: 100%;
  // min-width: $base-width;
  // margin: 0;
  // overflow: auto;
  // overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl {
  margin-top: 0;
}
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-weight: normal;
// }
ul,
ol {
  padding-left: 0;
}
li {
  list-style-type: none;
}
dd {
  margin-left: 0;
}
a {
  color: $base-color;
  text-decoration: none;
  // &:hover {
    // color: $base-color;
    // text-decoration: none;
  // }
}
img {
  vertical-align: middle;
  max-width: 100%;
}
// [src$=".svg"] {
//   width: 100%;
// }
// table {
  // border-collapse: collapse;
// }
// input,
// button {
  // padding: 0;
  // outline: none;
  // border: 0;
  // border-radius: 0;
  // appearance: none;
// }
input {
  border: 0;
  outline: none;
  cursor: pointer;
}
textarea {
  color: #333;
  outline: none;
  appearance: none;
}
// small {
  // font-size: 100%;
// }
// @include placeholder {
  // color: #efefef;
// }
// address {
//   font-style: normal;
// }
