// change

// .merit--change {
//   > div:first-of-type {
//     margin-bottom: 37px;
//   }
// }

.flow--change {
  margin-bottom: 30px;
}

@media print, screen and (min-width: 768px) {

.merit--change {
  padding-bottom: 30px;
  // > div:first-of-type {
  //   margin-bottom: 52px;
  // }
}

.flow--change {
  margin-bottom: 90px;
}


}

