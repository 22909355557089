// common

// html:after {
//   position: absolute;
//   background-color: #fff;
//   width: 100%;
//   height: 100%;
//   bottom: 100%;
//   z-index: 1;
//   content: "";
// }

.header {
  position: relative;
  background-color: #fff;
  border-bottom: 1px dotted $base-color;
  z-index: 100;
  &__head {
    color: #fff;
    font-size: 10px;
    // padding-left: 20px;
    padding: 0 20px;
    height: 51px;
    overflow: hidden;
  }
  &__logo {
    display: inline-block;
    width: 60px;
    margin-right: 10px;
    // margin-bottom: 0
    a {
      display: block;
      padding-top: 6px;
      height: 24px;
    }
    img {
      display: block;
    }
  }
  &__author {
    position: relative;
    // display: inline-block;
    display: none;
    width: 150px;
    // margin-bottom: 0;
    bottom: 8px;
    @media print, screen and (min-width: 360px) {
      display: inline-block;
    }
    img {
      display: block;
    }
  }
  &__member {
    position: absolute;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // max-width: calc(100% - 280px);
    right: 20px;
    top: 7px;
    // overflow: hidden;
    @media print, screen and (min-width: 360px) {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100% - 280px);
      overflow: hidden;
    }
  }
  &__foot {
    position: absolute;
    top: 0;
    right: 16px;
    font-size: 10px;
    padding: 0 20px;
    p {
      width: 140px;
      margin-bottom: 0;
      @media print, screen and (min-width: 360px) {
        width: 180px;
      }
      a {
        display: block;
        padding-top: 14px;
        height: 37px;
      }
    }
  }
  &__foot ul {
    @media print, screen and (min-width: 768px) {
      top: 0;
      position: absolute;
      right: 18px !important;
    }
  }
  &__foot li {
    position: relative;
    &:first-child .header__button:after {
      background-image: url(#{$img-dir}common/ico-favorite.png);
      background-size: 20px;
      width: 20px;
      height: 20px;
      top: 8px;
    }
    &:nth-child(2) .header__button:after {
      background-image: url(#{$img-dir}common/ico-history.png);
      background-size: 18px;
      width: 18px;
      height: 18px;
      top: 9px;
    }
    &:last-child .header__button:after {
      background-image: url(#{$img-dir}common/ico-menu.png);
      background-size: 18px;
      width: 18px;
      height: 12px;
      top: 12px;
    }
    &:first-child .header__button--disabled:after {
      background-image: url(#{$img-dir}common/ico-favorite-disabled.png);
    }
    &:nth-child(2) .header__button--disabled:after {
      background-image: url(#{$img-dir}common/ico-history-disabled.png);
    }
  }
  &__button {
    display: block;
    color: #000;
    // padding: 31px 6px 0;
    padding-top: 31px;
    height: 20px;
    transition: color 0.2s;
    &:before,
    &:after {
      position: absolute;
      background-repeat: no-repeat;
      left: 50%;
      content: "";
      transform: translateX(-50%);
      transition: opacity 0.2s;
    }
    &:before {
      background-image: url(#{$img-dir}common/ico-close.png);
      background-size: 15px;
      width: 15px;
      height: 15px;
      top: 11px;
      opacity: 0;
    }
    span:first-child {
      display: block;
      transform: scale(0.8);
      &:after {
        position: absolute;
        color: transparent;
        text-align: center;
        width: 100%;
        left: 0;
        content: "閉じる";
        transition: color 0.2s;
      }
    }
    span + span {
      display: none;
    }
    &--active {
      color: transparent;
      &:before {
        opacity: 1;
      }
      &:after {
        opacity: 0;
      }
      span:first-child:after {
        color: #000;
      }
    }
    &--disabled {
      color: #92b9d6;
      // cursor: auto;
      cursor: default;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }
}

// .nav {
//   font-weight: bold;
//   &__dropdown {
//     position: absolute;
//     box-sizing: border-box;
//     // padding: 82px 20px 32px;
//     padding: 114px 20px 32px;
//     width: 100%;
//     // height: 100%;
//     max-height: 100%;
//     // top: calc(-100% + 82px);
//     top: 0;
//     z-index: 1;
//     overflow-y: auto;
//     -webkit-overflow-scrolling: touch;
//     // transform: translateY(calc(-100% + 82px));
//     transform: translateY(-100%);
//     // transition: top .4s;
//     // transition: transform .4s;
//     &--loaded {
//       transition: transform 0.4s;
//     }
//     &--opened {
//       // top: 0;
//       z-index: 10;
//       transform: translateY(0);
//     }
//   }
//   &__guest {
//     margin-bottom: 30px;
//     li {
//       margin-bottom: 15px;
//     }
//   }
//   &__list {
//     background-color: #fff;
//     // line-height: 49px;
//     // font-size: 14px;
//     font-size: 13px;
//     padding: 10px 20px 20px;
//     margin-bottom: 25px;
//     border-radius: 10px;
//     @media print, screen and (min-width: 360px) {
//       font-size: 14px;
//     }
//     li {
//       position: relative;
//       border-bottom: 1px solid $base-color;
//     }
//     a {
//       display: block;
//       padding: 14px 40px 14px 0;
//     }
//     span {
//       position: absolute;
//       font-size: 20px;
//       right: 0;
//       top: 50%;
//       transform: translateY(-50%);
//     }
//     &--content {
//       line-height: 1.58;
//       font-size: 12px;
//       // padding-top: 9px;
//       @media print, screen and (min-width: 360px) {
//         font-size: 12px;
//       }
//       a {
//         padding-top: 10px;
//         padding-bottom: 10px;
//       }
//     }
//   }
//   &__member {
//     margin-bottom: 30px;
//     li {
//       margin-bottom: 15px;
//     }
//     li:last-child {
//       line-height: 46px;
//       a {
//         background-color: #1e75c2;
//         // border: 2px solid #fff;
//         border: 2px solid #125c9d;
//         box-shadow: 0 4px #125c9d;
//       }
//     }
//   }
//   &__text {
//     color: #333;
//     // line-height: 2;
//     font-size: 12px;
//     margin-bottom: 10px;
//   }
//   &__button {
//     line-height: 30px;
//     font-size: 11px;
//     text-align: center;
//     width: 90px;
//     margin: 0 auto;
//     a {
//       display: block;
//       background-color: #fff;
//       box-shadow: 0 2px #0a5d9d;
//       border-radius: 100px;
//       &:after {
//         position: relative;
//         display: inline-block;
//         background: url(#{$img-dir}common/ico-nav.png) no-repeat;
//         background-size: 16px;
//         width: 16px;
//         height: 16px;
//         margin-left: 5px;
//         top: 4px;
//         content: "";
//       }
//     }
//   }
// }

// main {
// padding-top: 82px;
// }

.description {
  color: #999;
  background-color: #eee;
  // line-height: 1.44;
  line-height: 30px;
  // font-size: 10px;
  font-size: 9px;
  letter-spacing: 0.04em;
  // padding: 10px 20px;
  padding: 0 20px;
  // margin: -2px -8px;
  // transform: scale(.9);
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
    overflow: hidden;
  }
}

.directory-nav {
  color: #333;
  line-height: 35px;
  // font-size: 10px;
  font-size: 9px;
  white-space: nowrap;
  // height: 35px;
  margin-bottom: 0;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  li:last-child {
    position: relative;
    padding-left: 30px;
    z-index: -1;
    &:after {
      position: absolute;
      width: 24px;
      height: 24px;
      border-top: 1px solid #ddd;
      border-right: 1px solid #ddd;
      right: -20px;
      top: 50%;
      content: "";
      transform-origin: top;
      transform: scaleX(0.56) rotate(45deg) translateY(-50%);
    }
  }
  a {
    position: relative;
    display: block;
    text-decoration: underline;
    // padding-left: 20px;
    &:after {
      position: absolute;
      width: 24px;
      height: 24px;
      border-top: 1px solid #ddd;
      border-right: 1px solid #ddd;
      right: -20px;
      top: 50%;
      // z-index: 1;
      content: "";
      transform-origin: top;
      transform: scaleX(0.56) rotate(45deg) translateY(-50%);
    }
  }
  li:first-child a {
    padding-left: 20px;
  }
  li:not(:first-child) a {
    padding-left: 30px;
  }
}

.contact {
  // padding: 50px 20px 70px;
  padding: 50px 20px 54px;
  &__title {
    color: #333;
    // line-height: 1.48;
    font-size: 21px;
    text-align: center;
    letter-spacing: 0.06em;
    margin-bottom: 15px;
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
  }
  // form {
  //   margin-bottom: 50px;
  // }
  textarea {
    box-sizing: border-box;
    // display: block;
    // color: #333;
    line-height: 1.79;
    // font-size: 14px;
    // font-size: 16px;
    letter-spacing: 0.04em;
    // padding: 18px 20px;
    padding: 15px 17px;
    width: 100%;
    height: 200px;
    border: 3px solid #eee;
    margin-bottom: 30px;
    border-radius: 10px;
    // transform: scale(.875);
    transition: background-color 0.4s, border-color 0.4s;
    &::placeholder {
      color: #999;
    }
    &:focus {
      background-color: #e3f3ff;
      border-color: $base-color;
    }
  }
  input {
    display: block;
    color: #fff;
    background-color: $base-color;
    font-weight: bold;
    font-size: 18px;
    width: 180px;
    height: 60px;
    margin: 0 auto;
    border-radius: 100px;
    box-shadow: 0 4px #0a5d9d;
  }
}

.aside {
  background-color: #217dc5;
  letter-spacing: 0.04em;
  padding: 40px 20px;
  > div {
    background-color: #fff;
    padding: 20px 20px 6px;
    border-radius: 10px;
  }
  > div > div:first-of-type {
    padding-bottom: 6px;
    border-bottom: 1px dotted $base-color;
    margin-bottom: 19px;
  }
  &__title {
    color: #333;
    font-weight: bold;
    line-height: 1.75;
    font-size: 12px;
    margin-bottom: 10px;
  }
  &__description {
    line-height: 1.8;
    font-size: 10px;
  }
  > div > div:last-of-type {
    line-height: 1.8;
    font-size: 10px;
  }
  > div > div > div {
    margin-bottom: 5px;
  }
  &__image {
    min-width: 30px;
    max-width: 30px;
    margin-right: 10px;
  }
}

.footer {
  position: relative;
  padding: 40px 20px 58px;
  &__title {
    color: #333;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 10px;
  }
  &__global {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 30px;
    li {
      position: relative;
      border-bottom: 1px solid $base-color;
    }
    a {
      display: block;
      padding: 14px 40px 14px 0;
    }
    span {
      position: absolute;
      font-size: 20px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__link {
    // color: #333;
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0;
    a {
      color: #333;
      padding: 10px 0;
    }
  }
  &__list {
    line-height: 53px;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 40px;
    a {
      position: relative;
      display: block;
      border-bottom: 1px solid #ccc;
    }
    span {
      position: absolute;
      font-size: 20px;
      margin-top: -10px;
      right: 0;
      top: 50%;
    }
  }
  &__button span {
    transform: rotate(90deg);
    transition: transform 0.2s;
  }
  &__button--opened span {
    transform: rotate(270deg);
  }
  &__nav {
    display: none;
    a {
      padding-left: 14px;
    }
  }
  > div {
    color: #999;
    text-align: center;
  }
  &__image {
    width: 150px;
    margin: 0 auto 40px;
  }
  &__sub-nav {
    flex-wrap: wrap;
    justify-content: center;
    line-height: 2.36;
    font-size: 11px;
    margin-bottom: 30px;
    li {
      // margin: 0 1em;
      + li {
        margin-left: 2em;
      }
    }
    a {
      color: #666;
      // padding: 10px 0;
      padding: 5px 0;
    }
  }
  &__text,
  &__copy {
    line-height: 1.8;
    font-size: 10px;
  }
  &__text {
    margin-bottom: 17px;
  }
  &__prev {
    position: absolute;
    font-size: 0;
    right: 20px;
    bottom: 257px;
    a {
      display: block;
      background-color: #fff;
      width: 41px;
      height: 41px;
      border: 2px solid $base-color;
      border-radius: 50%;
      box-shadow: 0 3px #0a5d9d;
      &:after {
        position: absolute;
        background: url(#{$img-dir}common/ico-prev-top.png) no-repeat;
        background-size: 44px;
        width: 22px;
        height: 20px;
        left: 50%;
        top: 50%;
        content: "";
        transform: translate(-50%, -50%);
      }
    }
  }
}

@media print, screen and (min-width: 768px) {
  .header {
    position: static;
    border-bottom: 0;
    // z-index: 100;
    &__head {
      position: relative;
      font-size: 12px;
      letter-spacing: 0.04em;
      height: 52px;
      z-index: 100;
      div {
        position: relative;
        max-width: $base-width;
        margin: 0 auto;
      }
    }
    &__logo {
      width: 210px;
      margin-right: 16px;
      padding: 0;
      a {
        padding-top: 10px;
        height: 36px;
        transition: opacity 0.2s;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    &__author {
      display: none;
      width: 188px;
      bottom: 17px;
      @media print, screen and (min-width: 880px) {
        display: inline-block;
      }
    }
    &__member {
      font-weight: bold;
      // max-width: 190px;
      // max-width: 380px;
      max-width: calc(100% - 496px);
      // right: 0;
      right: 410px;
      top: 17px;
      @media print, screen and (min-width: 880px) {
        max-width: calc(100% - 700px);
      }
    }
    &__foot {
      position: static;
      font-weight: bold;
      font-size: 11px;
      // padding: 0 20px;
      max-width: $base-width;
      margin: 0 auto;
      p {
        position: relative;
        width: 210px;
        z-index: 1;
        a {
          padding-top: 24px;
          // width: 210px;
          height: 51px;
          transition: opacity 0.4s;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    &__foot ul {
      line-height: 30px;
      text-align: center;
      right: 350px;
      top: 10px;
      z-index: 100;
      @media print, screen and (min-width: 1100px) {
        left: 50%;
        margin-left: 350px;
      }
    }
    &__foot li:first-child {
      margin-right: 10px;
    }
    &__button {
      color: $base-color;
      background-color: #fff;
      padding-top: 0;
      width: 90px;
      height: auto;
      border-radius: 100px;
      box-shadow: 0 2px #0a5d9d;
      // transition: background-color .2s;
      transition: all 0.2s;
      &:before,
      &:after {
        display: none;
      }
      &:hover {
        background-color: #e3f3ff;
        box-shadow: none;
        transform: translateY(2px);
      }
      span:first-child {
        display: inline-block;
        transform: scale(1);
        &:after {
          display: none;
        }
      }
      span + span {
        position: relative;
        display: inline-block;
        font-size: 16px;
        // margin-left: 5px;
        margin-left: 2px;
        top: 3px;
        transform: rotate(90deg);
        transition: transform 0.2s;
      }
      &--active {
        color: $base-color;
        background-color: #e3f3ff;
        box-shadow: none;
        transform: translateY(2px);
        span + span {
          transform: rotate(270deg);
        }
      }
      &--disabled {
        color: #69aee3;
        background-color: #2e8ed9;
        // cursor: auto;
        &:hover {
          color: #69aee3;
          background-color: #2e8ed9;
          box-shadow: 0 2px #0a5d9d;
          transform: translateY(0);
        }
      }
    }
  }

  // .nav {
  //   height: 75px;
  //   &__dropdown {
  //     // padding: 30px 30px 20px;
  //     padding: 82px 30px 22px;
  //     max-width: $base-width;
  //     // height: auto;
  //     // max-height: 100%;
  //     // left: 50%;
  //     right: 50%;
  //     // top: calc(-100% + 52px);
  //     // top: -100%;
  //     // z-index: 1;
  //     // -webkit-overflow-scrolling: touch;
  //     border-radius: 0 0 10px 10px;
  //     // transform: translateX(-50%);
  //     // transform: translateX(50%);
  //     // transform: translate(50%, calc(-100% + 52px));
  //     transform: translate(50%, -100%);
  //     &--opened {
  //       // top: 0;
  //       // z-index: 10;
  //       transform: translateX(50%);
  //     }
  //   }
  //   &__dropdown--global {
  //     position: static;
  //     // background-color: transparent;
  //     padding: 0;
  //     // width: auto;
  //     // height: auto;
  //     // top: 0;
  //     // z-index: 10;
  //     // overflow-y: visible;
  //     // transform: translateX(0);
  //     transform: none;
  //   }
  //   &__guest {
  //     position: absolute;
  //     // margin-bottom: 30px;
  //     right: 20px;
  //     top: 10px;
  //     z-index: 100;
  //     @media print, screen and (min-width: 1100px) {
  //       margin-left: 350px;
  //       left: 50%;
  //     }
  //     li {
  //       line-height: 28px;
  //       font-size: 12px;
  //       // width: 90px;
  //       // margin-bottom: 15px;
  //     }
  //     li:first-child {
  //       margin-right: 10px;
  //     }
  //     a {
  //       width: 88px;
  //       border-width: 1px;
  //       transition: all 0.2s;
  //       &:hover {
  //         transform: translateY(2px);
  //       }
  //     }
  //     li:first-child a {
  //       box-shadow: 0 2px #64a20f;
  //       &:hover {
  //         box-shadow: none;
  //       }
  //     }
  //     li:last-child a {
  //       box-shadow: 0 2px #c16c09;
  //       &:hover {
  //         box-shadow: none;
  //       }
  //     }
  //   }
  //   &__list {
  //     font-size: 15px;
  //     letter-spacing: 0.04em;
  //     // padding: 27px 30px 33px;
  //     padding: 16px 30px 33px;
  //     min-height: calc(100% - 103px);
  //     margin-bottom: 20px;
  //     a {
  //       padding: 17px 36px 17px 0;
  //       // transition: background-color .2s;
  //       transition: color 0.2s;
  //       &:after {
  //         position: absolute;
  //         background-color: #1289e6;
  //         // background-color: #333;
  //         width: 0;
  //         height: 1px;
  //         left: 0;
  //         bottom: -1px;
  //         content: "";
  //         transition: width 0.2s;
  //       }
  //       &:hover {
  //         // color: #fff;
  //         color: #1289e6;
  //         // background-color: #e3f3fe;
  //         // span {
  //         //   background-color: #fff;
  //         //   border-radius: 50%;
  //         // }
  //         &:after {
  //           width: 100%;
  //         }
  //       }
  //     }
  //     // span {
  //     //   transition: background-color .2s;
  //     // }
  //     &--content {
  //       // line-height: 1.58;
  //       font-size: 12px;
  //       a {
  //         padding-top: 10px;
  //         padding-bottom: 10px;
  //       }
  //     }
  //   }
  //   &__dropdown--global .nav__list {
  //     justify-content: space-between;
  //     position: absolute;
  //     box-sizing: border-box;
  //     background-color: transparent;
  //     line-height: 75px;
  //     font-weight: normal;
  //     // font-size: 13px;
  //     // font-size: calc(13 / 1440 * 100vw);
  //     font-size: calc(12 / 1440 * 100vw);
  //     // padding: 0 20px 0 290px;
  //     padding: 0;
  //     width: 100%;
  //     height: 75px;
  //     max-width: $base-width;
  //     min-height: auto;
  //     right: 50%;
  //     top: 52px;
  //     //overflow: hidden;
  //     transform: translateX(50%);
  //     @media print, screen and (min-width: 1020px) {
  //       font-size: 12px;
  //     }
  //     @media print, screen and (min-width: 1120px) {
  //       // padding: 0 0 0 270px;
  //       // padding: 0 0 0 250px;
  //       padding-right: 0;
  //     }
  //     li {
  //       border-bottom: 0;
  //       &:before {
  //         content: " ";
  //         display: inline-block;
  //         vertical-align: middle;
  //         width: 1px;
  //         height: 24px;
  //         background: #dcdcdc;
  //         position: absolute;
  //         top: 50%;
  //         margin-top: -12px;
  //       }
  //     }
  //     a {
  //       text-align: center;
  //       color: #000;
  //       padding: 0 21px;
  //       transition: color 0.4s;
  //       &:hover {
  //         color: $base-color;
  //         background-color: transparent;
  //       }
  //     }
  //     .nav__link--current {
  //       color: $base-color;
  //       font-weight: bold;
  //     }
  //     span {
  //       display: none;
  //     }
  //   }
  //   &__member {
  //     position: absolute;
  //     // margin-bottom: 30px;
  //     right: 20px;
  //     top: 10px;
  //     z-index: 100;
  //     @media print, screen and (min-width: 1100px) {
  //       margin-left: 350px;
  //       left: 50%;
  //     }
  //     li {
  //       font-size: 11px;
  //       // width: 90px;
  //       // margin-bottom: 15px;
  //     }
  //     li:first-child {
  //       line-height: 30px;
  //       margin-right: 10px;
  //     }
  //     li:last-child {
  //       line-height: 28px;
  //     }
  //     a {
  //       // width: 88px;
  //       // border-width: 1px;
  //       transition: all 0.2s;
  //       &:hover {
  //         // background-color: #e3f3ff;
  //         // box-shadow: none;
  //         transform: translateY(2px);
  //       }
  //     }
  //     li:first-child a {
  //       width: 90px;
  //       border: 0;
  //       box-shadow: 0 2px #0a5d9d;
  //       &:hover {
  //         color: $base-color;
  //         background-color: #e3f3ff;
  //         box-shadow: none;
  //       }
  //     }
  //     li:last-child a {
  //       width: 88px;
  //       // border-width: 1px;
  //       border: 1px solid #fff;
  //       box-shadow: 0 2px #125c9d;
  //       &:hover {
  //         color: #1e75c2;
  //         background-color: #fff;
  //         // background-color: #e3f3ff;
  //         // border-color: #fff;
  //         box-shadow: none;
  //       }
  //     }
  //     span {
  //       font-size: 16px;
  //       margin-left: 5px;
  //       top: 3px;
  //     }
  //   }
  //   div div div {
  //     width: 320px;
  //     + div {
  //       margin-left: 30px;
  //     }
  //   }
  //   &__text {
  //     letter-spacing: 0.04em;
  //     margin-bottom: 16px;
  //   }
  //   &__button {
  //     float: right;
  //     line-height: 28px;
  //     // margin: 0 auto;
  //     a {
  //       border: 1px solid transparent;
  //       transition: all 0.2s;
  //       // &:after {
  //       // top: 4px;
  //       // }
  //       &:hover {
  //         border-color: $base-color;
  //         box-shadow: none;
  //         transform: translateY(2px);
  //       }
  //     }
  //   }
  // }

  .description {
    color: #666;
    line-height: 35px;
    font-size: 10px;
    letter-spacing: 0.06em;
    p {
      max-width: $base-width;
      margin: 0 auto;
    }
  }

  .directory-nav {
    line-height: 40px;
    font-size: 10px;
    letter-spacing: 0.06em;
    // white-space: nowrap;
    padding: 0 20px;
    max-width: $base-width;
    margin: 0 auto;
    overflow-x: hidden;
    // overflow-y: hidden;
    // -webkit-overflow-scrolling: touch;
    li:not(:last-child):after {
      position: relative;
      display: inline-block;
      background: url(#{$img-dir}common/ico-directory-nav.png) no-repeat;
      background-size: 6px;
      width: 6px;
      // height: 9px;
      height: 10px;
      margin: 0 15px;
      top: 1px;
      content: "";
    }
    li:last-child {
      padding-left: 0;
      &:after {
        display: none;
      }
    }
    a {
      display: inline-block;
      &:after {
        display: none;
      }
      &:hover {
        text-decoration: none;
      }
    }
    li:first-child a {
      padding-left: 0;
    }
    li:not(:first-child) a {
      padding-left: 0;
    }
  }

  .contact {
    // padding: 50px 20px 70px;
    padding-top: 90px;
    padding-bottom: 94px;
    max-width: $base-width;
    margin: 0 auto;
    &__title {
      font-size: 30px;
      margin-bottom: 20px;
    }
    &__text {
      // line-height: 1.79;
      // line-height: 1.8;
      font-size: 15px;
      text-align: center;
      margin-bottom: 30px;
    }
    // form {
    //   margin-bottom: 94px;
    // }
    textarea {
      // line-height: 1.79;
      line-height: 1.8;
      // font-size: 15px;
      padding: 30px;
      // width: 100%;
      height: 180px;
      // transition-duration: .4s;
    }
    input {
      // width: 176px;
      // height: 56px;
      border: 2px solid $base-color;
      transition: all 0.4s;
      &:hover {
        color: $base-color;
        background-color: #fff;
        box-shadow: none;
        transform: translateY(4px);
      }
    }
  }

  .aside {
    // padding: 40px 20px;
    padding-top: 60px;
    padding-bottom: 60px;
    > div {
      box-sizing: border-box;
      padding: 30px 30px 32px;
      // padding: 30px 30px 27px;
      max-width: $base-width;
      margin: 0 auto;
    }
    > div > div:first-of-type {
      padding-bottom: 40px;
      border-bottom-width: 1px;
      margin-bottom: 26px;
    }
    &__title {
      line-height: 1.47;
      font-size: 15px;
      padding-right: 29px;
      border-right: 1px dotted $base-color;
      margin-bottom: 0;
    }
    &__description {
      line-height: 1.83;
      font-size: 12px;
      letter-spacing: 0;
      padding-left: 29px;
      margin-bottom: 0;
    }
    > div > div:last-of-type {
      line-height: 1.83;
      font-size: 12px;
      letter-spacing: 0;
    }
    > div > div > div {
      align-items: center;
      // margin-bottom: 5px;
      &:first-of-type {
        padding-right: 29px;
        border-right: 1px dotted $base-color;
      }
      &:last-of-type {
        padding-left: 30px;
      }
    }
    // div div div div {
    //   min-width: 30px;
    //   max-width: 30px;
    //   margin-right: 10px;
    // }
    > div > div > div:first-of-type .aside__image {
      min-width: 44px;
      max-width: 44px;
      margin-right: 23px;
    }
    > div > div > div:last-of-type .aside__image {
      min-width: 60px;
      max-width: 60px;
      margin-right: 15px;
    }
    &__text {
      margin-bottom: 0;
    }
  }

  .footer {
    // padding: 40px 20px 58px;
    padding-top: 60px;
    padding-bottom: 88px;
    max-width: $base-width;
    margin: 0 auto;
    &__title {
      font-size: 15px;
      margin-bottom: 30px;
    }
    &__global {
      justify-content: space-between;
      font-weight: normal;
      // font-size: 13px;
      font-size: calc(13 / 1440 * 100vw);
      letter-spacing: 0.04em;
      padding-bottom: 45px;
      border-bottom: 1px dotted $base-color;
      margin-bottom: 45px;
      @media print, screen and (min-width: 940px) {
        font-size: 13px;
      }
      li {
        border-bottom: 0;
      }
      a {
        display: inline;
        color: #666;
        padding: 10px 0;
        &:hover {
          color: $base-color;
        }
      }
      span {
        display: none;
      }
    }
    &__link {
      font-size: 15px;
      letter-spacing: 0.04em;
      // padding-bottom: 15px;
      border-bottom: 0;
      margin-bottom: 30px;
      a {
        &:hover {
          color: $base-color;
        }
      }
    }
    &__list {
      // justify-content: space-between;
      line-height: normal;
      letter-spacing: 0.04em;
      padding-bottom: 60px;
      border-bottom: 1px dotted $base-color;
      // margin-bottom: 61px;
      margin-bottom: 59px;
      a {
        // display: inline;
        display: inline-block;
        border-bottom: 0;
      }
      span {
        display: none;
      }
    }
    &__list > li {
      width: calc(100% / 5);
    }
    &__button {
      color: #333;
      // line-height: normal;
      font-size: 14px;
      margin-bottom: 10px;
      // cursor: auto;
      cursor: default;
    }
    &__nav {
      display: block !important;
      line-height: 1.85;
      font-weight: normal;
      font-size: 13px;
      a {
        color: #666;
        padding-left: 0;
        // transition: color .2s;
        &:hover {
          color: $base-color;
        }
      }
    }
    > div {
      justify-content: space-between;
      text-align: left;
    }
    &__image {
      order: 1;
      // margin: 0 auto 40px;
      // margin: 8px 0 0;
      margin: 8px 90px 0 0;
      a {
        transition: opacity 0.2s;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    &__sub-nav {
      // justify-content: center;
      justify-content: start;
      // line-height: normal;
      // line-height: 2.36;
      // margin-bottom: 17px;
      margin-bottom: 15px;
      a {
        // padding: 5px 0;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__text,
    &__copy {
      line-height: 2;
      font-size: 11px;
    }
    &__text {
      margin-bottom: 0;
    }
    &__prev {
      // right: 20px;
      bottom: 137px;
      a {
        transition: all 0.2s;
        &:hover {
          background-color: #217dc5;
          border-color: #0a5d9d;
          box-shadow: none;
          transform: translateY(3px);
          &:after {
            background-position: -22px;
          }
        }
      }
    }
  }
}
