@charset "UTF-8";
/*
  icon-button
  説明：アイコンとボタンのスタイル
 */

/*各トップで適用するスタイルは_service.scssに移動 */

/*独立して扱われるもの*/

.service-guide__example__heading {
  position: relative;
  box-sizing: border-box;
  font-size: 32px;
  line-height: 75px;
  display: table;
  width: auto;
  height: 75px;
  margin: 0 auto 40px;
  padding: 0 38px 0 82px;
  letter-spacing: 0.06em;
  color: #ffffff;
  border-radius: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  &:before {
    width: 58px;
    height: 80px;
    /*bottom: 0;*/
    left: 14px;
    background-size: 58px;
    background-repeat: no-repeat;
    position: absolute;
    content: "";
  }
  &:after {
    position: absolute;
    bottom: -20px;
    left: 50%;
    content: "";
    margin-left: -10px;
    border-width: 10px;
    border: 11px solid;
    border-color: #1777c2 transparent transparent transparent;
  }
}

#example01 .service-guide__example__heading {
  &:before {
    background-image: url(#{$img-dir}sub/ico-example1.png);
    bottom: 0;
  }
}

#example02 .service-guide__example__heading {
  &:before {
    background-image: url(#{$img-dir}sub/ico-example2.png);
    bottom: 0;
    top: 0;
  }
}

#example03 .service-guide__example__heading {
  &:before {
    background-image: url(#{$img-dir}sub/ico-example3.png);
    bottom: 0;
    top: 0;
  }
}

.heading-icon {
  display: block;
  position: relative;
  color: #333333;
  background-color: #f4f4df;
  border-radius: 10px;
  width: 192px;
  height: 170px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
  margin: 0;
  padding-top: 100px;
  line-height: 1.3;
  letter-spacing: 0.06em;
  font-weight: bold;
  text-align: center;
  img {
    top: 16px;
    width: 120px;
    height: auto;
    position: absolute;
    left: 50%;
    margin-left: -60px;
  }
  &__text {
    display: flex;
    height: 54px;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    span {
      text-align: center;
    }
  }
}
