// module

// エントリーリスト
.entry-list {
  &__title {
  }
  &__copy {
  }
  &__content {
    margin: 0;
  }
  &__head {
  }
  &__body {
  }
  &__foot {
  }

  .is-r {
    margin-left: auto;
  }

  //ピックアップ card、タイトル操作
  &.is-pickup {
    .entry-list {
      &__title {
        font-weight: bold;
        line-height: 1.52;
        margin-bottom: 20px;
        letter-spacing: 0.06em;
        color: #333;
        text-align: center;
        @media print, screen and (min-width: 768px) {
          font-size: 36px;
        }
        @media print, screen and (max-width: 767px) {
          font-size: 21px;
        }
      }
      &__copy {
        font-weight: bold;
        letter-spacing: 0.04em;
        color: #1777c2;
        text-align: center;
        @media print, screen and (min-width: 768px) {
          font-size: 15px;
          line-height: 1.47;
          margin: 0;
          padding-top: 16px;
        }
        @media print, screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 1.75;
          margin-bottom: 10px;
        }
      }
      &__item {
        margin: 0 0 8px 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

// サービスリスト
.service-list {
  &__title {
  }
  &__copy {
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    @media print, screen and (max-width: 767px) {
      justify-content: center;
    }
  }
  &__item {
    padding: 10px;
    @media print, screen and (max-width: 767px) {
      padding: 5px;
    }
  }
  &__head {
  }
  &__body {
    padding: 10px;
    background: #fff;
    border-radius: 5px;
  }
  &__foot {
  }
}
