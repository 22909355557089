// home

.home-hero {
  position: relative;
  box-sizing: border-box;
  background: #e3f3ff url(#{$img-dir}home/bg-hero.png) no-repeat center 91px;
  background-size: 87px;
  font-weight: bold;
  text-align: center;
  padding-top: 149px;
  height: 640px;
  &__lead {
    position: relative;
    color: #333;
    // font-size: 12px;
    font-size: 10px;
    letter-spacing: 0.04em;
    margin-bottom: 10px;
    z-index: 1;
    @media print, screen and (min-width: 360px) {
      font-size: 12px;
    }
  }
  strong {
    box-shadow: inset 0 -10px #ffec60;
  }
  &__title {
    color: #333;
    line-height: 1.47;
    // font-size: 21px;
    font-size: 18px;
    letter-spacing: 0.06em;
    margin: 0 0 14px 67px;
    @media print, screen and (min-width: 360px) {
      font-size: 21px;
    }
    &:after {
      position: absolute;
      // display: inline-block;
      background: url(#{$img-dir}home/ico-hero.png) no-repeat;
      background-size: 22px;
      width: 22px;
      height: 33px;
      // margin-left: .5em;
      margin: -1.5px 0 0 0.5em;
      // top: 4px;
      content: "";
    }
  }
  &__card {
    position: relative;
    height: 227px;
    &:before,
    &:after {
      position: absolute;
      background-repeat: no-repeat;
      content: "";
    }
    &:before {
      background-image: url(#{$img-dir}home/balloon-hero.png);
      background-size: 84px;
      width: 84px;
      height: 90px;
      left: 0;
      top: -90px;
      @media print, screen and (min-width: 375px) {
        margin-left: -188px;
        left: 50%;
      }
    }
    &:after {
      background-image: url(#{$img-dir}home/img-hero.png);
      background-size: 118px;
      width: 118px;
      height: 172px;
      right: 5px;
      bottom: -4px;
      // z-index: 1;
      @media print, screen and (min-width: 375px) {
        margin-right: -182px;
        right: 50%;
      }
    }
  }
  &__card div {
    position: absolute;
    box-sizing: border-box;
    background-color: $base-color;
    padding: 20px 105px 0 20px;
    width: 100%;
    height: 227px;
    &:last-of-type {
      visibility: hidden;
      opacity: 0;
      transition: visibility 1s, opacity 1s;
    }
  }
  &__card--opened {
    div:last-of-type {
      visibility: visible;
      opacity: 1;
    }
  }
  &__sub-title {
    color: #fff;
    line-height: 1.57;
    // font-size: 14px;
    font-size: 12px;
    letter-spacing: 0.04em;
    @media print, screen and (min-width: 360px) {
      font-size: 14px;
    }
  }
  &__card div:first-of-type &__sub-title {
    margin-bottom: 15px;
  }
  &__card div:last-of-type &__sub-title {
    margin-bottom: 10px;
  }
  &__card div:first-of-type li {
    // font-size: 13px;
    font-size: 11px;
    margin-bottom: 15px;
    @media print, screen and (min-width: 360px) {
      font-size: 13px;
    }
  }
  &__card div:last-of-type li {
    line-height: 36px;
    margin-bottom: 14px;
  }
  &__list {
    li {
      position: absolute;
      // width: 120px;
      width: 100px;
      @media print, screen and (min-width: 360px) {
        width: 120px;
      }
      &:first-child {
        left: 20px;
        top: 11px;
        @media print, screen and (min-width: 375px) {
          margin-left: -168px;
          left: 50%;
        }
      }
      &:nth-child(2) {
        right: 20px;
        top: 11px;
        @media print, screen and (min-width: 375px) {
          margin-right: -168px;
          right: 50%;
        }
      }
      &:nth-child(3) {
        left: 20px;
        bottom: 29px;
        @media print, screen and (min-width: 375px) {
          margin-left: -168px;
          left: 50%;
        }
        img {
          bottom: 0;
        }
      }
      &:last-child {
        right: 20px;
        bottom: 29px;
        @media print, screen and (min-width: 375px) {
          margin-right: -168px;
          right: 50%;
        }
        img {
          bottom: 0;
        }
      }
    }
    img {
      position: absolute;
      left: 0;
      // top: 0;
      opacity: 0;
      transform: scale(0);
      // &:first-of-type {
      //   animation: balloon-zoom-in 8s infinite;
      // }
      // &:nth-of-type(2) {
      //   animation: balloon-zoom-in 8s 4s infinite;
      // }
    }
  }
  &--loaded {
    .home-hero__list {
      img {
        &:first-of-type {
          animation: balloon-zoom-in 8s infinite;
        }
        &:nth-of-type(2) {
          animation: balloon-zoom-in 8s 4s infinite;
        }
      }
    }
  }
}

.home-about {
  position: relative;
  color: #fff;
  padding: 40px 20px 70px;
  overflow: hidden;
  &:after {
    position: absolute;
    background: url(#{$img-dir}home/bg-about.png) no-repeat;
    background-size: 352px;
    width: 352px;
    height: 40px;
    left: -4px;
    bottom: -4px;
    content: "";
  }
  &__box {
    padding-bottom: 19px;
  }
  &__title {
    // font-size: 24px;
    font-size: 22px;
    text-align: center;
    letter-spacing: 0.06em;
    margin-bottom: 20px;
    @media print, screen and (min-width: 360px) {
      font-size: 24px;
    }
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: 0.04em;
  }
  &__text:first-of-type {
    margin-bottom: 20px;
  }
  ul {
    line-height: 1.93;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
  }
  li {
    position: relative;
    // padding-left: 17px;
    padding-left: 20px;
    &:after {
      position: absolute;
      background: url(#{$img-dir}home/ico-about.png) no-repeat;
      background-size: 13px;
      width: 13px;
      height: 9px;
      left: 0;
      top: 10px;
      content: "";
    }
  }
  ul + .home-about__text {
    margin-bottom: 30px;
  }
  video {
    max-width: 100%;
    // opacity: 0;
    border-radius: 10px;
  }
  img {
    border-radius: 10px;
  }
}

.home-service {
  padding: 50px 20px 59px;
  &__lead {
    color: $base-color;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.04em;
    margin-bottom: 10px;
  }
  &__title {
    color: #333;
    // font-size: 24px;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.06em;
    margin-bottom: 20px;
    @media print, screen and (min-width: 360px) {
      font-size: 24px;
    }
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 30px;
  }
  > div {
    font-weight: bold;
    letter-spacing: 0.04em;
  }
  &__card {
    padding-bottom: 15px;
    border-bottom: 1px dotted $base-color;
    margin-bottom: 30px;
  }
  &__merit {
    color: #fff;
    background-color: #f4b119;
    line-height: 30px;
    font-size: 15px;
    text-align: center;
    width: 120px;
    margin: 0 auto 20px;
    border-radius: 10px;
  }
  img {
    display: block;
    margin: 0 auto 20px;
  }
  &__card:first-of-type img {
    width: 130px;
  }
  &__card:nth-of-type(2) img {
    width: 90px;
  }
  &__card:last-of-type img {
    width: 90px;
  }
  &__point {
    color: #333;
    line-height: 1.6;
    font-size: 15px;
  }
  strong {
    color: $base-color;
  }
  li {
    margin-bottom: 14px;
  }
}

.home-content {
  position: relative;
  background-color: #217dc5;
  padding: 50px 0 140px;
  overflow: hidden;
  &:after {
    position: absolute;
    background: url(#{$img-dir}home/bg-content.png) no-repeat;
    background-size: 357px;
    width: 357px;
    height: 44px;
    left: -5px;
    // bottom: -9px;
    bottom: -5px;
    content: "";
  }
  &__title {
    color: #fff;
    // font-size: 24px;
    font-size: 20px;
    letter-spacing: 0.06em;
    margin: 0 0 25px 20px;
    @media print, screen and (min-width: 360px) {
      font-size: 24px;
    }
  }
  &__button {
    position: absolute;
    right: 20px;
    bottom: 56px;
  }
}

.home-search {
  padding: 50px 20px;
  &__title {
    color: #333;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.06em;
    margin-bottom: 20px;
  }
  div:first-of-type > a {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 20px 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    &:first-of-type {
      background-image: url(#{$img-dir}home/bg-search1.jpg);
    }
    &:last-of-type {
      background-image: url(#{$img-dir}home/bg-search2.jpg);
    }
  }
  &__button {
    color: #fff;
    background-color: #de3f7c;
    line-height: 42px;
    // font-size: 14px;
    font-size: 12px;
    text-align: center;
    max-width: 315px;
    margin: 0 auto;
    border-radius: 20px;
    @media print, screen and (min-width: 360px) {
      font-size: 14px;
    }
    &:before {
      position: relative;
      display: inline-block;
      background-repeat: no-repeat;
      margin-right: 5px;
      content: "";
    }
  }
  div:first-of-type a:first-of-type .home-search__button:before {
    background-image: url(#{$img-dir}module/ico-video.png);
    background-size: 20px;
    width: 20px;
    height: 20px;
    top: 4px;
  }
  div:first-of-type a:last-of-type .home-search__button:before {
    background-image: url(#{$img-dir}module/ico-article.png);
    background-size: 18px;
    width: 18px;
    height: 18px;
    top: 3px;
  }
  div:first-of-type p:last-of-type {
    margin: 0 auto;
  }
  div:last-of-type {
    flex-wrap: wrap;
    font-weight: bold;
    // font-size: 12px;
    text-align: center;
  }
  div:last-of-type a {
    position: relative;
    display: block;
    color: #333;
    background-color: #f4f4df;
    // padding: 89px 0 13px;
    // width: 50%;
    width: calc(50% - 10px);
    // margin: 0 20px 20px 0;
    margin-bottom: 20px;
    border-radius: 10px;
    &:nth-of-type(odd) {
      margin-right: 20px;
    }
    &:first-of-type,
    &:nth-of-type(2) {
      // font-size: 12px;
      font-size: 10px;
      padding: 89px 0 13px;
      @media print, screen and (min-width: 360px) {
        font-size: 12px;
      }
    }
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:last-of-type {
      font-size: 13px;
      padding: 101px 0 17px;
    }
  }
  img {
    position: absolute;
    width: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
  a:first-of-type img {
    // width: 80px;
    top: 25px;
  }
  a:nth-of-type(2) img {
    // width: 80px;
    top: 24px;
  }
  a:nth-of-type(3) img {
    // width: 80px;
    top: 25px;
  }
  a:nth-of-type(4) img {
    width: 82px;
    top: 18px;
  }
  a:last-of-type img {
    // width: 80px;
    top: 30px;
  }
}

.home .news {
  padding-top: 50px;
  padding-bottom: 40px;
  &__box {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  &__heading {
    margin-bottom: 0;
  }
  &__box p {
    width: 135px;
    margin-bottom: 0;
  }
  &__card a {
    margin-bottom: 15px;
  }
}

.home .contact {
  padding-bottom: 70px;
  &__box {
    letter-spacing: 0.04em;
    padding: 20px 20px 10px;
    margin-top: 54px;
    border-radius: 10px;
    a {
      position: relative;
      background-color: #fff;
      padding: 20px 67px 8px 20px;
      margin-bottom: 15px;
      border-radius: 10px;
      box-shadow: 0 5px #d0eafd;
      &:before,
      &:after {
        position: absolute;
        content: "";
      }
      &:before {
        background: url(#{$img-dir}home/ico-external.png) no-repeat;
        background-size: 40px;
        width: 20px;
        height: 20px;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:after {
        background-color: #e3f3ff;
        width: 1px;
        height: 100%;
        right: 51px;
        top: 0;
      }
    }
  }
  &__image {
    min-width: 30px;
    max-width: 30px;
    margin-right: 11px;
  }
  &__sns {
    color: #333;
    // line-height: 1.43;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 6px;
  }
  &__sub-text {
    color: #666;
    font-size: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .home .header__foot {
    p {
      display: none;
    }
  }

  .home-hero {
    background-image: url(#{$img-dir}home/pc-bg-hero.png);
    background-position: center 19px;
    background-size: 1269px;
    padding-top: 0;
    // min-width: 1440px;
    height: 550px;
    // margin: 0 auto;
    // overflow-x: hidden;
    &__box {
      position: relative;
      box-sizing: border-box;
      background-color: #fff;
      padding-top: 85px;
      width: 630px;
      height: 630px;
      // margin: -40px auto 0;
      margin: 0 auto;
      bottom: 40px;
      border-radius: 50%;
    }
    &__heading {
      width: 211px;
      margin: 0 auto 17px;
      img {
        display: block;
      }
    }
    &__lead {
      font-size: 18px;
      margin-bottom: 15px;
    }
    &__title {
      line-height: 1.45;
      font-size: 33px;
      margin: 0 0 22px;
      &:after {
        background-size: 33px;
        width: 33px;
        height: 52px;
        // margin: -1.5px 0 0 .5em;
      }
    }
    &__card {
      width: 400px;
      height: 252px;
      margin: 0 auto;
      &:before,
      &:after {
        z-index: 1;
      }
      &:before {
        background-image: url(#{$img-dir}home/pc-balloon-hero.png);
        background-size: 129px;
        width: 129px;
        height: 121px;
        margin-left: 0;
        left: -70px;
        top: -60px;
      }
      &:after {
        background-size: 200px;
        width: 200px;
        height: 293px;
        margin-right: 0;
        right: -145px;
        bottom: -64px;
      }
    }
    &__card div {
      padding: 20px 35px 0;
      width: 400px;
      height: 252px;
      border-radius: 10px;
    }
    &__sub-title {
      line-height: 1.5;
      font-size: 18px;
    }
    // &__card div:first-of-type &__sub-title {
    //   margin-bottom: 15px;
    // }
    &__card div:last-of-type &__sub-title {
      margin-bottom: 15px;
    }
    &__card div:first-of-type li {
      font-size: 18px;
      margin-bottom: 19px;
    }
    &__card div:last-of-type li {
      line-height: 41px;
      // margin-bottom: 14px;
      a {
        width: 100%;
      }
    }
    &__list {
      li {
        // width: 120px;
        &:first-child {
          width: 240px;
          margin-left: -633px;
          // left: 87px;
          top: 40px;
        }
        &:nth-child(2) {
          width: 180px;
          margin-right: 348px;
          // right: 20px;
          // left: 192px;
          top: auto;
          bottom: 20px;
          img {
            bottom: 0;
          }
        }
        &:nth-child(3) {
          width: 240px;
          margin-left: 411px;
          bottom: 20px;
        }
        &:last-child {
          width: 180px;
          margin-right: -571px;
          bottom: auto;
          top: 20px;
          img {
            top: 0;
          }
        }
      }
      // img {
      //   &:nth-of-type(3) {
      //     animation: balloon-zoom-in 8s infinite;
      //   }
      //   &:last-of-type {
      //     animation: balloon-zoom-in 8s 4s infinite;
      //   }
      // }
    }
    &--loaded {
      .home-hero__list {
        img {
          &:nth-of-type(3) {
            animation: balloon-zoom-in 8s infinite;
          }
          &:last-of-type {
            animation: balloon-zoom-in 8s 4s infinite;
          }
        }
      }
    }
  }

  .home-about {
    // padding: 40px 20px 70px;
    padding-top: 70px;
    padding-bottom: 53px;
    &:after {
      background-size: 878px;
      width: 878px;
      height: 100px;
      left: auto;
      right: 0;
      // bottom: -22px;
      bottom: -11px;
    }
    > div {
      max-width: $base-width;
      margin: 0 auto;
    }
    // div div {
    //   width: 525px;
    // }
    &__box {
      order: 1;
      // padding-bottom: 19px;
      width: 495px;
      margin-left: 60px;
    }
    &__title {
      font-size: 36px;
      text-align: left;
      margin-bottom: 21px;
    }
    &__text {
      line-height: 1.8;
      font-size: 15px;
    }
    &__box:first-of-type {
      margin-bottom: 30px;
    }
    ul {
      line-height: 1.8;
      font-size: 15px;
      margin-bottom: 30px;
    }
    li {
      // padding-left: 20px;
      &:after {
        // top: 10px;
        top: 9px;
      }
    }
    ul + .home-about__text {
      margin-bottom: 56px;
    }
    a {
      margin: 0;
    }
    &__video {
      width: 525px;
    }
  }

  .home-service {
    // padding: 50px 20px 59px;
    padding-top: 90px;
    padding-bottom: 98px;
    max-width: $base-width;
    margin: 0 auto;
    &__lead {
      // line-height: 1.47;
      font-size: 15px;
      margin-bottom: 20px;
    }
    &__title {
      font-size: 36px;
      margin-bottom: 30px;
    }
    &__text {
      line-height: 1.8;
      font-size: 15px;
      text-align: center;
      margin-bottom: 47px;
    }
    &__card {
      position: relative;
      box-sizing: border-box;
      // padding: 182px 30px 0;
      padding: 0 30px;
      width: calc(100% / 3);
      border-bottom: 0;
      margin-bottom: 45px;
      &:first-of-type {
        padding-top: 53px;
      }
      &:nth-of-type(2) {
        padding-top: 63px;
        border-right: 1px dotted $base-color;
        border-left: 1px dotted $base-color;
      }
      &:last-of-type {
        padding-top: 44px;
      }
      @media print, screen and (min-width: 1140px) {
        &:first-of-type {
          padding-left: 0;
        }
        &:nth-of-type(2) {
          min-width: 382px;
        }
        &:last-of-type {
          padding-right: 0;
        }
      }
    }
    &__merit {
      position: absolute;
      margin-left: -60px;
      left: 50%;
      top: 0;
    }
    // img {
    // position: absolute;
    //   margin-bottom: 20px;
    // }
    &__card:first-of-type img {
      width: 145px;
      // top: 19px;
    }
    &__card:nth-of-type(2) img {
      width: 100px;
      // top: 27px;
    }
    &__card:last-of-type img {
      width: 100px;
      // top: 10px;
    }
    &__point {
      line-height: 1.5;
      font-size: 18px;
      margin-bottom: 0;
    }
    ul {
      justify-content: center;
    }
    li {
      margin: 0 13px;
    }
  }

  .home-content {
    // padding: 70px 20px 95px;
    padding-top: 70px;
    padding-bottom: 95px;
    @media print, screen and (min-width: 1305px) {
      padding-left: 0;
    }
    &:after {
      background-size: 812px;
      width: 812px;
      height: 100px;
      left: -8px;
      // bottom: -24px;
      bottom: -12px;
    }
    &__title {
      font-size: 36px;
      text-align: center;
      margin: 0 0 40px 0;
    }
    &__button {
      // right: 20px;
      // bottom: 56px;
      bottom: 5px;
      z-index: 1;
      @media print, screen and (min-width: 1305px) {
        margin-left: 420px;
        left: 50%;
        top: 69px;
      }
      a {
        position: relative;
      }
    }
  }

  .home-search {
    // padding: 50px 20px;
    padding-top: 90px;
    padding-bottom: 110px;
    max-width: $base-width;
    margin: 0 auto;
    &__title {
      font-size: 36px;
      letter-spacing: 0.055em;
      margin-bottom: 30px;
    }
    div:first-of-type > a {
      // padding: 20px 10px;
      padding: 25px 10px 22px;
      width: 525px;
      margin-bottom: 35px;
      transition: opacity 0.4s;
      &:hover {
        opacity: 0.8;
        p:last-of-type a:after {
          width: 100%;
        }
      }
      &:first-of-type {
        // background-image: url(#{$img-dir}home/bg-search1.png);
        margin-right: 30px;
      }
      // &:last-of-type {
      // background-image: url(#{$img-dir}home/bg-search2.png);
      // }
    }
    &__button {
      // font-size: 18px;
      font-size: 16px;
      // max-width: 390px;
      // padding: 0 20px;
      @media print, screen and (min-width: 860px) {
        font-size: 18px;
      }
      &:before {
        // margin-right: 5px;
        // margin-right: 6.5px;
        // margin-right: 8.5px;
        margin-right: 7px;
      }
    }
    div:first-of-type a:first-of-type .home-search__button {
      max-width: 390px;
    }
    div:first-of-type a:first-of-type .home-search__button:before {
      top: 3px;
    }
    div:first-of-type a:last-of-type .home-search__button {
      max-width: 360px;
    }
    div:first-of-type a:last-of-type .home-search__button:before {
      top: 2px;
    }
    div:last-of-type {
      flex-wrap: nowrap;
      line-height: 1.46;
      letter-spacing: 0.06em;
    }
    div:last-of-type a {
      // padding: 114px 0 7px;
      // padding: 114px 0 20px;
      width: 192px;
      margin: 0 30px 0 0;
      transition: opacity 0.4s;
      &:hover {
        opacity: 0.8;
      }
      &:nth-of-type(odd) {
        margin-right: 30px;
      }
      &:first-of-type,
      &:nth-of-type(2) {
        font-size: 13px;
        padding: 114px 0 20px;
      }
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:last-of-type {
        font-size: 13px;
        padding: 123px 0 38px;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    // img {
    // width: 80px;
    // }
    a:first-of-type img {
      width: 100px;
      top: 30px;
    }
    a:nth-of-type(2) img {
      width: 97px;
      top: 30px;
    }
    a:nth-of-type(3) img {
      width: 100px;
      top: 30px;
    }
    a:nth-of-type(4) img {
      width: 105px;
      top: 20px;
    }
    a:last-of-type img {
      width: 100px;
      top: 30px;
    }
    &__text {
      margin: 0 10px;
      @media print, screen and (min-width: 1120px) {
        margin: 0;
      }
    }
  }

  .home .news {
    // padding: 50px 20px 40px;
    padding-top: 70px;
    padding-bottom: 60px;
    &__box {
      // justify-content: space-between;
      // align-items: center;
      width: 240px;
      // margin-bottom: 30px;
    }
    &__heading {
      font-size: 36px;
      margin-bottom: 15px;
    }
    &__box p {
      width: 145px;
    }
    // div:last-of-type a {
    // margin-bottom: 15px;
    // }
  }

  .home .contact {
    padding-bottom: 90px;
    &__box {
      padding: 30px 30px 20px;
      margin-top: 94px;
      a {
        padding: 19px 82px 6px 20px;
        width: 495px;
        // margin-bottom: 15px;
        // border-radius: 10px;
        transition: box-shadow 0.2s, transform 0.2s;
        @media print, screen and (min-width: 1120px) {
          padding-right: 0;
        }
        &:hover {
          box-shadow: none;
          transform: translateY(5px);
          &:before {
            background-position: -20px;
          }
        }
        &:first-of-type {
          margin-right: 30px;
        }
      }
    }
    &__image {
      min-width: 60px;
      max-width: 60px;
      margin-right: 15px;
    }
    &__sns {
      font-size: 15px;
      margin-bottom: 5px;
    }
    &__sub-text {
      // letter-spacing: 0;
      @media print, screen and (min-width: 1120px) {
        letter-spacing: 0;
      }
    }
  }
}
