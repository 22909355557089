// sell

.sell-cost {
  padding: 30px 20px 15px;
  .lead {
    margin-bottom: 30px;
  }
  &__title {
    color: #333;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 15px;
  }
  table {
    color: #333;
    border-spacing: 0;
    font-size: 15px;
    text-align: center;
    letter-spacing: .04em;
    width: 100%;
    margin-bottom: 20px;
  }
  th,
  td {
    display: block;
    padding: 10px 20px;
    border: 2px solid $base-color;
    border-top: 0;
    // border-bottom: 2px solid $base-color;
  }
  th {
    color: #fff;
    background-color: $base-color;
    // font-weight: bold;
  }
  td:nth-of-type(even) {
    background-color: #e3f3ff;
  }
  tr:first-of-type th {
    border-radius: 10px 10px 0 0;
  }
  tr:last-of-type td:last-of-type {
    border-radius: 0 0 10px 10px;
  }
  table strong {
    color: $base-color;
    font-size: 18px;
  }
  &__text {
    color: #999;
    line-height: 1.83;
    font-size: 12px;
    letter-spacing: .04em;
    margin-bottom: 30px;
  }
}

.merit--sell {
  .heading {
    margin-bottom: 37px;
  }
}

@media print, screen and (min-width: 768px) {

.sell-cost {
  // padding: 30px 20px;
  padding-top: 90px;
  padding-bottom: 72px;
  max-width: $base-width;
  @media print, screen and (min-width: 1120px) {
    margin-right: auto;
    margin-left: auto;
  }
  .lead {
    margin-bottom: 60px;
  }
  &__title {
    font-size: 21px;
    margin-bottom: 30px;
  }
  table {
    table-layout: fixed;
    font-size: 18px;
    margin-bottom: 40px;
  }
  th,
  td {
    display: table-cell;
    padding: 12px 20px;
    // border-top: 2px solid $base-color;
    border-left: 0;
  }
  th {
    border-bottom-color: #fff;
  }
  tr:first-of-type th,
  tr:first-of-type td {
    border-top: 2px solid $base-color;
  }
  tr:first-of-type th {
    border-radius: 10px 0 0 0;
  }
  tr:first-of-type td:last-of-type {
    border-radius: 0 10px 0 0;
  }
  tr:last-of-type th {
    border-bottom-color: $base-color;
    border-radius: 0 0 0 10px;
  }
  tr:last-of-type td:last-of-type {
    border-radius: 0 0 10px 0;
  }
  table strong {
    font-size: 27px;
  }
  &__text {
    margin-bottom: 60px;
  }
}

.merit--sell {
  .heading {
    margin-bottom: 82px;
  }
}


}

