// use

@media print, screen and (min-width: 768px) {

.example-nav--use {
  padding-bottom: 376px;
  @media print, screen and (min-width: 1500px) {
    padding-bottom: 129px;
  }
  li:nth-child(2) a {
    padding-top: 66px;
  }
  li:nth-child(3) a {
    padding-top: 66px;
  }
}


}

