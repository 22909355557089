// module

// section 大枠要素
.section {
  position: relative;
  box-sizing: border-box;
  &__inner {
    max-width: 1080px;
    margin: 0 auto;
    padding: 30px 20px;
  }

  &.is-bglogo {
    .section__inner {
      // padding-bottom: 70px;
    }
    &:after {
      position: absolute;
      background: url(#{$img-dir}home/bg-about.png) no-repeat;
      background-size: 878px;
      width: 878px;
      height: 100px;
      left: auto;
      right: 0;
      bottom: -11px;
      content: "";
    }
  }
}
