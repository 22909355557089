// beginner

.beginner-hero {
  padding: 0 20px 30px;
  img {
    margin-bottom: 20px;
  }
  &__title {
    color: #333;
    font-size: 21px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 10px;
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: .04em;
    margin-bottom: 20px;
  }
  &__card {
    padding: 20px 20px 11px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  &__sub-title {
    color: #333;
    font-size: 15px;
    letter-spacing: .04em;
    margin-bottom: 10px;
  }
  &__sub-text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: .04em;
    margin-bottom: 20px;
  }
  .button {
    // font-size: 13px;
    font-size: 10px;
    @media print, screen and (min-width: 360px) {
      font-size: 13px;
    }
  }
  .quote {
    margin-bottom: 20px;
  }
  video {
    width: 100%;
    border-radius: 10px;
  }
}

.beginner-service {
  padding: 30px 20px 15px;
  &__heading {
    color: #333;
    font-size: 21px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 20px;
  }
  &__title {
    color: #000;
    font-size: 18px;
    text-align: center;
    // letter-spacing: .06em;
    margin-bottom: 20px;
    &:last-of-type {
      margin-top: 30px;
    }
  }
  .example__card {
    box-shadow: none;
  }
}

.beginner-aside {
  letter-spacing: .04em;
  padding: 1px 20px 8px;
  margin: 30px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,.16);
  &__title {
    color: $base-color;
    font-size: 15px;
    margin: 20px 0 10px;
  }
  p,
  dl {
    // line-height: 1.79;
    line-height: 2;
    // font-size: 14px;
    font-size: 12px;
  }
  dt {
    color: #333;
  }
  dd {
    margin-bottom: 1em;
  }
  .line-button {
    font-size: 10px;
    width: 100%;
    max-width: 330px;
    @media print, screen and (min-width: 360px) {
      // font-size: 15px;
      font-size: 11px;
    }
    // @media print, screen and (min-width: 375px) {
      // font-size: 13px;
    // }
  }
}

@media print, screen and (min-width: 768px) {

.beginner-hero {
  // padding: 0 20px 30px;
  padding-bottom: 90px;
  margin-top: -15px;
  img {
    display: block;
    width: 595px;
    margin: 0 auto 60px;
  }
  &__title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  &__text {
    line-height: 2;
    font-size: 15px;
    text-align: center;
    margin-bottom: 40px;
  }
  > div {
    max-width: $base-width;
    margin: 0 auto 40px;
  }
  &__card {
    width: calc(100% / 2);
    padding: 30px 30px 19px;
    margin-bottom: 0;
    &:first-of-type {
      margin-right: 30px;
    }
  }
  &__sub-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  &__sub-text {
    margin-bottom: 30px;
  }
  .button {
    font-size: 15px;
    a {
      width: 100%;
      max-width: 362px;
      // max-width: none;
    }
  }
  .quote {
    margin-bottom: 40px;
  }
  video {
    display: block;
    max-width: 810px;
    margin: 0 auto;
  }
}

.beginner-service {
  // padding: 30px 20px 15px;
  padding-top: 90px;
  padding-bottom: 60px;
  &__heading {
    font-size: 36px;
    margin-bottom: 40px;
  }
  &__title {
    font-size: 30px;
    margin-bottom: 40px;
    &:last-of-type {
      margin-top: 90px;
    }
  }
}

.beginner-aside {
  box-sizing: border-box;
  padding: 10px 40px 25px;
  max-width: $base-width;
  // margin: 30px 20px;
  margin-top: 90px;
  margin-bottom: 0;
  @media print, screen and (min-width: 1120px) {
    margin-right: auto;
    margin-left: auto;
  }
  &__title {
    font-size: 18px;
    margin: 30px 0 15px;
  }
  p,
  dl {
    line-height: 1.8;
    font-size: 14px;
    margin-bottom: 15px;
  }
  dd {
    margin-bottom: 20px;
  }
  .line-button {
    font-size: 15px;
    max-width: 377px;
  }
}


}

