// error

.error {
  position: relative;
  // background: url(#{$img-dir}error/bg-error.png) no-repeat;
  // background-size: cover;
  // padding: 30px 0 19px;
  div {
    position: absolute;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    right: 50%;
    // top: 50%;
    top: 30px;
    // transform: translate(50%, -50%);
    transform: translateX(50%);
  }
  &__title {
    color: #333;
    font-size: 21px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 20px;
  }
  &__button {
    font-size: 13px;
    margin-bottom: 0;
    @media print, screen and (min-width: 360px) {
      font-size: 14px;
    }
    a {
      max-width: 316px;
    }
  }
}

.content--error {
  margin-bottom: 40px;
}

@media print, screen and (min-width: 768px) {

.error {
  // padding: 30px 20px 19px;
  padding-top: 38px;
  padding-bottom: 90px;
  div {
    top: 96px;
  }
  &__title {
    font-size: 36px;
    margin-bottom: 20px;
  }
  &__button {
    font-size: 18px;
    a {
      width: 416px;
      max-width: none;
    }
  }
  img {
    display: block;
    max-width: $base-width;
    margin: 0 auto;
  }
}

.content--error {
  margin-bottom: 90px;
}


}

