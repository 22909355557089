// settings

$base-color: #1777c2;
$img-dir: "/img/";
$sans-serif-font: "Noto Sans JP", "Hiragino Kaku Gothic ProN", YuGothic, Meiryo, sans-serif;
$serif-font: "Hiragino Mincho ProN", YuMincho, "ＭＳ 明朝", serif;
// $en-font: "Helvetica Neue", Helvetica, Arial, sans-serif;
// $border-color: #666;

$break-point: 768px;
$base-width: 1080px;

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    content: "";
  }
}

@mixin breakpoint {
  @media print, screen and (min-width: $break-point) {
    @content;
  }
}
