@charset "UTF-8";
/*
  grid
  説明：グリッド
 */

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .col {
    box-sizing: border-box;
  }

  @include media(tab) {
    & > .col.l1 {
      width: 8.33%;
    }
    & > .col.l2 {
      width: 16.66%;
    }
    & > .col.l3 {
      width: 25%;
    }
    & > .col.l4 {
      width: 33.33%;
    }
    & > .col.l5 {
      width: 41.66%;
    }
    & > .col.l6 {
      width: 50%;
    }
    & > .col.l7 {
      width: 58.33%;
    }
    & > .col.l8 {
      width: 66.66%;
    }
    & > .col.l9 {
      width: 75%;
    }
    & > .col.l10 {
      width: 83.33%;
    }
    & > .col.l11 {
      width: 91.66%;
    }
    & > .col.l12 {
      width: 100%;
    }
  }

  @include media(sp) {
    & > .col.s1 {
      width: 8.33%;
    }
    & > .col.s2 {
      width: 16.66%;
    }
    & > .col.s3 {
      width: 25%;
    }
    & > .col.s4 {
      width: 33.33%;
    }
    & > .col.s5 {
      width: 41.66%;
    }
    & > .col.s6 {
      width: 50%;
    }
    & > .col.s7 {
      width: 58.33%;
    }
    & > .col.s8 {
      width: 66.66%;
    }
    & > .col.s9 {
      width: 75%;
    }
    & > .col.s10 {
      width: 83.33%;
    }
    & > .col.s11 {
      width: 91.66%;
    }
    & > .col.s12 {
      width: 100%;
    }
  }
}
.row {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}

/*
 12分割以外
 均等分割
 対象は子要素のdiv,a
 */
.sep-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include media(tab) {
    & > .col.l1 {
      width: 100%;
    }
    & > .col.l2 {
      width: 50%;
    }
    & > .col.l3 {
      width: 33.333%;
    }
    & > .col.l4 {
      width: 25%;
    }
    & > .col.l5 {
      width: 20%;
    }
    & > .col.l8 {
      width: 12.5%;
    }
  }
  @include media(sp) {
    & > .col.s1 {
      width: 100%;
    }
    & > .col.s2 {
      width: 50%;
    }
    & > .col.s3 {
      width: 33.333%;
    }
  }
}

// 子要素 flex適用
.col {
  //display: flex;

  // &.btn-group__item{
  //   margin-bottom: 0;
  // }
  .figure {
    // margin: 0 16px;
    padding: 0;
  }

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

// 共通内容
.is-between {
  justify-content: space-between;
  .col:first-child {
    margin-right: auto;
  }
}
.is-sp-between {
  @include media(sp) {
    justify-content: space-between;
  }
}
.is-pc-reverse {
  @include media(tab) {
    flex-flow: row-reverse;
  }
}
.is-sp-reverse {
  @include media(sp) {
    flex-flow: column-reverse;
  }
}

.is-gap-1 {
  & > .col,
  & > .col {
    padding: 16px;
    @include media(sp) {
      padding: 8px;
    }
  }
  & .is-inline {
    margin: 16px;
    @include media(sp) {
      margin: 8px;
    }
  }
}
.is-gap-2 {
  & > .col,
  & > .col {
    padding: 10px;
    @include media(sp) {
      padding: 10px;
    }
  }
}
.is-gap-3 {
  & > .col,
  & > .col {
    padding: 5px;
  }
  & .is-inline {
    margin: 5px;
  }
}
