// rate

.simulator-box {
  padding: 47px 20px 0;
  margin-bottom: 20px;
  > img {
    background-color: #fff;
    min-width: calc(100% + 40px);
    width: 100%;
    margin: 0 -20px;
  }
}

.rate-card {
  padding: 20px;
  margin: 0 20px 30px;
  border-radius: 10px;
  &__lead {
    color: #fff;
    line-height: 1.47;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    letter-spacing: .04em;
    margin-bottom: 10px;
  }
  &__title {
    color: #fff;
    line-height: 1.48;
    font-weight: bold;
    font-size: 21px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 10px;
  }
  &__text {
    color: #fff;
    line-height: 1.79;
    font-size: 14px;
    text-align: center;
    letter-spacing: .04em;
    margin-bottom: 15px;
  }
  &__button {
    margin-bottom: 25px;
  }
  &__box {
    background-color: #fff;
    padding: 20px 20px 10px;
    border-radius: 10px;
  }
  &__sub-lead {
    color: $base-color;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    letter-spacing: .04em;
    margin-bottom: 10px;
  }
  &__sub-title {
    color: #333;
    line-height: 1.48;
    font-weight: bold;
    font-size: 21px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 10px;
    strong {
      box-shadow: inset 0 -10px #ffec60;
    }
  }
  &__sub-text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: .04em;
    margin-bottom: 0;
  }
  > div > div {
    margin-bottom: 30px;
  }
  div div div {
    font-weight: bold;
    padding: 30px 0 15px;
    border-bottom: 1px dotted $base-color;
  }
  &__merit {
    color: #fff;
    background-color: #f4b119;
    line-height: 30px;
    font-size: 15px;
    text-align: center;
    width: 120px;
    margin: 0 auto 20px;
    border-radius: 10px;
  }
  img {
    display: block;
    margin: 0 auto 20px;
  }
  div div div:first-of-type img {
    width: 130px;
  }
  div div div:nth-of-type(2) img,
  div div div:last-of-type img {
    width: 90px;
  }
  &__point {
    color: #333;
    line-height: 1.6;
    font-size: 15px;
    letter-spacing: .04em;
    strong {
      color: $base-color;
    }
  }
  &__sub-button {
    li {
      margin-bottom: 14px;
      &:last-child {
        // font-size: 15px;
        font-size: 13px;
        @media print, screen and (min-width: 360px) {
          font-size: 15px;
        }
      }
    }
  }
}

.flow--rate {
  .heading {
    margin-bottom: 20px;
  }
}

.card-flow--rate {
  div div:first-of-type img {
    width: 69px;
  }
  div div:nth-of-type(2) img {
    width: 51px;
  }
  div div:last-of-type img {
    width: 106px;
  }
}

.rate-faq {
  padding: 30px 20px 15px;
  .heading {
    margin-bottom: 20px;
  }
  &__card {
    // padding: 38px 20px 5px;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0,0,0,.16);
  }
  img {
    width: 100%;
    margin-bottom: 15px;
  }
  dl {
    font-weight: bold;
    // font-size: 18px;
    font-size: 15px;
    letter-spacing: .04em;
    padding: 0 20px;
    margin-bottom: 16px;
  }
  dt {
    position: relative;
    color: #333;
    padding-left: 31px;
    margin-bottom: 11px;
    &:after {
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: $base-color;
      line-height: 1;
      font-size: 13px;
      width: 22px;
      height: 22px;
      border: 2px solid $base-color;
      content: "Q";
      left: 0;
      top: -2px;
      border-radius: 50%;
    }
  }
  dd {
    position: relative;
    color: $base-color;
    padding-left: 31px;
    &:after {
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: $base-color;
      line-height: 1;
      font-size: 13px;
      width: 26px;
      height: 26px;
      content: "A";
      left: 0;
      top: -2px;
      border-radius: 50%;
    }
  }
  &__text {
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: .04em;
    margin: 0 20px;
  }
}

.rate-aside {
  color: #fff;
  // text-align: center;
  padding: 20px 20px 5px;
  margin: 0 20px 30px;
  border-radius: 10px;
  &__lead {
    color: #fff;
    line-height: 1.47;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    letter-spacing: .04em;
    margin-bottom: 10px;
  }
  &__title {
    color: #fff;
    line-height: 1.48;
    font-weight: bold;
    font-size: 21px;
    text-align: center;
    letter-spacing: .06em;
    margin-bottom: 10px;
  }
  &__text {
    color: #fff;
    line-height: 1.79;
    font-size: 14px;
    letter-spacing: .04em;
    margin-bottom: 20px;
  }
  ul {
    color: #333;
    font-weight: bold;
    // font-size: 21px;
    font-size: 18px;
    text-align: center;
    letter-spacing: .04em;
    margin-bottom: 20px;
    border-radius: 10px;
    @media print, screen and (min-width: 360px) {
      font-size: 21px;
    }
  }
  li {
    background-color: #fff;
    padding: 15px 0;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  ul strong {
    color: $base-color;
  }
  &__sub-text {
    // line-height: 1.79;
    font-weight: bold;
    // font-size: 14px;
    font-size: 15px;
    letter-spacing: .04em;
    margin-bottom: 20px;
  }
}

@media print, screen and (min-width: 768px) {

.simulator-box {
  // padding: 47px 20px 0;
  padding-top: 90px;
  margin-bottom: 40px;
}

.rate-card {
  box-sizing: border-box;
  padding: 58px 40px 40px;
  max-width: $base-width;
  // margin: 0 20px 30px;
  margin-bottom: 90px;
  @media print, screen and (min-width: 1120px) {
    margin-right: auto;
    margin-left: auto;
  }
  // &__lead {
    // line-height: 1.47;
  // }
  &__title {
    // line-height: 1.48;
    font-size: 27px;
    margin-bottom: 20px;
  }
  &__text {
    line-height: inherit;
    font-size: 15px;
    margin-bottom: 30px;
  }
  &__button {
    margin-bottom: 44px;
  }
  &__box {
    padding: 40px 30px 26px;
  }
  &__sub-lead {
    font-size: 15px;
  }
  &__sub-title {
    // line-height: 1.48;
    font-size: 27px;
    margin-bottom: 20px;
    strong {
      box-shadow: inset 0 -15px #ffec60;
    }
  }
  &__sub-text {
    line-height: inherit;
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
  }
  > div > div {
    margin-bottom: 35px;
  }
  div div div {
    position: relative;
    // padding: 30px 0 15px;
    // padding: 137px 0 0;
    padding-bottom: 0;
    border-bottom: 0;
    &:first-of-type {
      padding-top: 53px;
    }
    &:nth-of-type(2) {
      padding: 63px 30px 0;
      border-right: 1px dotted $base-color;
      border-left: 1px dotted $base-color;
      margin: 0 30px;
    }
    &:last-of-type {
      padding-top: 44px;
    }
  }
  &__merit {
    position: absolute;
    margin-left: -60px;
    left: 50%;
    top: 0;
    // margin: 0 auto 20px;
  }
  // img {
    // position: absolute;
    // left: 50%;
    // top: 0;
    // margin-bottom: 20px;
    // transform: translateX(-50%);
  // }
  // div div div:first-of-type img {
  //   width: 130px;
  // }
  // div div div:nth-of-type(2) img,
  // div div div:last-of-type img {
  //   width: 90px;
  // }
  &__point {
    line-height: 1.47;
    margin-bottom: 0;
  }
  &__sub-button {
    justify-content: center;
    li {
      margin: 0 26px 0 0;
      &:first-child a {
        width: 206px;
      }
      &:last-child {
        font-size: 18px;
      }
    }
  }
}

.flow--rate {
  .heading {
    margin-bottom: 50px;
  }
}

.rate-faq {
  // padding: 30px 20px 10px;
  padding-top: 90px;
  padding-bottom: 90px;
  .heading {
    margin-bottom: 62px;
  }
  > div {
    max-width: $base-width;
    margin: 0 auto;
  }
  &__card {
    padding-bottom: 30px;
    width: calc(100% / 3);
    margin-bottom: 0;
    &:not(:last-of-type) {
      margin-right: 30px;
    }
  }
  img {
    margin-bottom: 23px;
  }
  dl {
    font-size: 18px;
    padding: 0 30px;
    margin-bottom: 19px;
  }
  dt {
    padding-left: 39px;
    margin-bottom: 20px;
    &:after {
      font-size: 18px;
      width: 30px;
      height: 30px;
      top: -3px;
    }
  }
  dd {
    padding-left: 39px;
    &:after {
      font-size: 18px;
      width: 34px;
      height: 34px;
      top: -3px;
    }
  }
  &__text {
    line-height: 1.8;
    font-size: 15px;
    margin: 0 30px;
  }
}

.rate-aside {
  box-sizing: border-box;
  padding: 40px 30px 26px;
  max-width: $base-width;
  // margin: 0 20px 30px;
  margin-bottom: 40px;
  @media print, screen and (min-width: 1120px) {
    margin-right: auto;
    margin-left: auto;
  }
  // &__lead {
    // line-height: 1.47;
  // }
  &__title {
    // line-height: 1.48;
    font-size: 27px;
    margin-bottom: 20px;
  }
  &__text {
    // line-height: 1.8;
    line-height: inherit;
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
  }
  ul {
    font-size: 24px;
    margin-bottom: 30px;
  }
  li {
    padding: 30px 0;
    width: calc(100% / 3);
    // margin-bottom: 10px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  &__sub-text {
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
  }
}


}

